import React, { useState, useEffect } from "react";
import { catchError } from "auto-trace";
import { CpSelectMultiPillsField, useDebounce } from "canopy-styleguide!sofe";
import { getValues } from "./search-and-select-filter.resource";

export default function SearchAndSelectInput({ column, control, disabled, fieldName, setInputField, watchInputField }) {
  const { customField, filterField, type, getSearchFilterValue, getSearchFilterLabel, roleId } = column;
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const debounceSearch = useDebounce((value) => setDebouncedSearch(value), 500, {}, []);
  const onSearch = (value) => {
    setSearch(value);
    debounceSearch(value.trim());
  };

  useEffect(() => {
    // reset search term when value is selected
    setSearch("");
    debounceSearch("");
  }, [debounceSearch, watchInputField]);

  const [prevValuesConverted, setPrevValuesConverted] = useState(false);
  useEffect(() => {
    // when viewing a previously applied filter, we only get the id back. We need to match this with the correct object from data so that it is displayed correctly in the input.
    if (!watchInputField.length) {
      setPrevValuesConverted(true);
      return;
    }

    if (!data.length || prevValuesConverted) {
      return;
    }

    const { findSearchItemFromValue, getSearchFilterValue } = column;
    const values = watchInputField.map((filterValue) => {
      return data.find((serverItem) => {
        if (findSearchItemFromValue) {
          return findSearchItemFromValue(serverItem, filterValue);
        } else {
          return getSearchFilterValue(serverItem) === filterValue;
        }
      });
    });
    setInputField(values.filter((v) => !!v));
    setPrevValuesConverted(true);
  }, [watchInputField, column, data, prevValuesConverted]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  useEffect(() => {
    setIsLoading(true);
    getValues({
      field: !!customField ? type : !!roleId ? "roles" : filterField,
      search: debouncedSearch,
      fieldId: customField?.field_id || roleId || "",
    }).subscribe(
      (res) => {
        const values = res.values?.map((item, i) => {
          const itemValue = getSearchFilterValue(item);
          const itemLabel = getSearchFilterLabel?.(item) || item.name || item.value || item.id;
          return {
            id: item.id || item.name,
            name: itemLabel,
            value: itemValue,
          };
        });
        setData(values);
        setIsLoading(false);
      },
      catchError((err, throwError) => {
        setIsLoading(false);
        throwError(err);
      })
    );
  }, [debouncedSearch]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  return (
    <div>
      <div>Select values</div>
      <CpSelectMultiPillsField
        control={control}
        data={data}
        disabled={disabled}
        fieldName={fieldName}
        loading={isLoading}
        placeholder="Select values"
        searchOnChange={onSearch}
        searchValue={search}
        transformData={(item) => {
          return {
            ...item,
            id: item.id || item,
            name: item.name || item,
          };
        }}
      />
    </div>
  );
}
