import React, { useState } from "react";
import { formatSsn, formatEin } from "src/common/utils.helpers";

function format(id, value, mask) {
  switch (id) {
    case "ssn":
      return formatSsn(value, { mask });
    case "ein":
      return formatEin(value, { mask });
    default:
      return value;
  }
}

export default function MaskedCell({ value, columnId }) {
  const [showMasked, setShowMasked] = useState(true);
  return value ? (
    <div onMouseEnter={() => setShowMasked(false)} onMouseLeave={() => setShowMasked(true)}>
      {format(columnId, value, showMasked)}
    </div>
  ) : (
    "—"
  );
}
