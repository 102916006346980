// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: none;\n}\n\n.src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__iconButton {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 3.2rem;\n  height: 3.2rem;\n  border: unset;\n  font-size: 1.3rem;\n  color: var(--cps-color-cool-gray);\n  background-color: var(--cps-color-bumble);\n  border-radius: 0.5rem;\n}\n\n.src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__iconButton cps-icon {\n  color: var(--cps-color-monsoon);\n}\n\n.src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__iconButton:hover,\n.src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__iconButton:focus {\n  background-color: var(--cps-color-chrome);\n}\n\n.src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__body {\n  height: 34.6rem;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__header",
	"iconButton": "src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__iconButton",
	"body": "src-modal-upgrade-contacts-modal-upgrade-contacts-modal-styles__body"
};
module.exports = exports;
