import React from "react";
import { SimpleSelect } from "src/form/select-input/select-input.component.js";

const industries = [
  "Administrative & Support",
  "Agriculture",
  "Apparel",
  "Arts",
  "Chemicals",
  "Construction",
  "Education",
  "Electronics",
  "Energy",
  "Engineering",
  "Entertainment",
  "Finance",
  "Fishing",
  "Food Services",
  "Forestry",
  "Fuel Dealer",
  "Government",
  "HVAC",
  "Health Care",
  "Hospitality",
  "Hunting",
  "Information Technology",
  "Insurance",
  "Investment Holding",
  "Landscape",
  "Management",
  "Manufacturing",
  "Media",
  "Mining",
  "Other",
  "Pharmaceuticals",
  "Plumbing",
  "Pool Services",
  "Professional Services",
  "Real Estate",
  "Recreation",
  "Remediation Services",
  "Rental & Leasing",
  "Retail Trade",
  "Scientific Services",
  "Shipping",
  "Social Assistance",
  "Tan & Spa Service",
  "Technical Services",
  "Transportation",
  "Utilities",
  "Warehousing",
  "Waste Management",
  "Wholesale Food",
  "Wholesale Trade",
];
const options = industries.map((type) => ({ key: type, value: type }));

export function IndustrySelect({ value, onChange }) {
  return (
    <SimpleSelect
      name="industry"
      options={options}
      labelText="Industry"
      clearable
      value={value}
      updateValue={onChange}
    />
  );
}
