import { fetchAsObservable } from "fetcher!sofe";

export function createRole(label) {
  return fetchAsObservable("/api/roles", {
    method: "POST",
    body: {
      label,
    },
  });
}

export function getRoles() {
  return fetchAsObservable("/api/roles");
}
