import { useState, useEffect } from "react";
import { catchAsyncStacktrace } from "auto-trace";
// project
import { getCustomFields } from "src/global-settings/custom-fields/custom-fields.resource.js";

export function useCustomFields() {
  const [customFields, setCustomFields] = useState([]);
  useEffect(() => {
    const subscription = getCustomFields().subscribe((customFields) => {
      setCustomFields(customFields);
    }, catchAsyncStacktrace());
    return () => subscription && subscription.unsubscribe();
  }, []);

  return customFields;
}
