// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-filter-view-tabs-styles__cptabs {\n  overflow: auto;\n  scrollbar-width: none; /* Firefox */\n  height: 4rem;\n}\n\n.src-client-list-filter-view-tabs-styles__cptabs::-webkit-scrollbar {\n  display: none; /* Safari and Chrome */\n}\n\n.src-client-list-filter-view-tabs-styles__flexNoShrink {\n  flex-shrink: 0;\n}\n\n.src-client-list-filter-view-tabs-styles__innerList {\n  width: 100%;\n  position: relative;\n}\n\n.src-client-list-filter-view-tabs-styles__virtualContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"cptabs": "src-client-list-filter-view-tabs-styles__cptabs",
	"flexNoShrink": "src-client-list-filter-view-tabs-styles__flexNoShrink",
	"innerList": "src-client-list-filter-view-tabs-styles__innerList",
	"virtualContainer": "src-client-list-filter-view-tabs-styles__virtualContainer"
};
module.exports = exports;
