import React from "react";
import { CpWell, CpToggle } from "canopy-styleguide!sofe";

export function ActiveClientToggle({ className, disabled, value, onChange }) {
  return (
    <CpWell level={2} className={`${className || ""} cp-p-16 cp-pt-24`}>
      <div className="flex justify-between">
        <div className="cp-pt-4 cp-mb-4">
          <label className="cp-wt-semibold">Active</label>
        </div>
        <CpToggle checked={value} disabled={disabled} onChange={onChange} />
      </div>
      <div>
        When turned off, contact functionality is reduced to contact record information, notes, and email sync. Client
        portal access will be deactivated. Only active contacts count towards your contact billing tier.
      </div>
    </CpWell>
  );
}
