import React from "react";
import PropTypes from "prop-types";
import styles from "./page-title-bar.styles.css";
import getTitleBarContent from "./page-title-bar.helper.js";
import ExitConfirmationModal from "../modals/exit-confirmation-modal.component.js";
import { DISPLAY_STATES } from "../common/common.helper.js";

export default class PageTitleBar extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    finishImport: PropTypes.func.isRequired,
    displayState: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showExitConfirmationModal: false,
    };
  }

  render() {
    const { finishImport, closeModal, displayState } = this.props;
    const { showExitConfirmationModal } = this.state;

    const titleData = getTitleBarContent(displayState);

    return (
      <div className={`${styles.pageTitleBar} cps-white`}>
        <div className={`${styles.titleAndSubTitle}`}>
          <div className="cps-subheader cps-wt-bold">{titleData.title}</div>
          <span>{titleData.subtitle}</span>
        </div>
        <div className={`${styles.toolsArea}`}>
          <div className={`${styles.slideRight}`} style={{ alignSelf: "flex-start" }}>
            <span
              onClick={() => {
                this.setState({ showExitConfirmationModal: true });
              }}
              style={{ cursor: "pointer" }}
              className="cps-icon cps-icon-close cps-white"
            ></span>
          </div>
          {displayState === DISPLAY_STATES.COMPLETE ? (
            <div className={`${styles.slideRight}`}>
              <button onClick={finishImport} className={`cps-btn +secondary cps-bg-white ${styles.importButton}`}>
                Finish
              </button>
            </div>
          ) : null}
        </div>
        {showExitConfirmationModal && (
          <ExitConfirmationModal
            closeModal={closeModal}
            closeDialog={() => {
              this.setState({ showExitConfirmationModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

PageTitleBar.defaultProps = {
  displayState: DISPLAY_STATES.DEDUPE,
};
