// @flow
import * as React from "react";
import AsyncDecorator from "async-decorator/rx6";
import { catchAsyncStacktrace } from "auto-trace";
// project
import { getFormatedStates } from "./states.resource.js";
import { SimpleTypeahead } from "src/form/typeahead-input/typeahead-input.component.js";
// sofe
import { CprLoader } from "canopy-styleguide!sofe";
// library
import { of } from "rxjs";

type State = {
  states: Array<any>,
};

type Props = {
  onChange: Function,
  selected: string,
  cancelWhenUnmounted: Function,
};

@AsyncDecorator
export default class StateSelect extends React.Component<Props, State> {
  state = {
    states: [],
  };

  static defaultProps = {
    cancelWhenUnmounted: () => {},
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getFormatedStates().subscribe((formattedStates) => {
        this.setState({ states: formattedStates });
      }, catchAsyncStacktrace())
    );
  }

  Observable = (searchString: string) => {
    return of(
      this.state.states.filter((item) => {
        return (
          item.key.toLowerCase().includes(searchString.toLowerCase()) ||
          itemToString(item).toLowerCase().includes(searchString.toLowerCase())
        );
      })
    );
  };

  render() {
    const { selected, onChange, ...rest } = this.props;
    if (this.state.states.length > 0) {
      let found = this.state.states.find((state) => state.key === selected) || "";
      return (
        <SimpleTypeahead
          itemToString={itemToString}
          debounceTime={0}
          itemKey={(item) => item.key}
          items={this.state.states}
          value={found}
          emptySelectedItem={{ key: "" }}
          updateValue={(obj) => obj && onChange(obj.key)}
          placeholder="State"
          Observable={this.Observable}
          {...rest}
        />
      );
    } else {
      return <CprLoader />;
    }
  }
}

function itemToString(item) {
  return item ? item.value : "";
}
