import { pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";

export function searchContactsByName(name, limit = 20, type, options) {
  let query;
  if (options && options.customQuery) {
    query = options.customQuery;
  }
  query = buildQuery(name.toLowerCase(), type, options);
  const jql = name !== "" && name !== undefined ? encodeURIComponent(JSON.stringify(query)) : undefined;
  return fetchAsObservable(`
    api/contacts?jql=${jql ? jql : ""}&limit=${limit}&sort=name
  `).pipe(pluck("contacts"));
}

export function searchContacts(name, limit = 5, type) {
  const jql =
    name !== "" && name !== undefined
      ? encodeURIComponent(JSON.stringify(buildQuery(name.toLowerCase(), type)))
      : undefined;
  return fetchAsObservable(`
    api/contacts?jql=${jql ? jql : ""}&limit=${limit}&sort=name
  `).pipe(pluck("contacts"));
}

const defaultJql = [
  {
    field: "is_archived",
    operator: "eq",
    value: false,
  },
];

function buildQuery(name, type, options) {
  let nameFields = [];
  const person_name = {
    field: "person_name",
    operator: "contains",
    value: name,
  };
  const business_name = {
    field: "business_name",
    operator: "contains",
    value: name,
  };
  const last_name = {
    field: "last_name",
    operator: "contains",
    value: name,
  };
  let typeDeliminator;

  switch (type) {
    case "business":
      nameFields = [business_name];
      typeDeliminator = {
        field: "is_business",
        operator: "eq",
        value: true,
      };
      break;
    case "individual":
      nameFields = [person_name, last_name];
      typeDeliminator = {
        field: "is_business",
        operator: "eq",
        value: false,
      };
      break;
    default:
      nameFields = [person_name, business_name, last_name];
      break;
  }

  const jql = [
    ...(options && options.noDefaultJQL ? [] : defaultJql),
    {
      OR: nameFields,
    },
    ...(options && options.additionalJQL ? options.additionalJQL : []),
  ];

  if (typeDeliminator) {
    jql.push(typeDeliminator);
  }

  return jql;
}
