// @flow
import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./phone-number.krem.css";
import { MaskedInput } from "src/form/masked-input/masked-input.component.js";
import Actions from "../../list-of-items/actions/actions.component.js";
import { SimpleSelect } from "src/form/select-input/select-input.component.js";
// types
import type { phoneNumber as phoneNumberType, TNOption } from "../phone-numbers-section.component.js";
// sofe

type Props = {
  updatePhoneNumber: Function,
  name: string,
  removeTn: Function,
  showInvalid: boolean,
  removeRef: Function,
  setPrimaryById: Function,
  showActions: boolean,
  options: Array<TNOption>,
  phoneNumber: phoneNumberType,
};

export default class PhoneNumber extends React.Component<Props> {
  maskedInput: ?MaskedInput;
  ext: ?HTMLInputElement;

  render() {
    const { phoneNumber } = this.props;
    const showExt = this.props.phoneNumber.type === "work";
    const maskClassName = `width100`;
    return (
      <Scoped css={styles}>
        <div className="phoneNumber margin-between cps-margin-bottom-16">
          <div className="middle-section-dropdown">
            <SimpleSelect
              options={this.getDropdownOptions()}
              value={this.props.phoneNumber.type}
              updateValue={this.updateType}
              ariaLabelText={"Phone Number Type select"}
            ></SimpleSelect>
          </div>
          <div className="tn margin-between">
            <MaskedInput
              className={maskClassName}
              value={this.props.phoneNumber.number}
              updateValue={this.onChange}
              showInvalid={this.props.showInvalid}
              validationFn={validateTN}
              inputProps={{
                onPaste: (e) => {
                  e.preventDefault();
                  const valueNumbersOnly = e.clipboardData.getData("text").replace(/\D+/g, "");
                  this.onChange(valueNumbersOnly);
                },
                autoComplete: "off",
                htmlFor: `phone_${phoneNumber.id}`,
                name: `phone_${phoneNumber.id}`,
              }}
              nonValidMsg="10 numbers minimum"
              masks={[
                {
                  condition: (value) => value.length <= 10,
                  pattern: "(111) 111-1111",
                },
                {
                  condition: (value) => value.length > 10,
                  pattern: "111111111111111",
                },
              ]}
              ref={(el) => (this.maskedInput = el)}
            />
            {showExt && (
              <div className="cps-form-group cps-margin-bottom-0">
                <input
                  className="cps-form-control"
                  style={{ width: "80px" }}
                  placeholder="Ext."
                  value={this.props.phoneNumber.ext}
                  onChange={this.updateExtension}
                  ref={(el) => (this.ext = el)}
                  maxLength="15"
                />
              </div>
            )}
          </div>
          {this.props.showActions && (
            <div>
              <Actions
                isPrimary={this.props.phoneNumber.is_primary}
                setPrimary={this.setPrimary}
                starHtml={`Primary phone number`}
                trashHtml={`Remove phone number`}
                remove={this.removeTn}
              />
            </div>
          )}
        </div>
      </Scoped>
    );
  }

  focus = () => {
    if (this.maskedInput) {
      this.maskedInput.focus();
    }
  };

  setPrimary = () => {
    this.props.setPrimaryById(this.props.phoneNumber.id, !this.props.phoneNumber.is_primary);
  };

  updateType = (value: string) => {
    const phoneNumber: phoneNumberType = { ...this.props.phoneNumber, type: value };
    this.props.updatePhoneNumber(this.props.phoneNumber.id, phoneNumber);
  };

  removePress = (evt: SyntheticKeyboardEvent<HTMLAnchorElement>) => {
    if (evt.key === " " || evt.key === "Enter") {
      this.removeTn();
    }
  };

  removeTn = () => {
    this.props.removeRef(this);
    this.props.removeTn(this.props.phoneNumber.id);
  };

  onChange = (number: string) => {
    const phoneNumber: phoneNumberType = { ...this.props.phoneNumber, number: number };
    this.props.updatePhoneNumber(this.props.phoneNumber.id, phoneNumber);
  };

  updateExtension = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    const ext = evt.target.value.replace(/\D+/g, "");
    const phoneNumber: phoneNumberType = { ...this.props.phoneNumber, ext };
    this.props.updatePhoneNumber(this.props.phoneNumber.id, phoneNumber);
  };

  getDropdownOptions = (): Array<{ key: string, value: string }> => {
    return this.props.options || [];
  };

  isInvalid = () => {
    return !validateTN(this.props.phoneNumber.number);
  };
}

function validateTN(number: string) {
  if (number.length < 10 && number.length !== 0) {
    return false;
  } else {
    return true;
  }
}
