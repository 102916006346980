import { useRef, useReducer, useMemo, useEffect, useContext, useCallback } from "react";
import { get } from "lodash";
import { Context } from "src/forms-lib";

export default function useFormInput(props) {
  const { name, initialValue = "", required = false, validationFn, asyncValidation = false } = props;

  if (name === undefined) {
    throw Error(`Name is a required field to use the 'useFormInput' hook`);
  }

  const context = useContext(Context);

  const shared = props.shared || get(context, "sharedValues", []).includes(name);

  const { updateSharedValue, registerRef, deregisterRef, showInvalid, sharedStateObs } = context;
  const contextInitialValue = get(context, `initialValue.${name}`);

  const initialState = {
    value: contextInitialValue !== undefined ? contextInitialValue : initialValue,
    valid: true,
    validated: false,
    validating: false,
  };

  const reducer = useMemo(() => {
    return createReducer({ validationFn, required, shared, name, updateSharedValue });
  }, [validationFn, required, shared, name, updateSharedValue]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const ref = useRef({});
  const updateValue = useCallback(
    (value) => {
      dispatch({ type: "update_value", value });
    },
    [name, shared, updateSharedValue] // eslint-disable-line
  );
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  useEffect(() => {
    const sub = sharedStateObs.subscribe((sharedState) => {
      if (sharedState.updateValuesToMatch && shared) {
        updateValue(sharedState[name]);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [sharedStateObs, shared, name, updateValue]);

  // setting shared value on initialization
  useEffect(() => {
    if (state.value !== undefined && shared) {
      const update = {};
      update[name] = state.value;
      updateSharedValue(update);
    }
  }, []); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  if (state.validated === false && showInvalid === true) {
    dispatch({ type: "validate" });
  }

  // ref setup
  if (required || validationFn || asyncValidation) {
    ref.current.isInvalid = () => !state.valid || state.validating;
  }
  ref.current.getValue = () => {
    if (ref.current.customGetValue) {
      return ref.current.customGetValue(state.value);
    } else {
      return state.value;
    }
  };
  useEffect(() => {
    ref.current.name = name;
    ref.current.getName = () => name;
  }, [name]);

  // register and deregister
  useEffect(() => {
    if (name) {
      const currentRef = ref.current;
      registerRef(ref.current);
      return () => deregisterRef(currentRef);
    }
  }, [name, registerRef, deregisterRef]);

  const setCustomGetValue = useCallback((fn) => {
    if (typeof fn === "function") {
      ref.current.customGetValue = fn;
    }
  }, []);

  const setFocus = useCallback((focusFn) => {
    if (typeof focusFn === "function") {
      ref.current.focus = focusFn;
    }
  }, []);

  const setValid = (value) => {
    dispatch({ type: "set_valid", value });
  };

  const setValidating = (value) => {
    dispatch({ type: "set_validating", value });
  };

  const more = {
    setFocus,
    showInvalid: showInvalid,
    sharedStateObs: sharedStateObs,
    setCustomGetValue,
    valid: state.valid,
    setValid: setValid,
    setValidating: setValidating,
  };
  return [state.value, updateValue, more];
}

function createReducer({ validationFn, required, shared, name, updateSharedValue }) {
  let valid;
  return (state, action) => {
    switch (action.type) {
      case "update_value":
        const { value } = action;
        valid = runValidityCheck(value, validationFn, required);
        if (shared) {
          const update = {};
          update[name] = value;
          updateSharedValue(update);
        }
        return { ...state, value, valid, validated: true };
      case "validate":
        valid = runValidityCheck(state.value, validationFn, required);
        return { ...state, valid, validated: true };
      case "set_valid":
        return { ...state, valid: action.value, validated: true };
      case "set_validating":
        return { ...state, validating: action.value };
      default:
        throw new Error(`dispatch action not supported: ${action}`);
    }
  };
}

function runValidityCheck(value, validationFn, required = false) {
  if (required) {
    if (value === undefined || value.trim() === "") {
      return false;
    } else {
      // Field is required but has something in it so either validate it or return true
      return validationFn ? validationFn(value) : true;
    }
  } else if (!required) {
    // Field is not required so run the validationFn or return nothing
    return validationFn ? validationFn(value) : true;
  } else {
    return true;
  }
}
