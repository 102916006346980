import { createSource, deleteSources, mergeSources, editSource } from "./contact-sources.resource";
import { EMPTY } from "rxjs";
import { sortBy, differenceWith } from "lodash";

const customFieldTypesDefinition = {
  text: "Text input",
  date: "Date",
  dropdown: "Dropdown select",
  multiselect: "Multi-select",
};

export const CustomFieldTypes = keyifyObj(customFieldTypesDefinition);

export const CustomFieldEntityTypes = {
  INDIVIDUALS: "individuals",
  BUSINESSES: "businesses",
  BOTH: "both",
};

export const ErrorTypes = {
  DROPDOWN_NO_OPTIONS: "At least one dropdown option is required!",
};

export const idComparer = (a, b) => a.id === b.id;

export const tempNewIdPrefix = "TEMP_NEW_ID_";

function keyifyObj(obj) {
  // example: {a: 1}   =>   {a: {key: 'a', value: 1}}
  return Object.entries(obj).reduce((result, entry) => {
    return { ...result, [entry[0]]: { key: entry[0], value: entry[1] } };
  }, {});
}

export function getCreateObservables(createOptions) {
  return createOptions.map((option) => {
    return createSource(option.name);
  });
}

export function getEditObservables(editOptions) {
  return editOptions.map((option) => {
    return editSource(option.name, option.id);
  });
}

export function getMergeObservables(mergeOptions) {
  return mergeOptions.map((option) => {
    return mergeSources(
      option.name,
      option.mergedFrom.map((mergeOption) => mergeOption.id)
    );
  });
}

export function getDeleteObservable(deleteOptions) {
  if (deleteOptions.length > 0) {
    return deleteSources(deleteOptions.map((option) => option.id).join(","));
  } else {
    return EMPTY;
  }
}

export function formatEntityType(type) {
  return type.charAt(0).toUpperCase() + type.toLowerCase().slice(1);
}

export function mapFieldFromApi(field) {
  const mappedField = {
    id: field.field_id,
    name: field.field_name,
    type: CustomFieldTypes[field.field_type],
    entityType: field.contact_type,
  };

  if (mappedField.type === CustomFieldTypes.dropdown || mappedField.type === CustomFieldTypes.multiselect) {
    // if type of field is dropdown then map the dropdown values as well
    mappedField.options = sortBy(field.dropdown_values, "order").map((dropdownValue) => ({
      id: dropdownValue.id,
      name: dropdownValue.value,
      businesses_applied_to: dropdownValue.businesses_applied_to,
      individuals_applied_to: dropdownValue.individuals_applied_to,
    }));
  }
  return mappedField;
}

export function mapFieldToApi(field) {
  const mappedField = {
    field_id: field.id,
    field_name: field.name,
    field_type: field.type.key,
    contact_type: field.entityType,
  };

  if (!mappedField.field_id) {
    // if there is no field_id remove it
    delete mappedField.field_id;
  }

  if (field.type === CustomFieldTypes.dropdown || field.type === CustomFieldTypes.multiselect) {
    mappedField.dropdown_values = field.options.reduce((dropdown_values, option) => {
      if (option.mergedFrom) {
        // if option is merged
        return [...dropdown_values, ...mapMergedDropdownOptionToApi(option)];
      } else if (option.id.startsWith(tempNewIdPrefix)) {
        // if option is new
        const mappedOption = mapNewDropdownOptionToApi(option);
        return mappedOption ? [...dropdown_values, mappedOption] : dropdown_values;
      } else {
        // option is existing
        return [...dropdown_values, mapExistingDropdownOptionToApi(option)];
      }
    }, []);
  }

  return mappedField;
}

function mapMergedDropdownOptionToApi(option) {
  let mappedDropdownValues = [];

  if (option.is_deleted) {
    // if option has been deleted, it is actually just deletes
    mappedDropdownValues = option.mergedFrom.map((mergedOption) => {
      return { id: mergedOption.id, is_deleted: true };
    });
  } else if (option.mergedFrom.length === 1) {
    // if option has only been merged from one existing option, it is actually just an edit
    mappedDropdownValues.push({
      id: option.mergedFrom[0].id,
      value: option.name,
    });
  } else if (option.mergedFrom.length > 1) {
    // if option has been merged from more than one existing option, transform it into the api format
    mappedDropdownValues.push({
      id: option.mergedFrom[0].id,
      value: option.name,
      merged_with: differenceWith(option.mergedFrom, [option.mergedFrom[0]], idComparer).map((option) => option.id),
    });
  }

  return mappedDropdownValues;
}

function mapNewDropdownOptionToApi(option) {
  return !option.is_deleted ? { value: option.name } : null;
}

function mapExistingDropdownOptionToApi(option) {
  const mappedDropdownValue = {
    id: option.id,
  };

  if (option.is_deleted) {
    mappedDropdownValue.is_deleted = option.is_deleted;
  } else {
    mappedDropdownValue.value = option.name;
  }

  return mappedDropdownValue;
}
