// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dedupe-modal-merge-card-merge-card-styles__mergeCard {\n  margin-top: 12px;\n  margin-bottom: 12px;\n  max-width: initial;\n  min-width: initial;\n}\n\n.src-dedupe-modal-merge-card-merge-card-styles__cardBody {\n  padding: 24px 20px;\n}\n\n.src-dedupe-modal-merge-card-merge-card-styles__actionStrip {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 26px;\n  display: inline-block;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"mergeCard": "src-dedupe-modal-merge-card-merge-card-styles__mergeCard",
	"cardBody": "src-dedupe-modal-merge-card-merge-card-styles__cardBody",
	"actionStrip": "src-dedupe-modal-merge-card-merge-card-styles__actionStrip"
};
module.exports = exports;
