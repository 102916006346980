import { useQuery } from "src/react-query";
import { catchError } from "auto-trace";

/*
 * Here we're using react-query to manage the state of user preferences. Since we are typically working with user objects we can avoid BE calls to get preferences for a user by passing in the user data to this hook.
 * So user data should be passed into this query, and in order to update components that use this query you can use `queryClient.setQueryData(['user-preferences', $UPDATED_USER.id], $UPDATED_USER.preferences)`
 */
export default function useUserPrefsQuery(user) {
  const query = useQuery({
    queryKey: ["user-preferences", user?.id],
    queryFn: () =>
      new Promise((resolve, reject) => {
        resolve(user?.preferences || null);
      }),
    onError: catchError(),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return {
    userPrefsQuery: query,
    userPrefs: query.data,
  };
}
