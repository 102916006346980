import React from "react";
import { InputDecorator } from "src/forms-lib";
import { FieldLabel } from "src/common/client-record-fields";

function Note(props) {
  const { value, updateValue } = props;
  return (
    <div className="cps-margin-bottom-16">
      <FieldLabel>
        <span>Additional information</span>
      </FieldLabel>
      <textarea
        value={value}
        onChange={(evt) => {
          const value = evt.target.value;
          updateValue(value);
        }}
        className="cps-form-control"
        style={{ maxWidth: "100%" }}
      ></textarea>
    </div>
  );
}

export default InputDecorator(Note);
