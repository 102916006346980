import React from "react";
// project
import Input from "src/form/input/input.component.js";
import { FieldLabel } from "src/common/client-record-fields";

export default class BusinessNameSection extends React.Component {
  render() {
    return (
      <Input
        className="business_name"
        maxLength={150}
        name="business_name"
        placeholder="Company name"
        type="text"
        invalidMessage={"Company name is required"}
        required={true}
        label={this.createLabel}
      />
    );
  }

  createLabel = ({ valid }) => {
    return (
      <FieldLabel>
        <span>Company name</span>
        <span className={valid ? "cps-color-primary" : undefined}>*</span>
      </FieldLabel>
    );
  };
}
