import React from "react";
import { SimpleSelect } from "src/form/select-input/select-input.component.js";

const options = [
  { key: "single", value: "Single" },
  { key: "married-joint", value: "Married filing jointly" },
  { key: "married-separate", value: "Married filing separately" },
  { key: "head-of-household", value: "Head of household" },
  { key: "widow", value: "Qualifying widow(er) with dependent child" },
];

export function FilingStatusSelect({ value, onChange }) {
  return (
    <SimpleSelect
      labelText="Filing Status"
      options={options}
      value={value?.key}
      updateValue={(key) => {
        return onChange(options.find((o) => o.key === key) || { key: "", value: undefined });
      }}
      clearable
    />
  );
}
