import { useState, useMemo, useCallback } from "react";

export default function useSelections({ totalSize }) {
  const [allSelected, setAllSelected] = useState(false);
  const [selectionType, setSelectionType] = useState("includes"); // 'includes' or 'excludes'
  const [selectionMap, setSelectionMap] = useState({});

  const selectAll = useCallback(() => {
    setSelectionMap({});
    setAllSelected(true);
    setSelectionType("excludes");
  }, []);

  const deselectAll = useCallback(() => {
    setSelectionMap({});
    setAllSelected(false);
    setSelectionType("includes");
  }, []);

  const toggleSelection = useCallback(
    (clientId) => {
      if (allSelected) {
        setAllSelected(false);
        setSelectionType("excludes");
      }

      const newSelection = { ...selectionMap, [clientId]: !selectionMap[clientId] };
      setSelectionMap(newSelection);

      const checkedCount = Object.values(newSelection).filter((s) => !!s).length;
      if (checkedCount >= totalSize) {
        selectionType === "excludes" ? deselectAll() : selectAll();
      } else if (checkedCount <= 0 && selectionType === "excludes") {
        selectAll();
      }
    },
    [allSelected, selectionMap, selectionType, selectAll, deselectAll, totalSize]
  );

  const selection = useMemo(() => {
    const selectionLength = Object.values(selectionMap).filter((s) => !!s).length;
    const totalSelected = selectionType === "excludes" ? totalSize - selectionLength : selectionLength;

    return {
      allSelected,
      byId: selectionMap,
      type: selectionType,
      length: selectionLength, // A count of how many inclusive or exclusive selections there are
      totalSelected, // A count of how many resulting items will be selected
      toArray: () =>
        Object.entries(selectionMap)
          .filter(([key, val]) => !!val)
          .map(([key]) => parseInt(key)),
      deselectAll,
    };
  }, [allSelected, selectionType, selectionMap, totalSize, deselectAll]);

  return {
    selection,
    selectAll,
    deselectAll,
    toggleSelection,
  };
}
