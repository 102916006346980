import React, { useEffect, useMemo } from "react";
import RolesSection from "./roles-section.component";
import UsersTeamsSection from "../users-teams-section/users-teams-section.component";
import { useFormInput } from "src/forms-lib";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import useRolesQuery from "./use-roles-query-hook";
import useTeamMembersQuery from "src/client-list/use-team-members-query.hook";
import { isUserIncluded } from "src/create-edit-contact/create-edit-contact-form/roles-section/roles.helpers";

export default function RolesSectionForCreateEditModal({ roleAssignments, usersTeams, defaultUser }) {
  const { roles } = useRolesQuery();
  const { teamMembers: users, teams } = useTeamMembersQuery();
  const [loggedInUser] = useWithUserAndTenant();
  const [rolesValues, updateRolesValues] = useFormInput({ name: "roles_fields", initialValue: {} });
  const [usersTeamsValue, updateUsersTeamsValue] = useFormInput({ name: "users_teams_field", initialValue: [] });
  const hasFullContactAccess = useHasAccess("contacts_not_assigned");

  const showCurrentUserNotAssigned = useMemo(() => {
    if (hasFullContactAccess) return false;
    return !isUserIncluded(loggedInUser.id, { ...rolesValues, usersTeamsValue });
  }, [rolesValues, usersTeamsValue, loggedInUser.id, hasFullContactAccess]);

  useEffect(() => {
    if (usersTeams && users && teams) {
      const usersArr =
        usersTeams.users?.map((userId) => {
          return users.find((u) => u.id === userId);
        }) || [];
      const teamsArr =
        usersTeams.teams?.map((teamId) => {
          return teams.find((t) => t.id === teamId);
        }) || [];
      updateUsersTeamsValue([...usersArr, ...teamsArr]);
    } else if (defaultUser && users) {
      updateUsersTeamsValue([users.find((u) => defaultUser === u.id)]);
    }
  }, [usersTeams, users, teams, defaultUser]); // eslint-disable-line
  // lint-TODO: Missing deps, need to make sure updateUsersTeamsValue is memoized

  useEffect(() => {
    if (users && teams) {
      updateRolesValues(mapRoleAssignmentsToState(roleAssignments, users, teams));
    }
  }, [roleAssignments, users, teams]); // eslint-disable-line
  // lint-TODO: Missing deps, need to make sure updateRolesValues is memoized

  const getRoleValue = (roleId) => {
    return rolesValues?.[roleId];
  };

  const updateRolesValuesCallback = (newValue, roleId) => {
    updateRolesValues({ ...rolesValues, [roleId]: newValue });
  };

  return (
    <>
      {roles.length > 0 && (
        <>
          <div className="cp-body-sm cp-wt-semibold cp-mb-16">Roles</div>
          <RolesSection
            roles={roles}
            value={getRoleValue}
            onChange={updateRolesValuesCallback}
            showCurrentUserNotAssigned={showCurrentUserNotAssigned}
          />
        </>
      )}
      <UsersTeamsSection
        value={usersTeamsValue}
        onChange={updateUsersTeamsValue}
        hasRoles={roles.length > 0}
        showCurrentUserNotAssigned={roles.length === 0 && showCurrentUserNotAssigned}
      />
    </>
  );
}

function mapRoleAssignmentsToState(roleAssignments, users, teams) {
  const newState = {};
  roleAssignments?.forEach((item) => {
    const usersArr = item?.users?.map((id) => users.find((u) => u.id === id)) || [];
    const teamsArr = item?.teams?.map((id) => teams.find((t) => t.id === id)) || [];
    newState[item.role_id] = [...usersArr, ...teamsArr];
  });
  return newState;
}
