import { forIn } from "lodash";

export const modelToContact = {
  topSection: topSection,
  emails,
  phones,
  addresses,
  bottomSection,
  always,
  rolesUsersTeamsSections,
};

function always(model) {
  const always = {
    id: model.id,
    is_hidden: model.is_hidden,
    filing_status: model.filing_status,
    custom_fields: createCustomFields(model),
    external_id: model.external_id,
  };

  return always;
}

function createCustomFields(model) {
  const objectRepresentation = model.custom_fields || {};
  // convert object to array for the api
  let arrayRepresentation = [];
  Object.entries(objectRepresentation).map((field) => {
    if (field[1].type === "dropdown") {
      return arrayRepresentation.push(handleCustomFieldSingleSelect(field));
    } else if (field[1].type === "multiselect") {
      return arrayRepresentation.push(handleCustomFieldMultiSelect(field));
    } else {
      return arrayRepresentation.push({ field_id: field[0], value: field[1].value });
    }
  });
  return arrayRepresentation;
}

function handleCustomFieldSingleSelect(field) {
  const selectedOption = field[1].value.key ? [field[1].value.key] : [];
  return { field_id: field[0], dropdown_values: selectedOption };
}

function handleCustomFieldMultiSelect(field) {
  const selections = field[1].value.length ? field[1].value.map((val) => val.key) : [];
  return { field_id: field[0], dropdown_values: selections };
}

function bottomSection(model) {
  let bottomSectionBusinessFields = {};
  let bottomSectionIndividualFields = {};
  if (model.is_business === true) {
    bottomSectionBusinessFields = {
      tin: !!model.ein && model.ein !== "" ? model.ein : undefined,
      business_type: model.business_type,
      industry: model.industry,
      contact_person: model.contact_person,
    };
    if (model.date_established instanceof Date) {
      bottomSectionBusinessFields.date_established = model.date_established.getTime();
    }
  } else {
    bottomSectionIndividualFields = {
      tin: !!model.ssn && model.ssn !== "" ? model.ssn : undefined,
      employer: model.employer && model.employer.trim(),
      occupation: model.occupation && model.occupation.trim(),
    };
    if (model.birth_date instanceof Date) {
      bottomSectionIndividualFields.birthdate = model.birth_date.getTime();
    }
  }
  const contactTags = tags(model);
  const contactSource = source(model);

  return {
    contact_owner_id: model.contact_owner ? model.contact_owner.id : undefined,
    ...bottomSectionBusinessFields,
    ...bottomSectionIndividualFields,
    notes: model.note && model.note.trim(),
    ...contactTags,
    ...contactSource,
  };
}

function topSection(model) {
  const returnValue = {
    first_name: model.first_name?.trim(),
    middle_name: model.middle_name?.trim(),
    last_name: model.last_name?.trim(),
    display_name: model.display_name?.trim(),
    is_business: model.is_business,
    contact_type: model.contact_type,
    is_active: model.is_active,
  };
  if (model.is_business === true) {
    returnValue.business_name = model.business_name;
  }
  if (model.client_since instanceof Date) {
    returnValue.client_since = model.client_since.getTime();
  }
  return returnValue;
}

function emails(model) {
  const emailArray = model.email_addresses
    .map((email) => {
      if (email.id && email.address !== "") {
        return {
          id: email.id.includes("create_") ? undefined : email.id,
          isPrimary: email.is_primary,
          key: email.type,
          value: email.address,
        };
      }
    })
    .filter((email) => !!email);
  return {
    emails: emailArray.length > 0 ? emailArray : undefined,
  };
}

function phones(model) {
  const phoneArray = model.phone_numbers
    .map((phone) => {
      if (phone.id && phone.number !== "") {
        return {
          id: phone.id.includes("create_") ? undefined : phone.id,
          isPrimary: phone.is_primary,
          key: phone.type,
          extension: phone.ext,
          number: phone.number,
          value: `${phone.number}${phone.ext ? ` x ${phone.ext}` : ""}`,
        };
      }
    })
    .filter((phone) => !!phone);
  return {
    phones: phoneArray.length > 0 ? phoneArray : undefined,
  };
}

function addresses(model) {
  const addressArray = model.addresses
    .map((address) => {
      if (checkForSomeAddressValues(address)) {
        return {
          id: address.id.includes("create_") ? undefined : address.id,
          key: address.type,
          isPrimary: address.is_primary,
          value: {
            address_1: address.line_1 && address.line_1.trim(),
            address_2: address.line_2 && address.line_2.trim(),
            country: address.country,
            region: address.region && address.region.trim(),
            locality: address.city && address.city.trim(),
            postal_code: address.postal_code && address.postal_code.trim(),
          },
        };
      }
    })
    .filter((address) => !!address);
  return {
    addresses: addressArray.length > 0 ? addressArray : undefined,
  };
}

function checkForSomeAddressValues(address) {
  return address.line_1 || address.line_2 || address.region || address.city || address.postal_code;
}

function tags(model) {
  return {
    tags: model.tags,
  };
}

function source(model) {
  const source = {
    ...model.source,
  };
  if (source.name !== "Referral") {
    source.selected = undefined;
  }
  if (source.id === undefined) {
    source.name = null;
  }
  return { source };
}

function rolesUsersTeamsSections(model) {
  const role_assignments = [];
  if (model.roles_fields) {
    forIn(model.roles_fields, (val, key) => {
      const users = [];
      const teams = [];
      val?.forEach((item) => {
        item.role === "TeamMember" ? users.push(item.id) : teams.push(item.id);
      });
      role_assignments.push({
        role_id: key,
        users,
        teams,
      });
    });
  }
  const users = [];
  const teams = [];
  if (model.users_teams_field) {
    model.users_teams_field?.forEach((item) => {
      if (!item) return;
      item.role === "TeamMember" ? users.push(item.id) : teams.push(item.id);
    });
  }

  return {
    role_assignments,
    users,
    teams,
  };
}
