import formLibForm from "./form.component.js";
import formLibDecorator from "./input-decorator.component.js";
import formLibContext from "./form.context.js";
import formSharedValueDecorator from "./shared-value-decorator.js";
import formUseSharedValue from "./use-shared-value.hook.js";
import formUseInput from "./use-form-input.hook.js";

export const Form = formLibForm;
export const InputDecorator = formLibDecorator;
export const Context = formLibContext;
export const SharedValueDecorator = formSharedValueDecorator;
export const useFormInput = formUseInput;
export const useSharedValue = formUseSharedValue;
// deprecated
export const useSharedState = formUseSharedValue;
