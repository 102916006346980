import React from "react";
import styles from "./client-type-picker.styles.css";
import { m } from "kremling";
import { FieldLabel } from "src/common/client-record-fields";

const options = [
  {
    label: "Client",
    value: "client",
  },
  {
    label: "Other",
    value: "other",
  },
  {
    label: "Prospect",
    value: "prospect",
  },
];

export function ClientTypePicker({ className, onChange, value }) {
  return (
    <div className={className || ""}>
      <FieldLabel>Type</FieldLabel>
      <div className="flex cp-gap-8 cp-pt-4">
        {options.map((option) => (
          <button
            key={option.value}
            type="button"
            className={m(styles.selectedTypeOption, value === option.value).a(styles.typeOption)}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}
