// @flow
import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./address.krem.css";
import { Input } from "src/form/input/input.component.js";
import Actions from "../../list-of-items/actions/actions.component.js";
import StateSelect from "./state-select.component.js";
import CountryInput from "./country-select.component.js";
import { SimpleSelect } from "src/form/select-input/select-input.component.js";
// types
import type { AddressOptionType, AddressType } from "../address-section.component.js";

type Props = {
  updateAddress: Function,
  name: string,
  removeAddress: Function,
  showInvalid: boolean,
  removeRef: Function,
  setPrimaryById: Function,
  showActions: boolean,
  address: AddressType,
  options: Array<AddressOptionType>,
};

export default class Address extends React.Component<Props> {
  addressLine1: ?Input;
  addressLine2: ?Input;
  city: ?Input;
  postal_code: ?Input;

  render() {
    const { address } = this.props;
    const isUS = isUSA(this.props.address.country);
    return (
      <Scoped css={styles}>
        <div className="address flex">
          <div className="addressBlock">
            <div className="addressTypeAndActions cps-margin-bottom-16">
              <div className="addressTypeBlock margin-between">
                <div className="middle-section-dropdown">
                  <SimpleSelect
                    value={this.props.address.type}
                    updateValue={this.updateType}
                    options={this.props.options}
                    ariaLabelText={"Address Type Select"}
                  />
                </div>
              </div>
              {this.props.showActions && (
                <div>
                  <Actions
                    isPrimary={this.props.address.is_primary}
                    setPrimary={this.setPrimary}
                    starHtml={`Primary address`}
                    trashHtml={`Remove address`}
                    remove={this.removeAddress}
                  />
                </div>
              )}
            </div>
            <Input
              outerClass={"cps-margin-bottom-16"}
              name="address_line_1"
              type="text"
              name={"address_line_1"}
              maxLength={100}
              placeholder="Street 1"
              value={this.props.address.line_1}
              updateValue={this.updateLine1}
              required={true}
              showInvalid={this.props.showInvalid}
              showError={false}
              ref={(el) => (this.addressLine1 = el)}
            />
            <Input
              outerClass={"cps-margin-bottom-16"}
              name="address_line_2"
              maxLength={100}
              type="text"
              name={"address_line_2"}
              placeholder="Street 2"
              value={this.props.address.line_2}
              updateValue={this.updateLine2}
              showInvalid={this.props.showInvalid}
              showError={false}
              ref={(el) => (this.addressLine2 = el)}
            />
            <div className="flex margin-between width100 cps-margin-bottom-16">
              <Input
                outerClass={"cps-margin-bottom-0 flex2"}
                name="city"
                type="text"
                placeholder="City"
                maxLength={100}
                value={this.props.address.city}
                updateValue={this.updateCity}
                showInvalid={this.props.showInvalid}
                showError={false}
                ref={(el) => (this.city = el)}
              />
              <div className="flex1">
                {isUS ? (
                  <StateSelect
                    selected={this.props.address.region}
                    onChange={this.updateRegion}
                    outerClass={"width100"}
                  ></StateSelect>
                ) : (
                  <Input
                    name="region"
                    type="text"
                    maxLength={100}
                    placeholder={"Province/Region"}
                    value={this.props.address.region}
                    updateValue={this.updateRegion}
                  />
                )}
              </div>
              <Input
                outerClass="cps-margin-bottom-0 flex1"
                name="postal_code"
                type="text"
                placeholder={isUS ? "Zip code" : "Postal code"}
                maxLength={12}
                value={this.props.address.postal_code}
                updateValue={this.updatePostalCode}
                showInvalid={this.props.showInvalid}
                showError={false}
                ref={(el) => (this.postal_code = el)}
              />
            </div>
            <CountryInput selected={this.props.address.country} onChange={this.updateCountry} />
          </div>
        </div>
      </Scoped>
    );
  }

  focus = () => {
    this.addressLine1 && this.addressLine1.focus();
  };

  setPrimary = () => {
    this.props.setPrimaryById(this.props.address.id, !this.props.address.is_primary);
  };

  removePress = (evt: SyntheticKeyboardEvent<HTMLAnchorElement>) => {
    if (evt.key === " " || evt.key === "Enter") {
      this.removeAddress();
    }
  };

  removeAddress = () => {
    this.props.removeRef(this);
    this.props.removeAddress(this.props.address.id);
  };

  updateType = (value: string) => {
    const address = { ...this.props.address, type: value };
    this.props.updateAddress(this.props.address.id, address);
  };

  updateLine1 = (value: string) => {
    const address = { ...this.props.address, line_1: value };
    this.props.updateAddress(this.props.address.id, address);
  };

  updateLine2 = (value: string) => {
    const address = { ...this.props.address, line_2: value };
    this.props.updateAddress(this.props.address.id, address);
  };

  updateCity = (value: string) => {
    const address = { ...this.props.address, city: value };
    this.props.updateAddress(this.props.address.id, address);
  };

  updatePostalCode = (value: string) => {
    let postal_code = value;
    if (isUSA(this.props.address.country)) {
      postal_code = postal_code.replace(/[^0-9\-]/g, "");
    }
    const address = { ...this.props.address, postal_code };
    this.props.updateAddress(this.props.address.id, address);
  };

  updateRegion = (value: string) => {
    const address = { ...this.props.address, region: value };
    this.props.updateAddress(this.props.address.id, address);
  };

  updateMailingAddress = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    const address = { ...this.props.address, mailing_address: value };
    this.props.updateAddress(this.props.address.id, address);
  };

  updateCountry = (country: string) => {
    const address = { ...this.props.address, country, region: "" };
    this.props.updateAddress(this.props.address.id, address);
  };
}

function isUSA(country) {
  return country === "United States";
}
