import React, { useEffect, useState, lazy, Suspense } from "react";
import ContactCardLoader from "./contact-card-loader.component";
import { useFetcher } from "fetcher!sofe";
import { CprEmptyState } from "canopy-styleguide!sofe";
import { useCss, m } from "kremling";
import { getFirstLine } from "rich-text-ui!sofe";
const NoteModal = lazy(() =>
  import("notes-ui!sofe")
    .then((module) => module.getCreateEditNoteModal())
    .then((modal) => modal)
);

export default function NotesList(props) {
  const [notes, setNotes] = useState();
  const [showModal, setShowModal] = useState();

  const { loading, results, error, refetch } = useFetcher(`/api/clients/${props.contactId}/notes`, { pluck: "notes" });

  useEffect(() => {
    if (results) {
      setNotes(results.slice(0, 6));
    }
  }, [results]);

  useEffect(() => {
    if (props.buttonClick) {
      setShowModal(true);
    }
  }, [props.buttonClick]);

  const close = (note) => {
    if (note) {
      setNotes((notes) =>
        typeof showModal === "boolean" // created
          ? [note, ...notes.slice(0, 5)]
          : [note, ...notes.filter((n) => n.id !== note.id)]
      );
    }
    setShowModal();
  };

  const emptyNotes = () => {
    return (
      <CprEmptyState
        iconSrc="es_notes"
        headText="No notes"
        subheadText="There are no notes for this contact."
        showCta={true}
        ctaClickHandler={props.setButtonClick}
        ctaButtonText="Add note"
        ctaButtonType="secondary"
      />
    );
  };

  return (
    <div {...useCss(styles)} className={`${m("full", notes?.length === 6).m("empty", notes?.length === 0)}`}>
      {loading ? (
        <ContactCardLoader />
      ) : !notes?.length ? (
        emptyNotes()
      ) : (
        notes.map((note) => (
          <div key={note.id} className="row" onClick={() => setShowModal(note)}>
            <div className="cps-ellipsis">{note.title}&nbsp;</div>
            <div
              className="cps-color-cool-gray cps-ellipsis"
              dangerouslySetInnerHTML={{ __html: getFirstLine(note.body) || "&nbsp;" }}
            />
          </div>
        ))
      )}
      {
        <Suspense fallback={<></>}>
          <NoteModal show={!!showModal} note={showModal} onClose={close} contactId={props.contactId} />
        </Suspense>
      }
    </div>
  );
}

const styles = `
& .row {
  flex-direction: column;
  justify-content: center;
  height: 70.1px;
}
`;
