// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-kabob-menu-styles__divider {\n  height: 0.1rem;\n  background-color: var(--cp-color-app-border);\n  margin: 0.4rem 0rem 0.4rem 0rem;\n}\n\n.src-client-list-kabob-menu-styles__displayAsButton {\n  /* Keeping the min height to 40px to account for the \"checked\" icon that changes the option height */\n  min-height: 4rem;\n}\n", ""]);
// Exports
exports.locals = {
	"divider": "src-client-list-kabob-menu-styles__divider",
	"displayAsButton": "src-client-list-kabob-menu-styles__displayAsButton"
};
module.exports = exports;
