import React from "react";
import { isValidEmail } from "src/common/utils.helpers.js";
import { CprButton } from "canopy-styleguide!sofe";
import { Scoped } from "kremling";
import styles from "./add-another-person.krem.css";

export default class AddAnotherPerson extends React.Component {
  state = {
    newInviteEmail: "",
    invalidEmail: true,
    error: false,
  };

  render() {
    return (
      <Scoped css={styles}>
        <div className={`inviteEmailSlat ${this.state.error && "cps-has-error"}`}>
          <input
            style={{ width: "356px" }}
            type="text"
            value={this.state.newInviteEmail}
            onChange={this.handleChangeNewInviteEmail}
            onKeyDown={this.onKeyDown}
            placeholder="Enter the email of a new person"
            className={`cps-form-control`}
          />
          <CprButton
            type="button"
            actionType={"primary"}
            className={`cps-margin-left-16 addButton`}
            onClick={this.handleAdd}
            disabled={this.state.invalidEmail}
          >
            Add
          </CprButton>
          <CprButton type="button" onClick={this.props.removePerson} actionType={"flat"} className={"removeButton"}>
            Remove
          </CprButton>
          <div className={`cps-error-block ${this.state.error && "cps-has-error"}`}>{this.state.error}</div>
        </div>
      </Scoped>
    );
  }

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      this.addNewInvite(this.state.newInviteEmail);
    }
  };

  handleChangeNewInviteEmail = (e) => {
    const newEmail = e.target.value;

    this.setState({
      newInviteEmail: newEmail,
      invalidEmail: !isValidEmail(newEmail),
    });
  };

  handleAdd = () => {
    this.addNewInvite(this.state.newInviteEmail);
  };

  addNewInvite = (email) => {
    if (!email) return;

    const isInvalid = !isValidEmail(email);
    const isDupe = this.props.allInvites.find((i) => i.email.toLowerCase() === email.toLowerCase());
    const existingUser = this.props.getExistingUser(email);
    const isExistingTeamMember = existingUser && existingUser.role !== "Client";

    if (isInvalid || isDupe || isExistingTeamMember) {
      this.setState({
        error: isInvalid
          ? "Please enter a valid email."
          : isDupe
          ? "You've entered a duplicate email."
          : "A user already exists with this email. (They may be deleted or deactivated.)",
      });
    } else {
      this.props.addNewInvite({ email });
      this.setState(
        {
          newInviteEmail: "",
          error: false,
        },
        this.props.removePerson
      );
    }
  };
}
