// @flow
import * as React from "react";
import FormContext from "./form.context.js";
import type { FormContextTypes } from "./form.context.js";
import { catchAsyncStacktrace } from "auto-trace";

type Context = {
  ...$Exact<FormContextTypes>,
};

type State = {
  value: *,
};

type Props = {
  context: Context,
  sharedKey: ?string,
  alwaysShow?: boolean,
};

type NewComponentProps = {
  sharedValue: *,
};

export default function (DecoratedComponent: React.ComponentType<NewComponentProps>) {
  const originalDisplayName = DecoratedComponent.displayName || DecoratedComponent.name || "unknown";

  class FormSharedValueConsumer extends React.Component<Props, State> {
    static displayName = `FormSharedValueConsumer(${originalDisplayName})`;

    state = { value: undefined };
    subscription: { subscribe: Function, unsubscribe: Function };

    componentDidMount() {
      this.subscription = this.props.context.sharedStateObs.subscribe((sharedState) => {
        const sharedKey = this.props.sharedKey;
        const valueToShare = sharedKey ? sharedState[sharedKey] : sharedState;
        if (valueToShare === undefined && sharedKey && !this.props.alwaysShow) {
          console.warn(
            `Provided sharedKey "${sharedKey}" did not exist on the sharedModel. ${originalDisplayName} was not rendered.`
          );
        }
        this.setState({ value: valueToShare });
      }, catchAsyncStacktrace());
    }

    render() {
      const { alwaysShow, context, sharedKey, ...rest } = this.props;
      if (this.props.alwaysShow || this.state.value !== undefined) {
        return <DecoratedComponent {...rest} sharedValue={this.state.value} />;
      } else {
        return null;
      }
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
    }
  }

  return function (props: { sharedKey: string }) {
    return (
      <FormContext.Consumer>
        {(value: Context) => <FormSharedValueConsumer {...props} context={value} />}
      </FormContext.Consumer>
    );
  };
}
