// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-table-headers-filter-header-styles__filterButton {\n  flex-shrink: 0;\n  margin-left: 0.8rem;\n}\n.src-client-list-table-headers-filter-header-styles__filterLabel {\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"filterButton": "src-client-list-table-headers-filter-header-styles__filterButton",
	"filterLabel": "src-client-list-table-headers-filter-header-styles__filterLabel"
};
module.exports = exports;
