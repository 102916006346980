import { useState, useEffect, useContext } from "react";
import { Context } from "src/forms-lib";
import { get } from "lodash";

export default function useSharedState(key) {
  const context = useContext(Context);
  const [sharedState, setSharedState] = useState({});

  useEffect(() => {
    const subscription = context.sharedStateObs.subscribe((sharedStateObj) => {
      if (key) {
        setSharedState(get(sharedStateObj, key));
      } else {
        setSharedState(sharedStateObj);
      }
    });

    return () => subscription.unsubscribe();
  }, [context]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  return sharedState;
}
