// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-create-edit-contact-create-edit-contact-form-users-teams-section-users-teams-section-styles__container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1.6rem;\n  margin-right: 1.6rem;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-create-edit-contact-create-edit-contact-form-users-teams-section-users-teams-section-styles__container"
};
module.exports = exports;
