import React, { lazy, Suspense } from "react";
import { CpLoader } from "canopy-styleguide!sofe";

const NotificationMessageImport = lazy(() => {
  return SystemJS.import("notifications-ui!sofe").then((notifications) => notifications.loadNotificationMessage());
});

export default function NotificationMessage(props) {
  return (
    <Suspense fallback={<CpLoader />}>
      <NotificationMessageImport {...props} />
    </Suspense>
  );
}
