// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dedupe-modal-modals-resolve-table-styles__resolveTable {\n  background: rgba(0, 150, 230, 0.05);\n  color: #0096e6;\n  border: 2px solid #ecf4f8;\n  overflow: auto;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__justifyLeft {\n  justify-content: flex-start;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__resolveTableHeader {\n  border-bottom: 2px solid #ecf4f8;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__resolveTableHeaderText {\n  font-weight: normal;\n  text-align: left;\n  padding: 8px;\n  border: inherit;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__resolveTableText {\n  font-weight: bold;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__radioLabel {\n  margin: 0px;\n  white-space: nowrap;\n  float: left;\n  clear: both;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__resolveCard {\n  width: 70rem !important;\n  left: 44%;\n}\n\n.src-dedupe-modal-modals-resolve-table-styles__radioLabelSpan {\n  padding: 0.4rem 2.4rem !important;\n}\n", ""]);
// Exports
exports.locals = {
	"resolveTable": "src-dedupe-modal-modals-resolve-table-styles__resolveTable",
	"justifyLeft": "src-dedupe-modal-modals-resolve-table-styles__justifyLeft",
	"resolveTableHeader": "src-dedupe-modal-modals-resolve-table-styles__resolveTableHeader",
	"resolveTableHeaderText": "src-dedupe-modal-modals-resolve-table-styles__resolveTableHeaderText",
	"resolveTableText": "src-dedupe-modal-modals-resolve-table-styles__resolveTableText",
	"radioLabel": "src-dedupe-modal-modals-resolve-table-styles__radioLabel",
	"resolveCard": "src-dedupe-modal-modals-resolve-table-styles__resolveCard",
	"radioLabelSpan": "src-dedupe-modal-modals-resolve-table-styles__radioLabelSpan"
};
module.exports = exports;
