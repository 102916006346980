// @flow
import React, { useEffect, useState } from "react";
import { catchSyncStacktrace, asyncStacktrace, catchAsyncStacktrace } from "auto-trace";
// project
import Modal from "src/modal/modal.component.js";
import CreateEditContactModalContents from "./create-edit-contact-modal-contents.component.js";
import { getContactAndAll } from "src/contact-page/contact-page.resource.js";
import { getContactsUsage } from "src/resources/contacts.resource";
import { useCustomFields } from "./custom-fields.hooks.js";
import { useIsContactLimitModel } from "cp-client-auth!sofe";
import useQboIntegrationQuery from "src/client-list/use-qbo-integration-query.hook";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient, queryContext } from "src/react-query";
import useRolesQuery from "../create-edit-contact-form/roles-section/use-roles-query-hook.js";

type ContactLimitModelCheckProps = {
  onModalHide: () => void,
  contact: any,
  isCreate?: boolean,
  contactId?: string,
  hideBusinessChooser?: boolean,
  redirectAsPrimary?: boolean,
};

type Props = {
  onModalHide: () => void,
  contact: any,
  isCreate?: boolean,
  contactId?: string,
  hideBusinessChooser?: boolean,
  redirectAsPrimary?: boolean,
  isContactLimitModel?: boolean,
  contactsUsageData: any,
};

export function CreateEditContactModalWrapper(props) {
  const { contact, contactId, client, clientId, ...rest } = props;

  return (
    <QueryClientProvider client={queryClient} context={queryContext}>
      <CreateEditContactModal contact={contact || client} contactId={contactId || clientId} {...rest} />
    </QueryClientProvider>
  );
}

export default function CreateEditContactModalContactLimitModelCheck(props: ContactLimitModelCheckProps) {
  const isContactLimitModel = useIsContactLimitModel();
  const contactsUsageData = useContactsUsageResource();

  return (
    <CreateEditContactModalWrapper
      contactsUsageData={contactsUsageData}
      isContactLimitModel={isContactLimitModel}
      {...props}
    />
  );

  function useContactsUsageResource() {
    const [contactsUsageData, setContactsUsageData] = useState(null);
    useEffect(() => {
      if (isContactLimitModel) {
        const subscription = getContactsUsage().subscribe((data) => setContactsUsageData(data), catchAsyncStacktrace());

        return () => subscription.unsubscribe();
      }
    }, [isContactLimitModel]); // eslint-disable-line
    // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

    return contactsUsageData;
  }
}

export function CreateEditContactModal(props: Props) {
  const { redirectAsPrimary = true, isContactLimitModel, contactsUsageData, isCreate = true } = props;
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(props.contact);
  const [error, setError] = useState();

  const customFields = useCustomFields();
  const { roles } = useRolesQuery();
  const { qboIntegrationInfo } = useQboIntegrationQuery();

  useEffect(() => {
    if (props.contactId !== undefined && contact === undefined && error === undefined) {
      setLoading(true);
      const req$: * = getContactAndAll(props.contactId, !isCreate).subscribe(
        (contact) => {
          setContact(contact);
          setLoading(false);
        },
        asyncStacktrace((err) => {
          setError(err);
          setLoading(false);
          catchSyncStacktrace(err);
        })
      );
      return () => {
        req$.unsubscribe();
      };
    }
  }, [props.contactId, contact, error]); //eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  if (isContactLimitModel === null || (isContactLimitModel && !contactsUsageData)) {
    return null;
  }

  return (
    <Modal onModalHide={props.onModalHide} getInitialFocus={getInitialFocus}>
      <CreateEditContactModalContents
        loading={loading}
        error={error}
        contact={contact}
        isCreate={props.isCreate}
        hideBusinessChooser={props.hideBusinessChooser}
        customFields={customFields}
        roles={roles}
        redirectAsPrimary={redirectAsPrimary}
        isContactLimitModel={isContactLimitModel}
        contactsUsageData={contactsUsageData}
        qboIntegrationInfo={qboIntegrationInfo}
        checkIntegrationAuth={() => queryClient.invalidateQueries(["qbo-integration"])}
      />
    </Modal>
  );
}

function getInitialFocus(modalRef) {
  return (
    modalRef.querySelector(".businessOption") ||
    modalRef.querySelector(".firstName input") ||
    modalRef.querySelector(".business_name") ||
    modalRef.querySelector("cps-button-icon") ||
    modalRef
  );
}
