// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-create-edit-contact-create-edit-contact-modal-create-edit-contact-modal-styles__contactLimit {\n  font-size: 1.3rem;\n  font-weight: bold;\n  color: var(--cps-color-monsoon);\n}\n\n.src-create-edit-contact-create-edit-contact-modal-create-edit-contact-modal-styles__modalBody {\n  padding: 0 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"contactLimit": "src-create-edit-contact-create-edit-contact-modal-create-edit-contact-modal-styles__contactLimit",
	"modalBody": "src-create-edit-contact-create-edit-contact-modal-create-edit-contact-modal-styles__modalBody"
};
module.exports = exports;
