import { catchError, map, tap } from "rxjs/operators";

import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable, forceBustCache } from "fetcher!sofe";
import { checkForFailedFetch } from "./resource.helpers.js";

const headers =
  typeof Headers !== "undefined"
    ? new Headers({
        "Content-Type": "application/json",
      })
    : {};

// Used to assign users to a client
export function postClientUsers(clientId, userIds, notifications) {
  if (!clientId) throw new Error("Cannot post client users without being given a client ID");

  let body = { users: userIds };
  if (notifications) {
    body.notifications = notifications;
  }

  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${clientId}/users`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  }).pipe(
    map((resp) => resp.users.id),
    catchError(checkForFailedFetch)
  );
}

export function removeContactUser(contactId, userId) {
  if (!contactId) throw new Error(`Cannot delete contact user without contactId`);
  if (!userId) throw new Error(`Cannot delete contact user without userId`);

  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}/users/${userId}`, {
    method: "delete",
  }).pipe(
    tap(() => {
      forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}`);
      forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/contacts/${contactId}`);
    })
  );
}
