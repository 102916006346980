import React from "react";
import { Scoped } from "kremling";
import styles from "./existing-invite.krem.css";
import { CprTooltip, CpCheckbox } from "canopy-styleguide!sofe";

export default class ExistingInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userChecked: false,
    };
  }

  componentDidMount() {
    const checkForNewUserMatch = this.props.newUsersToAdd?.find(
      (i) => i.email.toLowerCase() === this.props.invite.email.toLowerCase()
    );
    const checkForInviteMatch = !!this.props.invites.find(
      (i) => i.email.toLowerCase() === this.props.invite.email.toLowerCase()
    );

    if (checkForNewUserMatch || checkForInviteMatch) {
      this.setState({ userChecked: true });
    } else {
      this.setState({ userChecked: false });
    }
  }

  render() {
    const { invite } = this.props;
    return (
      <Scoped css={styles}>
        <div className={`inviteSlat`} data-testid="existing-invite">
          <CpCheckbox
            onChange={this.handleChange}
            checked={this.state.userChecked}
            aria-label={`Invite ${invite.email}`}
          ></CpCheckbox>
          {invite.name ? (
            <div className={`inviteDetails`} style={{ cursor: "default" }}>
              <div className={"nameAndRel"}>
                <div className={`cps-wt-bold cps-body inviteName`}>
                  <CprTooltip html={invite.name} disabled={this.checkNameRef()}>
                    <span ref={(el) => (this.nameRef = el)}>{invite.name}</span>
                  </CprTooltip>
                </div>
                <div className={`cps-light-gray relationship`}>
                  &nbsp;
                  {invite.relationship &&
                    `${invite.relationship.charAt(0).toUpperCase()}${invite.relationship.slice(1)}`}
                </div>
              </div>
              <CprTooltip html={invite.email} disabled={this.checkEmailRef()}>
                <span className={`cps-margin-right-4`}>
                  {invite.emailType && `${invite.emailType.charAt(0).toUpperCase()}${invite.emailType.slice(1)}:`}
                </span>
                <span className={`cps-light-gray inviteEmail`} ref={(el) => (this.emailRef = el)}>
                  {invite.email}
                </span>
              </CprTooltip>
            </div>
          ) : (
            <div>{invite.email}</div>
          )}
        </div>
      </Scoped>
    );
  }

  handleChange = (checked) => {
    this.setState({ userChecked: checked });
    this.props.handleToggleInvite(this.props.invite, checked);
  };

  checkEmailRef = () => {
    if (this.emailRef) {
      return this.emailRef.offsetWidth < 192;
    }
  };

  checkNameRef = () => {
    if (this.nameRef) {
      return this.nameRef.offsetWidth < 192;
    }
  };
}
