// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-templates-select-template-button-template-list-styles__container .src-templates-select-template-button-template-list-styles__section:first-child {\n  border-bottom: 0.1rem solid var(--cp-color-nav-active-border);\n}\n\n.src-templates-select-template-button-template-list-styles__section {\n  max-height: 50vh;\n  overflow-y: auto;\n}", ""]);
// Exports
exports.locals = {
	"container": "src-templates-select-template-button-template-list-styles__container",
	"section": "src-templates-select-template-button-template-list-styles__section"
};
module.exports = exports;
