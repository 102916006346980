import React, { lazy, Suspense, useState } from "react";
import { CpModal, CpButton, modalService, cdnImage } from "canopy-styleguide!sofe";
import { useQueryClient } from "src/react-query";

import useQboIntegrationQuery from "src/client-list/use-qbo-integration-query.hook";

const IntegrationSyncErrorModal = lazy(() =>
  SystemJS.import("integrations-ui!sofe").then((module) => module.getIntegrationSyncErrorModal())
);

export default function QboCell({ client }) {
  const [showIntegrationError, setShowIntegrationError] = useState(false);
  const url = client.external_contact_qbo_url;
  const errorMessage = client.external_contact_error;
  const { qboIntegrationInfo } = useQboIntegrationQuery();
  const queryClient = useQueryClient();

  const renderContactErrorModal = () => {
    modalService.render(ModalComponent, { url, errorMessage });
  };

  if (url && qboIntegrationInfo.disconnect_error) {
    return (
      <div>
        <div onClick={() => setShowIntegrationError(true)} className="cp-flex-center">
          <div className="cp-color-app-error-text cp-mr-4">Error</div>
          <CpButton icon="alert-triangle-open-large" aria-label="Warning" fill="var(--cp-color-app-secondary-text)" />
        </div>
        <Suspense fallback={<></>}>
          <IntegrationSyncErrorModal
            show={showIntegrationError}
            close={() => setShowIntegrationError(false)}
            integrationInfo={qboIntegrationInfo}
            checkIntegrationAuth={() => queryClient.invalidateQueries(["qbo-integration"])}
          />
        </Suspense>
      </div>
    );
  } else if (errorMessage) {
    return (
      <div>
        <div onClick={renderContactErrorModal} className="cp-flex-center">
          <div className="cp-color-app-error-text cp-mr-4">Error</div>
          <CpButton icon="alert-triangle-open-large" aria-label="Warning" fill="var(--cp-color-app-secondary-text)" />
        </div>
      </div>
    );
  } else if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer noopener">
        Access
      </a>
    );
  } else {
    return <i>Not linked</i>;
  }
}

const ModalComponent = ({ qboUrl, message }) => {
  return (
    <>
      <CpModal.Header title="Contact Sync Error" />
      <CpModal.Body>
        <img src={cdnImage("qbo_logo_light_horizontal.png")} height="50rem" alt="QuickBooks Online Logo" />
        <div className="cp-p-8">
          {JSON.parse(message).user_message ||
            "QuickBooks Online encountered an error while syncing this contact. Please check to verify that there aren't any duplicate display names. If the issue continues, please contact support."}
        </div>
      </CpModal.Body>
      {qboUrl && (
        <CpModal.Footer>
          <CpButton btnType="primary" anchor href={qboUrl} target="_blank">
            View contact
          </CpButton>
        </CpModal.Footer>
      )}
    </>
  );
};
