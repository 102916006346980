import React from "react";
import { catchError } from "auto-trace";
import { CpDropdown, CpButton, CpTooltip } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { infoToast, successToast } from "toast-service!sofe";
import { exportFilterView } from "./client-list.resource";
import { getExportColumns } from "./export-client-list.helper";
import { downloadText } from "src/common/utils.helpers";
import useCustomFieldsQuery from "./use-custom-fields-query.hook";

export default function ExportClientListButton({
  filterView,
  filterViewOverrides,
  filters,
  sortData,
  search,
  selection,
  subView,
}) {
  const hasExportAccess = useHasAccess("contacts_export");
  const { customFields } = useCustomFieldsQuery();

  function exportCurrentList(includeSelections) {
    const { closeToast } = infoToast(
      `Exporting client list: ${subView ? subView.name : filterView.name}. This may take a moment.`
    );
    const override = filterViewOverrides[filterView.id];
    const visibleColumns = override?.visible_columns || filterView.filter_data.visible_columns;
    const columnOrder = override?.column_order || filterView.filter_data.column_order;
    exportFilterView({
      export_columns: getExportColumns({
        exportAll: false,
        visibleColumns,
        columnOrder,
        customFields,
      }),
      filters: filters,
      sort_data: sortData.filter((d) => !!d.sort),
      search: search,
      ...(includeSelections
        ? {
            [selection.type]: selection.toArray(),
          }
        : {}),
    }).subscribe((csvText) => {
      closeToast();
      downloadText(`CanopyClientsExport - ${subView ? subView.name : filterView.name}.csv`, csvText, "text/csv");
      successToast("Export complete");
    }, catchError());
  }

  if (!hasExportAccess) return null;

  return (
    <CpDropdown
      renderTrigger={({ toggle }) => (
        <CpTooltip text="Export current list">
          <CpButton
            icon="af-line-square-up"
            btnType="icon"
            aria-label="Export current list"
            onClick={selection.totalSelected > 0 ? toggle : () => exportCurrentList(false)}
          />
        </CpTooltip>
      )}
      renderContent={() => (
        <div className="cp-select-list">
          <button onClick={() => exportCurrentList(false)}>Export current list</button>
          <button onClick={() => exportCurrentList(true)}>Export selected clients</button>
        </div>
      )}
    />
  );
}
