// @flow
import * as React from "react";
import { Scoped } from "kremling";
import { uniqueId } from "lodash";
// project
import styles from "./address-section.krem.css";
import ListOfItems from "../list-of-items/list-of-items.component.js";
import Address from "./address/address.component.js";
import DeletedItem from "../list-of-items/deleted-item.component.js";
// sofe
import { CprButton } from "canopy-styleguide!sofe";

type Props = {};

export type AddressType = {
  id: string,
  is_primary: boolean,
  line_1: string,
  line_2: string,
  city: string,
  postal_code: string,
  region: string,
  type: string,
  country: string,
};

export type AddressOptionType = {
  value: string,
  key: string,
};

const addressOptions: Array<AddressOptionType> = [
  { value: "Physical address", key: "physical" },
  { value: "Mailing address", key: "mailing" },
  { value: "Work address", key: "work" },
  { value: "Other address", key: "other" },
];

export default class AddressSection extends React.Component<Props> {
  render() {
    return (
      <Scoped css={styles}>
        <div className="AddressSection">
          <ListOfItems
            initialValue={[this.getEmptyAddress()]}
            name="addresses"
            getEmptyItem={this.getEmptyAddress}
            validationFn={this.validationFn}
            maxCount={addressOptions.length}
            buttonMargin={false}
            item={({
              item,
              addRef,
              removeRef,
              removeItemById,
              showInvalid,
              setPrimaryById,
              updateItem,
              itemsLength,
              currentValue,
              index,
            }) => {
              return (
                <div
                  key={item.id}
                  className="ListItem"
                  style={{ marginBottom: index + 1 !== itemsLength ? "32px" : "0px" }}
                >
                  <Address
                    id={item.id}
                    ref={(el) => addRef(el)}
                    name={item.id}
                    address={item}
                    showInvalid={showInvalid}
                    removeAddress={removeItemById}
                    removeRef={removeRef}
                    setPrimaryById={setPrimaryById}
                    showActions={itemsLength >= 2}
                    updateAddress={updateItem}
                    options={addressOptions}
                  />
                </div>
              );
            }}
            deleted={({ item, deleteItem, cancelPendingDelete }) => {
              return (
                <DeletedItem
                  key={item.id}
                  item={item}
                  deleteItem={deleteItem}
                  cancelPendingDelete={cancelPendingDelete}
                  itemDescription={`Address`}
                />
              );
            }}
            button={({ allItems, addItem, disabledButton }) => {
              return (
                <div className="cps-margin-top-16">
                  <CprButton type="button" actionType="flat" onClick={addItem} disabled={disabledButton}>
                    + Add address
                  </CprButton>
                </div>
              );
            }}
          />
        </div>
      </Scoped>
    );
  }

  getAvailableOptions = (existingOptions: Array<AddressType>): Array<AddressOptionType> => {
    const usedOptions = existingOptions.map((item) => item.type);
    return addressOptions.filter((option: AddressOptionType) => !usedOptions.includes(option.key));
  };

  getEmptyAddress = (existingOptions: Array<AddressType> = []): AddressType => {
    const availableOptions = this.getAvailableOptions(existingOptions);
    return {
      id: uniqueId("create_address"),
      is_primary: false,
      line_1: "",
      line_2: "",
      city: "",
      postal_code: "",
      region: "",
      type: availableOptions[0].key,
      country: "United States",
    };
  };

  validationFn = (value: Array<AddressType>) => {
    return value.reduce((acc, em) => {
      // if (em.address && em.address.length !== 0) {
      //   return isValidEmail(em.address) && acc
      // }
      return true && acc;
    }, true);
  };
}
