import React, { useState } from "react";
import { catchError } from "auto-trace";
import { CpModal, CpButton, CpSelectSingle, CpCheckbox } from "canopy-styleguide!sofe";
import { infoToast, successToast } from "toast-service!sofe";
import { exportFilterView } from "./client-list.resource";
import { downloadText } from "src/common/utils.helpers";
import { getExportColumns } from "./export-client-list.helper";
import useCustomFieldsQuery from "./use-custom-fields-query.hook";

export default function ExportClientListModal(props) {
  const { show, onClose } = props;
  return (
    <CpModal show={show} onClose={onClose}>
      <ModalContent {...props} />
    </CpModal>
  );
}

function ModalContent({
  filterViews,
  filterViewOverrides,
  selection,
  currentFilters,
  currentSortData,
  currentFilterViewId,
  globalSearch,
  onClose,
  totalClientsCount,
}) {
  const currentViewOption = { id: "current-view", name: `Current Filter View (${totalClientsCount})` };
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(currentViewOption);
  const [exportAll, setExportAll] = useState(false);
  const { customFields } = useCustomFieldsQuery();

  function exportList() {
    const { closeToast } = infoToast(`Exporting client list: ${value.name}. This may take a moment.`);
    const isCurrentView = value.id === "current-view" || value.id === "current-view-with-selections";
    const filterView = isCurrentView ? filterViews.find((d) => d.id === currentFilterViewId) : value;

    const override = filterViewOverrides[filterView.id];
    const visibleColumns = override?.visible_columns || filterView.filter_data.visible_columns;
    const columnOrder = override?.column_order || filterView.filter_data.column_order;
    const filterData = isCurrentView ? currentFilters : filterView.filter_data.filters;
    const sortData = isCurrentView ? currentSortData : filterView.filter_data.sort_data;

    exportFilterView({
      filters: filterData,
      sort_data: sortData.filter((d) => !!d.sort),
      export_columns: getExportColumns({
        exportAll,
        visibleColumns,
        columnOrder,
        customFields,
      }),
      search: globalSearch,
      ...(value.id === "current-view-with-selections" ? { [selection.type]: selection.toArray() } : {}),
    }).subscribe((csvText) => {
      closeToast();
      downloadText(`CanopyClientsExport - ${value.name}.csv`, csvText, "text/csv");
      successToast("Export complete");
    }, catchError());
    onClose();
  }

  return (
    <>
      <CpModal.Header title="Export List" />
      <CpModal.Body>
        Select a list to export
        <CpSelectSingle
          autoSelectOnSearch
          data={[
            {
              id: "current-view",
              data: [
                currentViewOption,
                ...(selection.totalSelected > 0
                  ? [
                      {
                        id: "current-view-with-selections",
                        name: `Selected Clients (${selection.totalSelected})`,
                      },
                    ]
                  : []),
              ],
            },
            {
              id: "filter-views",
              data: filterViews,
            },
          ]}
          isGroupData
          groupType="border"
          searchValue={search}
          searchOnChange={setSearch}
          value={value}
          onChange={setValue}
          searchFilter={CpSelectSingle.filterAlpha(search)}
          triggerIsBlock
        />
        <div className="cp-mt-16">
          <CpCheckbox checked={exportAll} onChange={setExportAll}>
            Export all visible and hidden columns
          </CpCheckbox>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={exportList} className="cp-mr-8">
          Export
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
