// @flow
import React, { lazy, Suspense } from "react";
import AsyncDecorator from "async-decorator/rx6";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";
import { get } from "lodash";
// project
import styles from "./create-edit-contact-modal.styles.css";
import CreateEditContactForm from "src/create-edit-contact/create-edit-contact-form/create-edit-contact-form.component.js";
import { mapContactToModel, mapModelToAPI } from "src/mapping-layer/mapping-layer.helpers.js";
import { createContact, updateContact } from "src/create-edit-contact/create-edit-contact.resource.js";
// sofe
import { CpButton, CpLoader, CpModal } from "canopy-styleguide!sofe";
import { infoToast, warningToast } from "toast-service!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";
import canopyUrls from "canopy-urls!sofe";

type Props = {
  closeModal?: (any) => void,
  allowDismiss?: () => void,
  preventDismiss?: () => void,
  loading: boolean,
  contact: any,
  cancelWhenUnmounted: Function,
  isCreate: boolean,
  error?: any,
  hideBusinessChooser: boolean,
  customFields: Array<any>,
  redirectAsPrimary: boolean,
  contactsUsageData?: any,
  isContactLimitModel?: boolean,
};

type State = {
  createRequestInProgress: boolean,
  updateRequestInProgress: boolean,
};

const IntegrationSyncErrorBanner = lazy(() =>
  SystemJS.import("integrations-ui!sofe").then((module) => module.getIntegrationSyncErrorBanner())
);

@AsyncDecorator
@UserTenantProps()
export default class CreateEditContactModalContents extends React.Component<Props, State> {
  static defaultProps = {
    cancelWhenUnmounted: () => {},
    isCreate: false,
    hideBusinessChooser: false,
  };

  form: any = React.createRef();

  state = {
    createRequestInProgress: false,
    updateRequestInProgress: false,
    showIntegrationError: false,
  };

  render() {
    const { loading, error, customFields, redirectAsPrimary, qboIntegrationInfo, checkIntegrationAuth } = this.props;
    const contact_mapped_to_model = mapContactToModel(this.props.contact);
    const isEdit = contact_mapped_to_model != undefined && this.props.isCreate === false;
    let title = isEdit ? "Edit" : "Add";
    if (loading) {
      title = "Loading";
    }

    const isDisabled = this.state.createRequestInProgress || this.state.updateRequestInProgress;

    return (
      <CpModal show={true} width={736} onClose={this.closeModalClick} data-testid="add-edit-client-modal">
        <CpModal.Header title={`${title} Client`}>
          {this.props.contactsUsageData &&
            this.props.contactsUsageData.allowed &&
            this.props.contactsUsageData.allowed - this.props.contactsUsageData.used <= 20 && (
              <div className={styles.contactLimit}>
                {`${this.props.contactsUsageData.used}/${this.props.contactsUsageData.allowed} contacts`}
              </div>
            )}
        </CpModal.Header>
        <CpModal.Body className={styles.modalBody}>
          {qboIntegrationInfo?.connected &&
            qboIntegrationInfo?.disconnect_error && (
              <div className="cp-mh-36 cp-mt-16">
                <Suspense fallback={<></>}>
                  <IntegrationSyncErrorBanner
                    integrationInfo={qboIntegrationInfo}
                    checkIntegrationAuth={checkIntegrationAuth}
                  />
                </Suspense>
              </div>
            )}
          {this.getModalBody({
            loading,
            error,
            isEdit,
            contact: contact_mapped_to_model,
            customFields,
            disabled: isDisabled,
          })}
        </CpModal.Body>
        <CpModal.Footer>
          <div>
            <CpButton
              form="create_edit_contact_form"
              className="cp-mr-16"
              type="submit"
              btnType="primary"
              disabled={isDisabled}
              showLoader={isDisabled}
            >
              {getPrimaryButtonText(isEdit, redirectAsPrimary)}
            </CpButton>
            {!isEdit && redirectAsPrimary && (
              <CpButton
                type="submit"
                btnType="flat"
                onClick={isDisabled ? null : this.getModelAndCreate}
                disabled={isDisabled}
              >
                Create and close
              </CpButton>
            )}
          </div>
        </CpModal.Footer>
      </CpModal>
    );
  }

  getModelAndCreate = () => {
    this.form.current.manualSubmit().then(([valid, model]) => {
      if (valid) {
        this.createContact(model);
      }
    });
  };

  getModalBody = ({ loading, error, isEdit, contact, customFields, disabled }) => {
    if (loading || (this.props.isContactLimitModel && !this.props.contactsUsageData)) {
      return (
        <div className={"cp-m-24"}>
          <CpLoader />
        </div>
      );
    } else if (error) {
      return <div className={"cp-m-24"}>Error loading</div>;
    } else {
      let submitAction = () => ({});
      if (isEdit) {
        submitAction = this.updateContact;
      } else if (this.props.redirectAsPrimary) {
        submitAction = this.createContactAndRedirect;
      } else {
        submitAction = this.createContact;
      }

      return (
        <CreateEditContactForm
          isEdit={isEdit}
          contact={contact}
          onValidSubmit={submitAction}
          hideBusinessChooser={this.props.hideBusinessChooser}
          customFields={customFields}
          ref={this.form}
          isContactLimitModel={this.props.isContactLimitModel}
          contactsUsageData={this.props.contactsUsageData}
          closeModal={this.props.closeModal}
          disabled={disabled}
        />
      );
    }
  };

  closeModalClick = () => {
    if (this.state.updateRequestInProgress === false && this.state.updateRequestInProgress === false) {
      this.handleHideModal();
    }
  };

  handleHideModal = (contact: any) => {
    this.props.closeModal && this.props.closeModal(contact);
  };

  updateContact = (formModel: any) => {
    const apiContact = mapModelToAPI(formModel);
    const apiContactWithRemovedFields = mergeAndRemoveFields(apiContact, this.props.contact);
    this.props.preventDismiss && this.props.preventDismiss();
    this.setState({ updateRequestInProgress: true }, () => {
      const originalTags = get(this.props, "contact.relationships.tags", []);
      this.props.cancelWhenUnmounted(
        updateContact(this.props.loggedInUser, apiContactWithRemovedFields, originalTags, this.props.roles).subscribe(
          (contact) => {
            infoToast({
              message: `Contact updated successfully`,
            });
            this.setState({ updateRequestInProgress: false });
            this.props.allowDismiss && this.props.allowDismiss();
            this.handleHideModal(contact);
          },
          asyncStacktrace((err) => {
            this.props.allowDismiss && this.props.allowDismiss();
            this.setState({ updateRequestInProgress: false });
            catchSyncStacktrace(err);
          })
        )
      );
    });
  };

  createContactAndRedirect = (formModel: any) => {
    this.createContact(formModel, true);
  };

  createContact = (formModel: any, redirect: boolean = false) => {
    const globalCreatedToast = !redirect;
    const apiContact = mapModelToAPI(formModel);
    this.props.preventDismiss && this.props.preventDismiss();
    this.setState({ createRequestInProgress: true }, () => {
      this.props.cancelWhenUnmounted(
        createContact(this.props.loggedInUser, apiContact, globalCreatedToast).subscribe(
          (contact) => {
            this.setState({ createRequestInProgress: false });
            this.props.allowDismiss && this.props.allowDismiss();
            this.handleHideModal(contact);
            const id = contact.id;
            if (redirect) {
              canopyUrls.navigateToUrl(`#/client/${id}`);
            } else {
              infoToast({
                message: `Contact successfully created`,
                links: [
                  {
                    url: `${location.origin}/#/client/${id}`,
                    label: `View Contact`,
                  },
                ],
              });
            }
          },
          asyncStacktrace((err) => {
            this.props.allowDismiss && this.props.allowDismiss();
            this.setState({ createRequestInProgress: false });
            if (err?.data?.errors?.userMessage === "contact limit reached") {
              warningToast("contact limit reached");
            } else {
              catchSyncStacktrace(err);
            }
          })
        )
      );
    });
  };
}

function getPrimaryButtonText(isEdit, redirectAsPrimary) {
  if (isEdit) {
    return "Update";
  } else if (redirectAsPrimary) {
    return "Create and manage";
  } else {
    return "Create";
  }
}

function mergeAndRemoveFields(newContact, originalContact) {
  const keys = Object.keys(originalContact);
  const nullKeys = ["date_established", "client_since", "birthdate", "contact_owner_id", "business_type", "industry"];
  const stringKeys = ["tin"];
  const arrayKeys = ["addresses", "emails", "phones"];
  const cleared = keys.reduce((acc, key) => {
    if (newContact[key] === undefined) {
      if (nullKeys.includes(key)) {
        acc[key] = null;
      } else if (stringKeys.includes(key)) {
        acc[key] = "";
      } else if (arrayKeys.includes(key)) {
        acc[key] = [];
      }
    }
    return acc;
  }, {});
  return { ...newContact, ...cleared };
}
