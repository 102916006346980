// @flow
import * as React from "react";
import { Scoped } from "kremling";
// project
import { InputDecorator } from "src/forms-lib";
import styles from "./multi-select-input.krem.css";
// sofe
import { CpSelectMulti } from "canopy-styleguide!sofe";
import { FieldLabel } from "src/common/client-record-fields";

type Props = {
  value: Array<{ key: string, value: string }>,
  updateValue: (string) => void,
  options: Array<{ key: string, value: string }>,
};

export function MultiSelect(props: Props) {
  const { value, options, updateValue, ...rest } = props;

  return (
    <Scoped css={styles}>
      <div>
        <div className="select">
          {props.labelText && <FieldLabel>{props.labelText}</FieldLabel>}
          <CpSelectMulti
            triggerIsBlock
            value={value || []}
            data={options}
            transformData={(item) => ({
              id: item.key,
              name: item.value,
            })}
            onChange={updateValue}
          />
        </div>
      </div>
    </Scoped>
  );
}

export default InputDecorator(MultiSelect);
