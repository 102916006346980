// @flow
import React from "react";
import { Scoped, a } from "kremling";
import { InputDecorator } from "src/forms-lib";
import styles from "./input.krem.css";

type Props = {
  showInvalid?: boolean,
  className?: string,
  value: string,
  type: string,
  placeholder?: string,
  required?: boolean,
  id?: string,
  validationFn?: Function,
  onValidChange?: Function,
  invalidMessage?: string | Function,
  outerClass?: string,
  // new
  updateValue: Function,
  updateSharedValue?: Function,
  sharedStateObs?: Function,
  showError?: boolean,
  setFocus?: Function,
  setCustomGetValue?: Function,
  name?: string,
  valid?: boolean,
  label?: *,
  onChange?: Function,
  maxLength?: ?number,
};

type State = {
  blurred: boolean,
};

export class Input extends React.Component<Props, State> {
  input: ?HTMLInputElement;

  state = {
    blurred: false,
  };

  focusSet: boolean = false;

  componentDidMount() {
    this.setFocus();
  }

  componentDidUpdate() {
    this.setFocus();
  }

  static defaultProps = {
    showError: true,
    valid: true,
    maxLength: null,
  };

  render() {
    const {
      // component
      className,
      updateValue,
      label,
      invalidMessage,
      showError,
      outerClass,
      // formLib
      name,
      valid,
      setFocus,
      sharedStateObs,
      updateSharedValue,
      value,
      showInvalid,
      setCustomGetValue,
      validationFn,
      // other
      ...rest
    } = this.props;

    const invalidOrBlurred = this.state.blurred || showInvalid;

    return (
      <Scoped css={styles}>
        <div
          className={a("cps-form-group canopyInput")
            .m("cps-has-error", !valid && invalidOrBlurred)
            .m(`${outerClass || ""}`, !!outerClass)}
        >
          {this.label()}
          <input
            ref={(el) => (this.input = el)}
            className={this.getClassName()}
            value={value}
            maxLength={this.props.maxLength || 150}
            {...rest}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
          {showError && invalidOrBlurred && !valid && (
            <span className="cps-error-block">{this.getInvalidMessage()}</span>
          )}
        </div>
      </Scoped>
    );
  }

  label = () => {
    if (this.props.label) {
      if (typeof this.props.label === "function") {
        return this.props.label({ valid: this.props.valid });
      } else {
        return this.props.label;
      }
    }
  };

  getInvalidMessage = () => {
    if (this.props.invalidMessage) {
      if (typeof this.props.invalidMessage === "function") {
        return this.props.invalidMessage(this.props.value);
      } else {
        return this.props.invalidMessage;
      }
    } else {
      return "Error";
    }
  };

  onBlur = () => {
    this.setState({ blurred: true });
  };

  onChange = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    evt.persist();
    const value = evt.target.value;
    if (this.isWithinMaxLength(value)) {
      this.props.updateValue(value);
      if (this.props.onChange) {
        this.props.onChange(evt);
      }
    }
  };

  isWithinMaxLength = (value: string) => {
    if (this.props.maxLength) {
      return value.length <= this.props.maxLength;
    } else {
      return true;
    }
  };

  focus = () => {
    this.input && this.input.focus();
  };

  getClassName = () => {
    const { className } = this.props;
    let string = "cps-form-control";
    if (className !== undefined) {
      string += ` ${className}`;
    }
    return string;
  };

  setFocus = () => {
    if (this.props.setFocus && this.focusSet === false && this.input) {
      this.props.setFocus(() => {
        this.input && this.input.focus();
      });
      this.focusSet = true;
    }
  };
}

export default InputDecorator(Input);
