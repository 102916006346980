import React from "react";
import PropTypes from "prop-types";
import { CustomField as CustomFieldBase } from "src/common/client-record-fields";
import { useCss } from "kremling";

CustomField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CustomField(props) {
  const scope = useCss(css);
  return (
    <div className="cps-margin-bottom-16 customField" {...scope}>
      <div>
        <CustomFieldBase {...props} />
      </div>
    </div>
  );
}

const css = `
  & .customField {
    width: 48%;
  }
`;
