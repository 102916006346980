import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useVirtual } from "react-virtual";
import { throttle } from "lodash";
import { CpButton, CpTabs, CpSelectSingle, useDebounce } from "canopy-styleguide!sofe";

import styles from "./filter-view-tabs.styles.css";
import { track } from "src/resources/analytics.resource";

function isOverflowing(el) {
  return el.clientWidth < el.scrollWidth;
}

function easeInOutQuint(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

export default function FilterViewTabs({ className, filterViews, filterView, onChange }) {
  const [currentTab, setCurrentTab] = useState("active_clients");
  const [search, setSearch] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [overflowing, setOverflowing] = useState(false);

  const tabContainerRef = useRef();
  const scrollingStartTimeRef = useRef();

  const trackScroll = useDebounce(
    (direction) => {
      track("client_list.filter-view-tab-scroll", { direction });
    },
    500,
    {},
    []
  );

  // Smooth scrolling function provided by react-virtual docs
  // https://react-virtual-v2.tanstack.com/examples/smooth-scroll
  const scrollToFn = useCallback((offset, defaultScrollTo) => {
    if (!tabContainerRef.current) return;
    const duration = 100;
    const start = tabContainerRef.current.scrollLeft;
    const startTime = Date.now();
    scrollingStartTimeRef.current = startTime;

    const run = () => {
      if (scrollingStartTimeRef.current !== startTime) return;
      const elapsed = Date.now() - startTime;
      const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
      const interpolated = start + (offset - start) * progress;

      if (elapsed < duration) {
        defaultScrollTo(interpolated);
        requestAnimationFrame(run);
      } else {
        defaultScrollTo(interpolated);
      }
    };

    requestAnimationFrame(run);
  }, []);

  // Joining all filter views names to be used in the virtualizer, so we recalc size only when order or name changes
  const filterViewNameKey = useMemo(() => filterViews.map((f) => f.name).join(), [filterViews]);
  const tabVirtualizer = useVirtual({
    parentRef: tabContainerRef,
    size: filterViews.length,
    estimateSize: useCallback(() => 88, [filterViewNameKey]), // eslint-disable-line
    overscan: 5,
    horizontal: true,
    scrollToFn,
  });

  useEffect(() => {
    if (!filterView) return;
    setSelectValue(filterView);
    setFilterView(filterView.id);
    setCurrentTab(filterView.id);
    requestAnimationFrame(() => {
      tabVirtualizer.scrollToIndex(filterViews.findIndex((view) => view.id === filterView.id));

      // So this is a hacky fix for a weird scrolling issue that I believe is related to dynamic widths of tabs.
      // Normally we estimate a size for all tabs and then as we load more react-virtual will measure the item and cache it.
      // However react-virtual has no way of knowing the sizes of the outliers until they're rendered.
      // So if we tell it to scroll to a far item I'm assuming it can only use the estimate size and if you have a long tab it can result in an incomplete scroll.
      // Thus the hacky fix is to wait after the first scroll and let the overscan measure the next set of items and then try to scroll again.
      setTimeout(() => {
        tabVirtualizer.scrollToIndex(filterViews.findIndex((view) => view.id === filterView.id));
      }, 100);
    });
  }, [filterView, filterViews]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  // Check overflow when window resizes
  useEffect(() => {
    const onResize = throttle(() => {
      setOverflowing(isOverflowing(tabContainerRef.current));
    }, 100);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  // Check overflow after tabs have initialized or if the virtualizer has changed
  useEffect(() => {
    if (tabContainerRef.current) {
      requestAnimationFrame(() => {
        setOverflowing(isOverflowing(tabContainerRef.current));
      });
    }
  }, [tabVirtualizer.virtualItems.length]);

  function setFilterView(viewId) {
    onChange(filterViews.find((v) => v.id === viewId));
  }

  return (
    <div className={`${className || ""} cp-flex-center ${overflowing ? "" : "cp-ml-16"}`}>
      {overflowing && (
        <CpButton
          btnType="icon"
          icon="caret-large-left"
          aria-label="Scroll left"
          className={styles.flexNoShrink}
          disabled={tabContainerRef.current?.scrollLeft <= 0}
          onClick={() => {
            const scrollLeft = tabContainerRef.current.scrollLeft;
            const nearest = tabVirtualizer.virtualItems.reverse().find((item) => item.start < scrollLeft);
            if (nearest) {
              tabVirtualizer.scrollToOffset(nearest.start);
            }
            trackScroll("left");
          }}
        />
      )}
      <CpTabs className={`${styles.cptabs}`} ref={tabContainerRef}>
        </* Fragment needed to prevent CpTabs from passing its props to the div */>
          <div className={styles.innerList} style={{ width: tabVirtualizer.totalSize }}>
            {tabVirtualizer.virtualItems.map((virtualRow) => {
              const filterView = filterViews[virtualRow.index];
              return (
                <div
                  key={virtualRow.key + filterView.id}
                  ref={virtualRow.measureRef}
                  className={styles.virtualContainer}
                  style={{
                    transform: `translateX(${virtualRow.start}px)`,
                  }}
                >
                  <CpTabs.Button
                    id={filterView.id}
                    activeId={currentTab}
                    onChange={(tab) => {
                      setSelectValue(null);
                      setCurrentTab(tab);
                      setFilterView(tab);
                      track("client_list.filter-view-clicked", {
                        filterViewId: tab,
                        filterViewName: filterView.name,
                        source: "tab_button",
                      });
                    }}
                  >
                    {filterView.name}
                  </CpTabs.Button>
                </div>
              );
            })}
          </div>
        </>
      </CpTabs>
      {overflowing && (
        <>
          <CpButton
            btnType="icon"
            icon="caret-large-right"
            aria-label="Scroll right"
            className={styles.flexNoShrink}
            disabled={
              tabContainerRef.current?.scrollLeft >=
              tabContainerRef.current?.scrollWidth - tabContainerRef.current?.clientWidth
            }
            onClick={() => {
              const scrollLeft = tabContainerRef.current.scrollLeft;
              const nearest = tabVirtualizer.virtualItems.find((item) => item.start > scrollLeft);
              if (nearest) {
                tabVirtualizer.scrollToOffset(nearest.start);
              }
              trackScroll("right");
            }}
          />
          <CpSelectSingle
            autoSelectOnSearch
            data={filterViews}
            onChange={(value) => {
              setSelectValue(value);
              setFilterView(value.id);
              track("client_list.filter-view-clicked", {
                filterViewId: value.id,
                filterViewName: value.name,
                source: "dropdown",
              });
            }}
            searchFilter={filterViews.length > 7}
            searchOnChange={setSearch}
            searchValue={search}
            value={selectValue}
            renderTrigger={({ toggle }) => (
              <CpButton
                btnType="icon"
                icon="caret-small-down"
                aria-label="Show filter views"
                className={styles.flexNoShrink}
                onClick={toggle}
              />
            )}
          />
        </>
      )}
    </div>
  );
}
