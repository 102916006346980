import { useState, useEffect } from "react";
import createFocusTrap from "focus-trap";

const DEFAULT_OPTIONS = {
  escapeDeactivates: false,
};

export default function useFocusTrap(ref, options = {}, activationTryCountLimit = 500) {
  const [focusTrap, setFocusTrap] = useState(null);
  const [activationTryCount, setActivationTryCount] = useState(0);
  const [isActivated, setIsActivated] = useState(false);
  const [isManuallyPaused, setIsManuallyPaused] = useState(false);

  useTrap();

  return {
    isActivated,
    isManuallyPaused,
    manuallyPause,
    manuallyUnpause,
  };

  function useTrap() {
    useEffect(() => {
      if (ref.current) {
        setFocusTrap(createFocusTrap(ref.current, { ...DEFAULT_OPTIONS, ...options }));
      }
    }, []);

    useEffect(() => {
      if (focusTrap) {
        try {
          focusTrap.activate();
          setIsActivated(true);
        } catch (err) {
          if (activationTryCount < activationTryCountLimit) {
            setActivationTryCount((prevCount) => prevCount + 1);
          } else {
            throw err;
          }
        }
      }

      return () => {
        if (focusTrap) {
          focusTrap.deactivate();
          setIsActivated(false);
        }
      };
    }, [focusTrap, activationTryCount]); // eslint-disable-line
    // lint-TODO: Unnecessary deps, need to test to make sure that removing them doesn't break anything
  }

  function manuallyPause() {
    if (focusTrap && isActivated) {
      focusTrap.pause();
      setIsManuallyPaused(true);
    }
  }

  function manuallyUnpause() {
    if (focusTrap && isActivated) {
      focusTrap.unpause();
      setIsManuallyPaused(false);
    }
  }
}
