import React, { useState, useEffect } from "react";
import { catchError } from "auto-trace";
import { useQueryClient } from "src/react-query";
import { CpModal, CpButton, CpSelectSingle } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";

import { patchClients } from "src/client-list/client-list.resource.js";
import useTeamMembersQuery from "src/client-list/use-team-members-query.hook";
import useClientQuery from "./use-client-query.hook";

// This is similar to the bulk modal, but this is designed to apply to only one client and offer a slightly better UX by populating info that we have.
export default function AssignContactOwnerModal({ onClose, clientId }) {
  const queryClient = useQueryClient();
  const [show, setShow] = useState(true);
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [teamMembersSearch, setTeamMembersSearch] = useState("");
  const [search, setSearch] = useState("");

  const { teamMembers } = useTeamMembersQuery();
  const { client } = useClientQuery(clientId);

  useEffect(() => {
    if (!client?.contact_owner_id || !teamMembers) return;
    const teamMember = teamMembers.find((tm) => tm.id === client.contact_owner_id);
    // Unfortunately we have to find the name from the team members list, contact_owner doesn't get returned even with include=contact_owner
    setSelectedTeamMember({ id: client.contact_owner_id, name: teamMember?.name });
  }, [client, teamMembers]);

  function assignContactOwner() {
    patchClients({
      action: "assign_contact_owner",
      contact_owner_id: selectedTeamMember?.id || "",
      includes: [clientId],
    }).subscribe(() => {
      if (selectedTeamMember) {
        successToast(`${selectedTeamMember.name} has been assigned as contact owner to ${client.name}.`);
      } else {
        successToast(`Contact owner has been unassigned from ${client.name}.`);
      }
      queryClient.invalidateQueries(["clients"]);
      queryClient.invalidateQueries(["client"]);
    }, catchError());
    close();
  }

  function close() {
    setShow(false);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose}>
      <CpModal.Header title="Assign Contact Owner" />
      <CpModal.Body>
        <div>Add contact owner</div>
        <CpSelectSingle
          clearable
          data={teamMembers}
          onChange={setSelectedTeamMember}
          placeholder="Search Team Members"
          searchFilter
          searchOnChange={setSearch}
          searchValue={search}
          teamMember
          triggerIsBlock
          value={selectedTeamMember}
          transformData={(tm) => ({
            ...tm,
            name: tm.profile_name || tm.name,
            subName: tm.name,
            namechip: {
              name: tm.name,
              imgSrc: tm.profile_picture_url,
            },
          })}
        />
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={assignContactOwner} className="cp-mr-8">
          Apply
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
