// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-table-headers-column-header-styles__clientNameCell {\n  background: var(--cp-color-well-l1-bg);\n  position: -webkit-sticky;\n  position: sticky;\n  left: 4rem;\n  z-index: 2;\n}\n\n.src-client-list-table-headers-column-header-styles__nonStickyColumn {\n  position: relative;\n}\n\n.src-client-list-table-headers-column-header-styles__resizeHandle {\n  position: absolute;\n  top: 0;\n  right: 0;\n  cursor: col-resize;\n  width: 1.6rem;\n  height: 100%;\n}\n\n.src-client-list-table-headers-column-header-styles__filtersApplied {\n  background-color: var(--cp-color-app-active-highlight-bg);\n}\n\n.src-client-list-table-headers-column-header-styles__columnHeader {\n  cursor: grab;\n}\n", ""]);
// Exports
exports.locals = {
	"clientNameCell": "src-client-list-table-headers-column-header-styles__clientNameCell",
	"nonStickyColumn": "src-client-list-table-headers-column-header-styles__nonStickyColumn",
	"resizeHandle": "src-client-list-table-headers-column-header-styles__resizeHandle",
	"filtersApplied": "src-client-list-table-headers-column-header-styles__filtersApplied",
	"columnHeader": "src-client-list-table-headers-column-header-styles__columnHeader"
};
module.exports = exports;
