import * as React from "react";
import { useSharedValue } from "src/forms-lib";
import PersonalInformationSection from "./personal-information-section/personal-information-section.component";
import BusinessInformationSection from "./business-information-section/business-information-section.component";

export default function DetailedInformationSection(props) {
  const isBusiness = useSharedValue("is_business");

  return <div>{isBusiness ? <BusinessInformationSection /> : <PersonalInformationSection />}</div>;
}
