import React from "react";
import ListCell from "./list-cell.component";

export default function RoleCell({ column, assignmentsByRoleId }) {
  const assignments = assignmentsByRoleId[column.roleId];

  if (assignments?.users?.length > 0 || assignments?.teams?.length > 0) {
    return <ListCell value={[...assignments.users.map((u) => u.name), ...assignments.teams.map((t) => t.name)]} />;
  } else {
    return "—";
  }
}
