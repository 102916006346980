import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useQueryClient } from "src/react-query";
import { a } from "kremling";
import styles from "./edit-filter-views-body.styles.css";
import { CpButton, CpDropdown, CpIcon, CpTooltip, CpWell } from "canopy-styleguide!sofe";
import { catchError } from "auto-trace";
import { actions } from "./edit-filter-views-body.helper";
import { patchFilterViewOrder } from "../../client-list.resource";
import useTeamMembersQuery from "src/client-list/use-team-members-query.hook";

export default function EditFilterViewsBody({
  filterViews: initialFilterViews,
  filterViewsToDelete,
  setFilterViewsToDelete,
}) {
  const [filterViews, setFilterViews] = useState(initialFilterViews);
  const queryClient = useQueryClient();

  useEffect(() => {
    setFilterViews(initialFilterViews);
  }, [initialFilterViews]);

  function handleReorder(result) {
    const { destination, source } = result;
    if (!source || !destination) return;
    const items = [...filterViews];
    const [movedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, movedItem);
    setFilterViews(items);
    const orderedViewIds = items.map((item) => item.id);
    patchFilterViewOrder(orderedViewIds).subscribe(() => {
      queryClient.invalidateQueries(["filter-views"]);
    }, catchError());
  }

  return (
    <DragDropContext onDragEnd={handleReorder}>
      <Droppable droppableId="filter-view-droppable-area">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {filterViews.map((fv, i) => {
              const isDefault = fv.created_by === "__DEFAULT";
              const { hidden: isHidden, read_only: readOnly } = fv;
              const isMarkedForDelete = filterViewsToDelete.includes(fv.id);
              const filteredActions = actions.filter((a) => {
                switch (a.id) {
                  case "rename":
                  case "share":
                    return !isDefault && !readOnly;
                  case "delete":
                    return !isDefault && !readOnly && !isMarkedForDelete;
                  case "undoDelete":
                    return !isDefault && !readOnly && isMarkedForDelete;
                  case "hide":
                    return !isHidden && !isDefault;
                  case "show":
                    return isHidden && !isDefault;
                  case "duplicate":
                  default:
                    return true;
                }
              });
              return (
                <Draggable key={fv.id} draggableId={fv.id} index={i}>
                  {(dragProvider) => (
                    <div ref={dragProvider.innerRef} {...dragProvider.draggableProps} {...dragProvider.dragHandleProps}>
                      <FilterViewCard
                        isDefault={isDefault}
                        readOnly={readOnly}
                        isMarkedForDelete={isMarkedForDelete}
                        filterView={fv}
                        setFilterViewsToDelete={setFilterViewsToDelete}
                        actions={filteredActions}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const FilterViewCard = ({ actions, filterView, isDefault, readOnly, setFilterViewsToDelete, isMarkedForDelete }) => {
  const [currentSubView, setCurrentSubView] = useState(null);
  const queryClient = useQueryClient();
  const isHidden = filterView.hidden;

  function closeSubView(closeMenu) {
    closeMenu?.();
    setCurrentSubView(null);
  }

  function showSubView(actionId, subViewProps) {
    setCurrentSubView({ actionId, subViewProps });
  }

  function markFilterViewForDelete(viewId) {
    setFilterViewsToDelete((prev) => {
      return prev.includes(viewId) ? prev : [...prev, viewId];
    });
  }

  function unmarkFilterViewForDelete(viewId) {
    setFilterViewsToDelete((prev) => prev.filter((item) => item !== viewId));
  }

  function executeAction(action, closeMenu) {
    if (!action.execute) return;
    const executeArgs = {
      filterView,
      queryClient,
      onClose: () => closeSubView(closeMenu),
      markFilterViewForDelete,
      unmarkFilterViewForDelete,
    };
    executeArgs.showSubView = (actionId) => showSubView(actionId, { ...executeArgs });
    action.execute(executeArgs);
  }

  const { teamMembers } = useTeamMembersQuery(false);
  const sharedBy = readOnly ? teamMembers?.find((tm) => tm.id === filterView.created_by) : null;
  let numSharedUsers = 0;
  let numSharedTeams = 0;
  filterView.sharing_data?.forEach((el) => (el.type === "user" ? numSharedUsers++ : numSharedTeams++));

  function getSharingSubtext() {
    if (sharedBy) {
      return `Shared filter from ${sharedBy.profile_name || sharedBy.name}`;
    } else if (numSharedUsers > 0 && numSharedTeams > 0) {
      return `Sharing with ${numSharedUsers} team member${numSharedUsers > 1 ? "s" : ""} and ${numSharedTeams} team${
        numSharedTeams > 1 ? "s" : ""
      }`;
    } else if (numSharedUsers > 0) {
      return `Sharing with ${numSharedUsers} team member${numSharedUsers > 1 ? "s" : ""}`;
    } else if (numSharedTeams) {
      return `Sharing with ${numSharedTeams} team${numSharedTeams > 1 ? "s" : ""}`;
    }
  }

  return (
    <div className={styles.filterViewContainer}>
      <CpIcon className={styles.handle} name="misc-drag-handle" />
      <CpWell className={styles.well} level={1}>
        <div className={styles.filterViewText}>
          <div className={a("cp-ellipsis cp-body cp-mr-8").m("cp-color-app-inactive-text", isHidden)}>
            {filterView.name}{" "}
            {isDefault && (
              <span className="cp-caption">
                <i>Cannot be deleted or renamed</i>
              </span>
            )}
          </div>
          {isMarkedForDelete ? (
            <div className="cp-body-caption cp-color-app-warning-text">
              <i>This filter view will be deleted upon closing this window</i>
            </div>
          ) : (
            !isDefault && (
              <div
                className={a("cp-caption").t(
                  "cp-color-app-callout-disabled-text",
                  "cp-color-app-callout-text",
                  isHidden
                )}
              >
                <i>{getSharingSubtext()}</i>
              </div>
            )
          )}
        </div>
        <div>
          {isHidden && (
            <CpTooltip text="Show filter view">
              <CpButton
                className="cp-mr-8"
                icon="misc-closed-eye"
                aria-label="Show filter view"
                onClick={() => executeAction(actions.find((a) => a.id === "show"))}
              />
            </CpTooltip>
          )}
          <CpDropdown
            allowContentClicks
            appendTo="parent"
            contentWidth={currentSubView ? 330 : "sm"}
            position="bottom-end"
            renderWhenClosed={false}
            renderTrigger={({ toggle }) => (
              <CpButton aria-label="filter view menu" icon="misc-kabob" onClick={toggle} />
            )}
            renderContent={({ close }) => (
              <>
                {!currentSubView ? (
                  <div className="cp-select-list">
                    {actions.map((action) => (
                      <button key={action.id} onClick={() => executeAction(action, close)}>
                        <CpIcon
                          className="cp-mr-8 cp-select-list__icon_left"
                          name={action.icon}
                          fill="var(--cp-color-app-icon)"
                        />
                        {action.label}
                      </button>
                    ))}
                  </div>
                ) : (
                  actions
                    .find((a) => a.id === currentSubView.actionId)
                    ?.renderView({ ...currentSubView.subViewProps, closeSubView: () => closeSubView(close) })
                )}
              </>
            )}
          />
        </div>
      </CpWell>
    </div>
  );
};
