// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "td.src-client-list-table-table-body-styles__selectCell {\n  background: var(--cp-color-well-l1-bg);\n  position: -webkit-sticky;\n  position: sticky;\n  left: 0;\n  padding: 0;\n  padding-left: 1.6rem;\n  border-right: none;\n}\n\n.src-client-list-table-table-body-styles__clientNameCell {\n  background: var(--cp-color-well-l1-bg);\n  position: -webkit-sticky;\n  position: sticky;\n  left: 4rem;\n}\n\n.src-client-list-table-table-body-styles__emptyStateCell {\n  position: absolute;\n  left: 50%;\n  top: 25%;\n}\n\n.src-client-list-table-table-body-styles__emptyStateBody {\n  height: 100%;\n}\n\n.src-client-list-table-table-body-styles__emptyStateBody td {\n  border: none;\n}\n\n.src-client-list-table-table-body-styles__emptyStateBody tr:hover td {\n  background-color: unset;\n}\n\ntr.src-client-list-table-table-body-styles__hiddenRow {\n  border: none;\n  height: 0;\n}\n\ntd.src-client-list-table-table-body-styles__hiddenCell {\n  padding: 0;\n  border: none;\n}\n\n.src-client-list-table-table-body-styles__contextMenuHighlight td {\n  transition: background-color 0.1s;\n  background-color: var(--cp-color-notification-banner-bg) !important;\n}\n", ""]);
// Exports
exports.locals = {
	"selectCell": "src-client-list-table-table-body-styles__selectCell",
	"clientNameCell": "src-client-list-table-table-body-styles__clientNameCell",
	"emptyStateCell": "src-client-list-table-table-body-styles__emptyStateCell",
	"emptyStateBody": "src-client-list-table-table-body-styles__emptyStateBody",
	"hiddenRow": "src-client-list-table-table-body-styles__hiddenRow",
	"hiddenCell": "src-client-list-table-table-body-styles__hiddenCell",
	"contextMenuHighlight": "src-client-list-table-table-body-styles__contextMenuHighlight"
};
module.exports = exports;
