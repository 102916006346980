// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dedupe-modal-page-title-bar-page-title-bar-styles__pageTitleBar {\n  width: 100%;\n  height: 112px;\n  background: #39f;\n  text-align: center;\n  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.18);\n}\n\n.src-dedupe-modal-page-title-bar-page-title-bar-styles__titleAndSubTitle {\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 112px;\n}\n\n.src-dedupe-modal-page-title-bar-page-title-bar-styles__toolsArea {\n  position: absolute;\n  width: 128px;\n  height: 112px;\n  padding: 20px;\n  top: 0;\n  right: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: space-between;\n}\n\n.src-dedupe-modal-page-title-bar-page-title-bar-styles__importButton {\n  color: #39f;\n}\n\n.src-dedupe-modal-page-title-bar-page-title-bar-styles__slideRight {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"pageTitleBar": "src-dedupe-modal-page-title-bar-page-title-bar-styles__pageTitleBar",
	"titleAndSubTitle": "src-dedupe-modal-page-title-bar-page-title-bar-styles__titleAndSubTitle",
	"toolsArea": "src-dedupe-modal-page-title-bar-page-title-bar-styles__toolsArea",
	"importButton": "src-dedupe-modal-page-title-bar-page-title-bar-styles__importButton",
	"slideRight": "src-dedupe-modal-page-title-bar-page-title-bar-styles__slideRight"
};
module.exports = exports;
