import React, { useState } from "react";
import { useQueryClient } from "src/react-query";
import { catchError } from "auto-trace";
import { CpModal, CpButton } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { patchClients } from "src/client-list/client-list.resource.js";

export default function BulkMakeInactiveModal({ onClose, filters, selection, search }) {
  const queryClient = useQueryClient();
  const [show, setShow] = useState(true);

  function onMakeInactive() {
    close();
    patchClients({
      search,
      filters,
      action: "deactivate",
      [selection.type]: selection.toArray(),
    }).subscribe(() => {
      const count = selection.totalSelected;
      successToast(`${count} client${count > 1 ? "s" : ""} will be marked ‘Inactive’.`);
      selection.deselectAll();
      // Small delay before invalidating in case bulk job hasn't finished
      setTimeout(() => {
        queryClient.invalidateQueries(["clients"]);
      }, 1000);
    }, catchError());
  }

  function close() {
    setShow(false);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose}>
      <CpModal.Header title="Make Inactive" />
      <CpModal.Body>
        <div>
          Please note. If there are clients with balances owing on their accounts <b>they cannot be made inactive</b>{" "}
          until their balance is zeroed out.
        </div>
        <div className="cp-mt-12">By continuing, all accounts with a zero balance will be moved to inactive.</div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={onMakeInactive} className="cp-mr-8">
          Continue
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
