import React from "react";
import { CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import styles from "./list-cell.styles.css";

export default function ListCell({ value: list, isUserList }) {
  const numItemsHidden = list?.length - 1;

  if (list?.length > 0 && list?.length <= 2) {
    return list.join(", ");
  } else if (list?.length > 2) {
    return (
      <>
        {list[0]}
        <CpDropdown
          contentWidth="auto"
          allowContentClicks
          appendTo="parent"
          renderWhenClosed={false}
          renderTrigger={({ toggle }) => (
            <button className={`${styles.moreButton} cp-body-sm`} onClick={toggle}>
              {`+${numItemsHidden} more`}
            </button>
          )}
          renderContent={({ close }) => (
            <div className={styles.dropdownContent}>
              {list.map((item, i) => {
                if (i <= 0) return null;
                return (
                  <div key={i} className="cp-p-8 cp-flex-center">
                    {isUserList && <CpIcon name="person" className="cp-mr-8" />}
                    <div>{item}</div>
                  </div>
                );
              })}
            </div>
          )}
        />
      </>
    );
  } else {
    return "—";
  }
}
