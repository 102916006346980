import { mapIdsToItems } from "../common/utils.helpers";

// Convert template data from the API to form data
export function rolesConvertTemplateApiToForm(templateData, users, teams) {
  const mappedUsers = mapIdsToItems(templateData.roles?.users, users);
  const mappedTeams = mapIdsToItems(templateData.roles?.teams, teams);
  const formUsersTeams = [...mappedUsers, ...mappedTeams];

  const formRoles = {};
  templateData.roles?.role_assignments?.forEach((r) => {
    const mappedRoleUsers = mapIdsToItems(r.users, users);
    const mappedRoleTeams = mapIdsToItems(r.teams, teams);
    formRoles[r.role_id] = [...mappedRoleUsers, ...mappedRoleTeams];
  });

  return {
    roles: formRoles,
    usersTeams: formUsersTeams,
  };
}
