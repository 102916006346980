// @flow
import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./business-information-section.krem.css";
import DateInput from "src/form/date-input/date-input.component.js";
import MaskedInput from "src/form/masked-input/masked-input.component.js";
import BusinessType from "./business-type/business-type.component.js";
import Industry from "./industry/industry.component.js";

type Props = {};

export default function BusinessInformationSection(props: Props) {
  return (
    <Scoped css={styles}>
      <div className="BusinessInformationSection">
        <div className="BusinessInformationRow margin-between">
          <DateInput
            name="date_established"
            label={
              <div className="cps-margin-bottom-4">
                <label>
                  <span>Date est./inc.</span>
                </label>
              </div>
            }
          />
          <div>
            <div className="cps-margin-bottom-4">
              <label>EIN</label>
            </div>
            <MaskedInput
              name="ein"
              inputProps={{
                autoComplete: "off",
                htmlFor: `EIN`,
                name: `Business EIN`,
              }}
              nonValidMsg="EIN should be 9 numberic characters"
              validationFn={validateEIN}
              filler="#"
              masks={[
                {
                  condition: (value) => value.length <= 9,
                  pattern: "11-1111111",
                },
                {
                  condition: (value) => value.length > 9,
                  pattern: "111111111",
                },
              ]}
            />
          </div>
        </div>
        <div className="BusinessInformationRow margin-between">
          <BusinessType />
          <Industry />
        </div>
      </div>
    </Scoped>
  );
}

function validateEIN(value) {
  return value.length === 9 || value === "";
}
