import React, { useState } from "react";
import { CpButton } from "canopy-styleguide!sofe";
import CreateEditContactModal from "src/create-edit-contact/create-edit-contact-modal/create-edit-contact-modal.component";
import { useQueryClient } from "src/react-query";

export default function AddClientButton({ className = "" }) {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <CpButton className={className} onClick={() => setShowModal(true)}>
        Add client
      </CpButton>
      {showModal && (
        // TODO: update this modal. afaik we don't yet have designs for this so using old modal for now
        <CreateEditContactModal
          onModalHide={() => {
            queryClient.invalidateQueries(["clients"]);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}
