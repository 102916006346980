// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dedupe-modal-resolved-contact-resolved-contact-table-styles__resolvedTable {\n  border-collapse: collapse;\n  width: 100%;\n  border: thin solid lightgray;\n  white-space: nowrap;\n}\n\n.src-dedupe-modal-resolved-contact-resolved-contact-table-styles__tableSpacing {\n  margin: 16px 0 24px 0;\n  white-space: nowrap;\n}\n\n.src-dedupe-modal-resolved-contact-resolved-contact-table-styles__borderBottom {\n  border-bottom: thin solid lightgray;\n}\n\n.src-dedupe-modal-resolved-contact-resolved-contact-table-styles__tableHead {\n  height: 32px;\n  border-bottom: thin solid lightgray;\n}\n.src-dedupe-modal-resolved-contact-resolved-contact-table-styles__tableBody {\n  height: 72px;\n  border-bottom: thin solid lightgray;\n}\n", ""]);
// Exports
exports.locals = {
	"resolvedTable": "src-dedupe-modal-resolved-contact-resolved-contact-table-styles__resolvedTable",
	"tableSpacing": "src-dedupe-modal-resolved-contact-resolved-contact-table-styles__tableSpacing",
	"borderBottom": "src-dedupe-modal-resolved-contact-resolved-contact-table-styles__borderBottom",
	"tableHead": "src-dedupe-modal-resolved-contact-resolved-contact-table-styles__tableHead",
	"tableBody": "src-dedupe-modal-resolved-contact-resolved-contact-table-styles__tableBody"
};
module.exports = exports;
