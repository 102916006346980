// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicate {\n  margin: 0px 16px 0px 16px;\n}\n\n.src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicateSet {\n  background: #eaebef;\n  padding: 1px 12px;\n  border-radius: 8px;\n  margin: 16px 4px 0 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"duplicate": "src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicate",
	"duplicateSet": "src-dedupe-modal-merge-card-group-merge-card-group-styles__duplicateSet"
};
module.exports = exports;
