import React from "react";
import ContactCardLoader from "./contact-card-loader.component";
import { useFetcher } from "fetcher!sofe";
import { CprEmptyState, CpButton, CpEmptyState } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { useCss, m } from "kremling";
import { DateTime } from "luxon";

export default function ResolutionCasesList(props) {
  const { loading, results, error, refetch } = useFetcher(
    featureEnabled("toggle_engagements_rename")
      ? `/api/clients/${props.contactId}/resolution_cases`
      : `/api/contacts/${props.contactId}/engagements`,
    {
      pluck: featureEnabled("toggle_engagements_rename") ? "resolution_cases" : "engagements",
    }
  );

  const resolutionCases = results?.slice(0, 6);

  const emptyState = () => {
    return featureEnabled("toggle_engagements_rename") ? (
      <CpEmptyState
        className="cp-mt-32"
        img="es_engagements_list"
        text="No active resolution cases"
        subText="A resolution case allows you to bundle and invoice your work."
        cta={
          <CpButton btnType="secondary" onClick={props.buttonClick}>
            Create resolution case
          </CpButton>
        }
      />
    ) : (
      <CprEmptyState
        iconSrc="es_engagements_list"
        headText="No active engagements"
        subheadText="An engagement allows you to bundle and invoice your work."
        showCta={true}
        ctaClickHandler={props.buttonClick}
        ctaButtonText="Create engagement"
        ctaButtonType="secondary"
      />
    );
  };

  return (
    <div
      {...useCss(styles)}
      className={`${m("full", resolutionCases?.length === 6).m("empty", resolutionCases?.length === 0)}`}
    >
      {loading ? (
        <ContactCardLoader />
      ) : !resolutionCases?.length ? (
        emptyState()
      ) : (
        resolutionCases.map((eng) => (
          <div
            key={eng.id}
            className="row"
            onClick={() =>
              props.history.push(
                `/taxes/client/${props.contactId}/${
                  featureEnabled("toggle_engagements_rename") ? "resolution-cases" : "engagements"
                }/${eng.id}/summary`
              )
            }
          >
            <div className="cps-ellipsis">{eng.name}</div>
            <div className="cps-color-cool-gray cps-ellipsis">
              Updated {DateTime.fromISO(eng.updated_at).toFormat("ff")}&nbsp;
            </div>
          </div>
        ))
      )}
    </div>
  );
}

const styles = `
& .row {
  flex-direction: column;
  justify-content: center;
  height: 70.1px;
}
`;
