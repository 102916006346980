import { pluck, map } from "rxjs/operators";
import { sortBy } from "lodash";

import { fetchAsObservable } from "fetcher!sofe";

export function getClients({ page, limit, filters, sort_data, search, visibleColumns }) {
  return fetchAsObservable(`api/v2/clients/search`, {
    method: "POST",
    body: {
      page,
      limit,
      filters,
      sort_data,
      search,
      visible_columns: visibleColumns,
    },
  });
}

export function getCustomFields() {
  return fetchAsObservable("/api/custom_fields").pipe(
    pluck("custom_fields"),
    map((fields) => {
      return sortBy(fields, (field) => field.field_name.toLowerCase());
    })
  );
}

export function getFilterViews() {
  return fetchAsObservable(`/api/v2/clients/filters`);
}

export function createFilterView(body) {
  return fetchAsObservable(`/api/v2/clients/filters`, {
    method: "POST",
    body: body,
  });
}

export function patchFilterView(viewId, body) {
  return fetchAsObservable(`/api/v2/clients/filters/${viewId}`, {
    method: "PATCH",
    body: body,
  });
}

export function patchFilterViewOrder(orderedViewIds) {
  return fetchAsObservable("/api/v2/clients/filters/filter-reorder", {
    method: "PATCH",
    body: {
      ids: orderedViewIds,
    },
  });
}

export function deleteFilterViews(viewIds) {
  return fetchAsObservable(`/api/v2/clients/filters`, {
    method: "DELETE",
    body: {
      ids: viewIds,
    },
  });
}

// body.filter_data - Filter data used in filter view
// body.includes - Selection of user ids to include
// body.excludes - Selection of users ids to exclude
export function translateFilterDataToJql(body) {
  return fetchAsObservable(`/api/v2/clients/translate`, {
    method: "POST",
    body: body,
  });
}

/*
body: {
  filters,
  sort_data,
  search,
  excludes,
  includes
}
*/
export function exportFilterView(body) {
  return fetchAsObservable("/api/v2/clients/filters/export", {
    method: "POST",
    body,
  });
}

// https://code.canopy.dev/python/crm-service/-/wikis/CRM-Technical-v2022#bulk-actions
export function patchClients(body) {
  return fetchAsObservable("/api/v2/clients", {
    method: "PATCH",
    body,
  });
}

export function getTags() {
  return fetchAsObservable(`/api/tags`).pipe(pluck("tags"));
}

export function createTag(name) {
  return fetchAsObservable(`/api/tags`, {
    method: "POST",
    body: {
      tags: [
        {
          name: name,
        },
      ],
    },
  });
}

// userIds - The IDs of all the resulting users that should be set on the client
// sendNotificationsTo - The IDs of NEWLY added users. These users will get a notification
// The notification verbiage was taken from client-menu
export function assignClientTeamMembers({ clientId, userIds, sendNotificationsTo }) {
  return fetchAsObservable(`/api/contacts/${clientId}/team-members`, {
    method: "PUT",
    body: {
      team_members: userIds,
      ...(!!sendNotificationsTo?.length
        ? {
            notifications: {
              users: sendNotificationsTo,
              messages: {
                team_message:
                  "Hi there. We’ll be using Canopy to share ideas, gather feedback, and track progress for this contact.",
              },
            },
          }
        : {}),
    },
  });
}
