import React, { useCallback, useContext, useMemo } from "react";
import { CpDateRangeSelector } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { FilterContext } from "../../client-list.component";
import { getColumn } from "./date-filter.helper";

const defaultDateFormat = "yyyy-MM-dd";

export default function DateFilter({ column: columnDef, onClose }) {
  const { filterField, sortField, hideBlanksOption } = columnDef;
  const { getFilter, setFilter, getSort, setSort } = useContext(FilterContext);
  const prevFilter = getFilter(filterField)?.[0];
  const prevSortDir = getSort(sortField)?.sort;

  const column = useMemo(() => getColumn(filterField, hideBlanksOption), [filterField, hideBlanksOption]);
  const outputDateFormat = columnDef.dateFormat || defaultDateFormat; // The format that we send to the BE

  const onApply = useCallback(
    (sortParam, dates) => {
      setSort({ field: sortField, sort: dates.order });
      const inputFormat = "MM/dd/yyyy";
      const filter = {};
      if (dates.after) {
        filter.start_date = DateTime.fromFormat(dates.after, inputFormat).toFormat(outputDateFormat);
      }
      if (dates.before) {
        filter.end_date = DateTime.fromFormat(dates.before, inputFormat).toFormat(outputDateFormat);
      }
      if (dates.filter_params === "noDateAdded") {
        filter.include_empty = true;
      }
      const filterToApply = isEmpty(filter) ? [] : [filter];
      setFilter(filterField, filterToApply, columnDef);
      onClose();
    },
    [filterField, onClose, outputDateFormat, setFilter, setSort, sortField, columnDef]
  );

  return (
    <CpDateRangeSelector
      column={column}
      filterContext={{
        applyFilter: onApply,
        filters: {
          [filterField]: {
            after: prevFilter?.start_date
              ? DateTime.fromFormat(prevFilter.start_date, outputDateFormat).toISODate()
              : null,
            before: prevFilter?.end_date
              ? DateTime.fromFormat(prevFilter.end_date, outputDateFormat).toISODate()
              : null,
            order: prevSortDir,
          },
        },
      }}
      menuStyles={{ display: "block" }}
      onCancel={onClose}
      setColumnMenuShown={() => {}} // this is supposed to be optional, but it always gets called when clicking Apply
    />
  );
}
