import React from "react";
import { CustomFieldTypes } from "src/global-settings/custom-fields/custom-fields.helper";
import { CustomFieldText } from "./custom-field-text.component";
import { CustomFieldDate } from "./custom-field-date.component";
import { CustomFieldSingleSelectDropdown } from "./custom-field-single-select-dropdown.component";
import { CustomFieldMultiSelectDropdown } from "./custom-field-multi-select-dropdown.component";

export function CustomField({ field, value, onChange }) {
  switch (field.field_type) {
    case CustomFieldTypes.text.key:
      return <CustomFieldText field={field} value={value} onChange={onChange} />;
    case CustomFieldTypes.date.key:
      return <CustomFieldDate field={field} value={value} onChange={onChange} />;
    case CustomFieldTypes.dropdown.key:
      return <CustomFieldSingleSelectDropdown field={field} value={value} onChange={onChange} />;
    case CustomFieldTypes.multiselect.key:
      return <CustomFieldMultiSelectDropdown field={field} value={value} onChange={onChange} />;
    default:
      return null;
  }
}
