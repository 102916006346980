import React from "react";
import styles from "./modal-header.styles.css";

export default class ModalHeader extends React.Component {
  render() {
    const images = [
      {
        label: "Files",
        url: "https://cdn.canopytax.com/static/style-guide/files.svg",
        width: 65,
        height: 65,
      },
      {
        label: "Surveys",
        url: "https://cdn.canopytax.com/static/style-guide/surveys.svg",
        width: 65,
        height: 65,
      },
      {
        label: "Invoices",
        url: "https://cdn.canopytax.com/static/style-guide/invoices.svg",
        width: 65,
        height: 65,
      },
      {
        label: "Requests",
        url: "https://cdn.canopytax.com/static/style-guide/requests.svg",
        width: 65,
        height: 65,
      },
    ];

    return (
      <div className={`cps-card__header ${styles.header}`}>
        <a
          onClick={this.props.closeModal}
          className={`cps-modal__dialog__close cps-icon cps-icon-close ${styles.closeHeader}`}
        ></a>
        <div className={`cps-text-center cps-subheader cps-wt-bold`}>The Client Portal</div>
        <div className={styles.imagesRow}>
          {images.map((image) => (
            <div key={image.label} className={styles.imageContainer}>
              <img className={styles.image} src={image.url} width={image.width} height={image.height} />
              <div className={`cps-wt-bold`}>{image.label}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
