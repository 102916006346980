import React from "react";
import PropTypes from "prop-types";
import styles from "./resolve-table.styles.css";
import { formatData } from "../common/common.helper.js";

export default class ResolveConflictsTable extends React.Component {
  static propTypes = {
    conflicts: PropTypes.array.isRequired,
    selectionChange: PropTypes.func.isRequired,
    isBusiness: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  render() {
    const { conflicts, selectionChange, isBusiness } = this.props;

    return (
      <div className={`${styles.resolveTable}`}>
        <table className={`${styles.justifyLeft}`} style={{ width: "100%" }}>
          <thead className={`${styles.resolveTableHeader}`}>
            <tr style={{ border: "inherit" }}>
              {conflicts.map((conflict, position) => (
                <th key={position} className={`${styles.resolveTableHeaderText}`}>
                  {conflict.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={`${styles.resolveTableText}`}>
            <tr>
              {conflicts.map((conflict, position) => (
                <td key={position} className="cps-padding-8">
                  {conflict.values.map((value, position) => (
                    <label key={position} className={`cps-radio cps-row ${styles.radioLabel}`}>
                      <input
                        type="radio"
                        onChange={selectionChange.bind(null, conflict.name)}
                        name={`${conflict.name}`}
                        value={`${value}`}
                      />
                      <span className={`${styles.radioLabelSpan}`}>
                        {formatData(value, conflict.dataType, isBusiness)}
                      </span>
                    </label>
                  ))}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
