import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./create-edit-contact-form.krem.css";
import FormBlock from "src/form/form-block/form-block.component.js";
import { Form } from "src/forms-lib";
import BusinessSection from "./business-section/business-section.component.js";
import ContactInformationSection from "./contact-information/contact-information.component.js";
import TypeAndLongevity from "./type-and-longevity/type-and-longevity.component.js";
import ActiveClientToggle from "./active-client-section/active-client.component.js";
import PhoneNumbersSection from "./phone-numbers-section/phone-numbers-section.component.js";
import EmailSection from "./email-section/email-section.component.js";
import AddressSection from "./address-section/address-section.component.js";
import DetailedInformationSection from "./detailed-information-section/detailed-information-section.component";
import TagsSection from "./tags-section/tags-section.component";
import RolesSectionForCreateEditModal from "./roles-section/roles-section-for-create-edit-modal.component";
import ContactOwner from "./contact-owner/contact-owner.component.js";
import ContactPerson from "./contact-person/contact-person.component";
import Source from "./source-and-note-section/source/source.component.js";
import Note from "./source-and-note-section/note/note.component.js";
import CustomFieldsSection from "./custom-fields-section/custom-fields-section.component";
import ExternalId from "./external-id/external-id.component.js";
import ContactFilingStatus from "./contact-filing-status/contact-filing-status.component.js";
import UpgradeContactsModal from "src/modal/upgrade-contacts-modal/upgrade-contacts-modal.component";
import { hasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import { SelectTemplateSection } from "src/create-edit-contact/create-edit-contact-form/select-template-section.component";

export default React.forwardRef((props, ref) => {
  const [loggedInUser] = useWithUserAndTenant();
  return <CreateEditContactForm loggedInUser={loggedInUser} {...props} forwardedRef={ref} />;
});

class CreateEditContactForm extends React.Component {
  state = {
    activeContact: false,
    showClientUpgradeModel: false,
  };

  componentDidMount() {
    const contact = this.props.contact || getDevContact();
    this.setState({
      activeContact: contact
        ? contact.is_active
        : this.props.isContactLimitModel
        ? !this.props.contactsUsageData.reached_limit
        : true,
    });
  }

  handleActiveToggle = (active) => {
    const { contact, isContactLimitModel, contactsUsageData } = this.props;
    if ((!contact || !contact.is_active) && active && isContactLimitModel && contactsUsageData.reached_limit) {
      this.setState({ showClientUpgradeModel: true });
    } else {
      this.setState({ activeContact: active });
    }
  };

  render() {
    const contact = this.props.contact || getDevContact();
    const { forwardedRef, isContactLimitModel, contactsUsageData } = this.props;
    const hasContactsPermission = hasAccess(this.props.loggedInUser)("contacts");
    const canApplyTemplates = hasAccess(this.props.loggedInUser)("templates_client_records_apply");

    return this.state.showClientUpgradeModel ? (
      <UpgradeContactsModal close={this.props.closeModal}>
        <span>
          You've hit your maximum of <span className="cps-wt-semibold">{contactsUsageData.allowed} contacts</span> for
          your pricing tier.
        </span>
      </UpgradeContactsModal>
    ) : (
      <Form
        id="create_edit_contact_form"
        onSubmit={this.onFormSubmit}
        initialValue={contact}
        preservedValues={["id", "is_business", "is_hidden"]}
        sharedValues={[
          "is_business",
          "business_type",
          "industry",
          "contact_type",
          "is_active",
          "filing_status",
          "contact_owner",
          "custom_fields",
          "roles_fields",
          "users_teams_field",
          "tags",
        ]}
        ref={forwardedRef}
      >
        <Scoped css={styles}>
          <div className="cp-mv-32">
            {canApplyTemplates && (
              <div className="template-section">
                <SelectTemplateSection
                  isBusiness={this.props.isEdit ? contact?.is_business || false : null}
                  isEdit={this.props.isEdit}
                />
              </div>
            )}
            {!this.props.isEdit && !this.props.hideBusinessChooser && <BusinessSection />}
            <FormBlock>
              <div className="cp-body-sm cp-wt-semibold cp-mb-16">General Info</div>
              <ContactInformationSection sharedKey="is_business" alwaysShow={true} />
              {hasContactsPermission && (
                <>
                  <TypeAndLongevity />
                  <ActiveClientToggle
                    name="is_active"
                    initialValue={isContactLimitModel ? !contactsUsageData.reached_limit : true}
                    onToggle={this.handleActiveToggle}
                  />
                </>
              )}
            </FormBlock>
            <FormBlock>
              <div className="cp-body-sm cp-wt-semibold cp-mb-16">Client Details</div>
              <EmailSection />
              <PhoneNumbersSection />
              <AddressSection />
            </FormBlock>
            <FormBlock showHr={hasContactsPermission}>
              <div className="cp-body-sm cp-wt-semibold cp-mb-16">Personal Info</div>
              <DetailedInformationSection sharedKey="is_business" />
              <div className="additionalInfo">
                <ContactOwner name="contact_owner" />
                <ExternalId />
                <ContactFilingStatus name="filing_status" />
              </div>
            </FormBlock>
            {hasContactsPermission && (
              <FormBlock>
                <div className="cp-body-sm cp-wt-semibold cp-mb-16">Custom Fields</div>
                <CustomFieldsSection customFields={this.props.customFields} contact={this.props.contact} />
                <Source name="source" initialValue={{ selected: {} }} />
                <Note name="note" />
                <ContactPerson sharedKey="is_business" />
              </FormBlock>
            )}
            <FormBlock>
              <RolesSectionForCreateEditModal
                roleAssignments={contact?.roleAssignments}
                usersTeams={contact?.usersTeams}
                defaultUser={!this.props.isEdit && this.props.loggedInUser?.id}
              />
            </FormBlock>
            {hasContactsPermission && (
              <FormBlock showHr={false}>
                <div className="cp-body-sm cp-wt-semibold cp-mb-16">Tags</div>
                <TagsSection initialValue={[]} name="tags" />
              </FormBlock>
            )}
          </div>
        </Scoped>
      </Form>
    );
  }

  onFormSubmit = (valid, model) => {
    if (valid && !this.props.disabled) {
      this.props.onValidSubmit && this.props.onValidSubmit(model);
    }
  };
}

function getDevContact() {
  try {
    const contact = localStorage.getItem("cp-dev:contact");
    if (contact != undefined) {
      const returnValue = JSON.parse(contact);
      const birth_date = parseDate(returnValue.birth_date);
      const client_since = parseDate(returnValue.client_since);
      return { ...returnValue, birth_date, client_since };
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}

function parseDate(dateString) {
  try {
    const date = new Date(dateString);
    if (date instanceof Date && !isNaN(date)) {
      return date;
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}
