import React, { useState } from "react";
import { useQueryClient } from "src/react-query";
import PropTypes from "prop-types";
import { catchError } from "auto-trace";
import { CpButton, CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import ExportClientListModal from "./export-client-list-modal.component";
import styles from "./kabob-menu.styles.css";
import { patchUser } from "src/resources/users.resource.js";
import useUserPrefsQuery from "src/client-list/use-user-prefs-query.hook";

export default function KabobMenu({
  currentView,
  showActiveClients,
  showInactiveClients,
  showArchivedClients,
  filterViews,
  filterViewOverrides,
  selection,
  currentFilterViewId,
  currentFilters,
  currentSortData,
  globalSearch,
  totalClientsCount,
}) {
  const [showExportModal, setShowExportModal] = useState(false);
  const hasBulkEmailAccess = useHasAccess("contacts_bulk_email");
  const hasExportAccess = useHasAccess("contacts_export");
  const hasImportAccess = useHasAccess("contacts_import");
  const queryClient = useQueryClient();
  const [user] = useWithUserAndTenant();
  const { userPrefs } = useUserPrefsQuery(user);

  function importClients() {
    window.location.assign("#/contacts/import");
  }

  function updateUserPrefs(prefs) {
    patchUser(user.id, {
      preferences: {
        ...user.preferences,
        ...prefs,
      },
    }).subscribe((updatedUser) => {
      queryClient.setQueryData(["user-preferences", updatedUser.id], updatedUser.preferences);
    }, catchError());
  }

  function renderDisplayAs(contactNameDisplayBy, label) {
    const displayPref = userPrefs?.contactNameDisplayBy;
    return (
      <button
        className={styles.displayAsButton}
        onClick={() => {
          updateUserPrefs({ contactNameDisplayBy });
        }}
      >
        <div className="cp-flex-center cp-gap-16">
          {label}
          {(displayPref === contactNameDisplayBy || (!displayPref && contactNameDisplayBy === "first")) && (
            <CpIcon name="checkmark-large" fill="var(--cp-color-button-icon-active-text)" />
          )}
        </div>
      </button>
    );
  }

  return (
    <>
      <CpDropdown
        allowContentClicks
        contentWidth="auto"
        renderTrigger={({ toggle }) => (
          <CpButton className="cp-ml-24" icon="misc-kabob" aria-label="More options" onClick={toggle} />
        )}
        renderContent={({ close }) => (
          <div className="cp-select-list">
            <div onClick={close}>
              {hasExportAccess && <button onClick={() => setShowExportModal(true)}>Export filtered list</button>}
              {currentView !== "Active Clients" && <button onClick={showActiveClients}>Active clients</button>}
              {currentView !== "Inactive Clients" && <button onClick={showInactiveClients}>Inactive clients</button>}
              {currentView !== "Archived Clients" && <button onClick={showArchivedClients}>Archived clients</button>}
              {hasImportAccess && <button onClick={importClients}>Import clients</button>}
              {hasBulkEmailAccess && (
                <button onClick={() => window.location.assign("#/communications/global-settings/bulk-email-history")}>
                  View bulk emails
                </button>
              )}
            </div>
            <div className={styles.divider}></div>
            {renderDisplayAs("first", "First Name, Last Name")}
            {renderDisplayAs("last", "Last Name, First Name")}
          </div>
        )}
      />
      <ExportClientListModal
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        filterViews={filterViews}
        filterViewOverrides={filterViewOverrides}
        selection={selection}
        currentFilterViewId={currentFilterViewId}
        currentFilters={currentFilters}
        currentSortData={currentSortData}
        globalSearch={globalSearch}
        totalClientsCount={totalClientsCount}
      />
    </>
  );
}

KabobMenu.propTypes = {
  currentView: PropTypes.oneOf(["Inactive Clients", "Archived Clients", "Active Clients"]).isRequired,
  showActiveClients: PropTypes.func.isRequired,
  showInactiveClients: PropTypes.func.isRequired,
  showArchivedClients: PropTypes.func.isRequired,
};
