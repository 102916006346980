import React from "react";
import useFormInput from "./use-form-input.hook.js";

export default function (DecoratedComponent) {
  const originalDisplayName = DecoratedComponent.displayName || DecoratedComponent.name;

  function FormInputDecorator(props) {
    const { initialValue, shareValue, name, required, validationFn, ...rest } = props;

    const [value, updateValue, more] = useFormInput({
      name,
      required,
      initialValue,
      shared: shareValue,
      validationFn: validationFn,
    });
    const { setFocus, showInvalid, valid, sharedStateObs, setCustomGetValue } = more;

    return (
      <DecoratedComponent
        valid={valid}
        showInvalid={showInvalid}
        sharedStateObs={sharedStateObs}
        value={value}
        setFocus={setFocus}
        setCustomGetValue={setCustomGetValue}
        updateValue={updateValue}
        {...rest}
      />
    );
  }

  return function (props) {
    return <FormInputDecorator {...props} />;
  };
}
