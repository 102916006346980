import React from "react";
import { useQueryClient } from "src/react-query";
import { catchError } from "auto-trace";
import { successToast } from "toast-service!sofe";
import { patchClients } from "src/client-list/client-list.resource.js";
import ArchiveModal from "src/common/archive-modal.component";

export default function BulkArchiveModal({ onClose, filters, selection, search }) {
  const queryClient = useQueryClient();

  function onArchive() {
    close();
    patchClients({
      search,
      filters,
      action: "archive",
      [selection.type]: selection.toArray(),
    }).subscribe(() => {
      const count = selection.totalSelected;
      successToast(`${count} client${count > 1 ? "s" : ""} will be archived. This may take some time.`);
      selection.deselectAll();
      // Wait a bit after the request to invalidate just in case it finished in time
      setTimeout(() => {
        queryClient.invalidateQueries(["clients"]);
      }, 1000);
    }, catchError());
  }

  return <ArchiveModal onArchive={onArchive} onClose={onClose} totalToArchive={selection.totalSelected} />;
}
