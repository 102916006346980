import React, { useEffect, useMemo } from "react";
import styles from "./search-and-select-filter.styles.css";
import { CpButton, CpSelectSingleField } from "canopy-styleguide!sofe";
import { conditionOpts } from "./search-and-select-filter.helper";
import SearchAndSelectInput from "./search-and-select-input.component";
import { featureEnabled } from "feature-toggles!sofe";

export default function SearchAndSelectFilterForm(props) {
  const { column, control, inputFieldName, onRemove, resetField, selectFieldName, setValue, watch } = props;

  function removeCondition() {
    resetField(selectFieldName, { defaultValue: null });
    resetField(inputFieldName, { defaultValue: "" });
    onRemove();
  }
  const watchSelectField = watch(selectFieldName);
  const watchInputField = watch(inputFieldName);
  const setInputField = (value) => setValue(inputFieldName, value, { shouldTouch: true });

  useEffect(() => {
    if (watchSelectField?.id === "is_blank" || watchSelectField?.id === "has_any_value") {
      resetField(inputFieldName, { defaultValue: [] });
    }
  }, [watchSelectField]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  const conditions = useMemo(() => {
    return conditionOpts.filter(
      (condition) =>
        !(
          (condition.id === "is_all_of" && column.singleSelect) ||
          (!featureEnabled("toggle_team_member_conditions") &&
            column.id === "users" &&
            (condition.id === "is_blank" || condition.id === "has_any_value"))
        )
    );
  }, [column]);

  return (
    <div className="cp-mt-8">
      <div>Select a condition</div>
      <div className="cp-flex">
        <CpSelectSingleField
          control={control}
          fieldName={selectFieldName}
          className={styles.selectField}
          data={conditions}
          triggerIsBlock
          contentWidth="block"
        />
        {!!onRemove && (
          <CpButton
            aria-label="Remove condition"
            className="cp-ml-8"
            btnType="icon"
            icon="close-small"
            onClick={removeCondition}
            type="button"
          />
        )}
      </div>
      <SearchAndSelectInput
        column={column}
        control={control}
        disabled={
          !watchSelectField?.id || watchSelectField?.id === "has_any_value" || watchSelectField?.id === "is_blank"
        }
        fieldName={inputFieldName}
        placeholder="Select values"
        setInputField={setInputField}
        watchInputField={watchInputField}
      />
    </div>
  );
}
