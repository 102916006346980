// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-invite-client-modal-invite-client-modal-styles__actionButtons {\n  border-top: 1px solid var(--cp-color-app-border);\n}\n.src-invite-client-modal-invite-client-modal-styles__actionButtonSend {\n  margin-left: 28px;\n}\n.src-invite-client-modal-invite-client-modal-styles__dialog {\n  width: 700px;\n  top: 100px;\n  max-height: calc(100% - 125px);\n  overflow: auto;\n  transform: translate(-350px);\n  border: none;\n}\n.src-invite-client-modal-invite-client-modal-styles__loaderContainer {\n  width: 100%;\n  padding: 24px;\n  display: flex;\n  justify-content: center;\n}\n.src-invite-client-modal-invite-client-modal-styles__notificationMessageContainer {\n  padding: 0 24px;\n}\n.src-invite-client-modal-invite-client-modal-styles__scrollableArea {\n  overflow-y: auto;\n  max-height: calc(100vh - 400px);\n}\n", ""]);
// Exports
exports.locals = {
	"actionButtons": "src-invite-client-modal-invite-client-modal-styles__actionButtons",
	"actionButtonSend": "src-invite-client-modal-invite-client-modal-styles__actionButtonSend",
	"dialog": "src-invite-client-modal-invite-client-modal-styles__dialog",
	"loaderContainer": "src-invite-client-modal-invite-client-modal-styles__loaderContainer",
	"notificationMessageContainer": "src-invite-client-modal-invite-client-modal-styles__notificationMessageContainer",
	"scrollableArea": "src-invite-client-modal-invite-client-modal-styles__scrollableArea"
};
module.exports = exports;
