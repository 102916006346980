import React, { useEffect, useState } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import { catchError } from "auto-trace";
import { useHasAccess } from "cp-client-auth!sofe";
import { CpSelectMultiPills } from "canopy-styleguide!sofe";
import { useQueryClient } from "src/react-query";
import { useTagsQuery } from "../use-tags-query.hook";

export function TagsSelect({ value, onChange }) {
  const queryClient = useQueryClient();
  const { tags } = useTagsQuery();
  const [search, setSearch] = useState("");
  const [selectedTags, setSelectedTags] = useState(value);
  const canCreateTags = useHasAccess("company_settings_tags");

  function createTag(name) {
    return fetchAsObservable(`/api/tags`, {
      method: "POST",
      body: {
        tags: [{ name }],
      },
    });
  }

  useEffect(() => {
    if (tags) {
      setSelectedTags(
        value.map((val) => {
          if (typeof val === "string") {
            return tags.find((tag) => tag.name === val);
          } else {
            return val;
          }
        })
      );
    }
  }, [value, tags]);

  return (
    <CpSelectMultiPills
      data={tags || []}
      placeholder="Search tags"
      onChange={onChange}
      renderPill={(item) => item.name}
      searchOnChange={(val) => {
        if (val.length <= 25) {
          setSearch(val);
        }
      }}
      searchValue={search}
      searchFilter
      value={selectedTags}
      renderEmptyState={
        canCreateTags
          ? ({ renderSingleItem }) => {
              return renderSingleItem(`Press enter to add “${search.trim()}”`, "25 character limit");
            }
          : null
      }
      emptyStateAction={({ onChange: updateValue }) => {
        createTag(search.trim()).subscribe((res) => {
          updateValue([...value, res.tags]);
          setSearch("");
          queryClient.invalidateQueries("tags");
        }, catchError());
      }}
    />
  );
}
