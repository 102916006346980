import React, { lazy, useState, Suspense } from "react";
import ContactCardLoader from "./contact-card-loader.component";
import { useFetcher } from "fetcher!sofe";
import { CprEmptyState, CpLoader } from "canopy-styleguide!sofe";
import { useCss, m } from "kremling";
import { DateTime } from "luxon";
import { Preview } from "docs-ui!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
const DocIcon = lazy(() => import("docs-ui!sofe").then((mod) => mod.loadDocIcon()));

export default function FilesList(props) {
  const [showPreview, setShowPreview] = useState(null);
  const hasChangeFilesAccess = useHasAccess("files_upload_move");
  const { loading, results, error, refetch } = useFetcher(
    `/api/docs/contacts/${props.contactId}/folders/0/files?view=recent`,
    { pluck: "folder_files" }
  );

  const files = results?.slice(0, 6);

  const emptyFiles = () => {
    if (hasChangeFilesAccess) {
      return (
        <CprEmptyState
          iconSrc="es_files_add"
          headText="Upload a file"
          subheadText="There are no files for this contact."
          showCta={true}
          ctaClickHandler={props.buttonClick}
          ctaButtonText="Upload file"
          ctaButtonType="secondary"
        />
      );
    } else {
      return (
        <CprEmptyState
          iconSrc="folder-closed"
          headText="No files"
          subheadText="No folders or files have been uploaded for this contact"
        />
      );
    }
  };

  const handleClosePreview = () => {
    setShowPreview(null);
  };

  return (
    <div {...useCss(styles)} className={`${m("full", files?.length === 6).m("empty", files?.length === 0)}`}>
      {loading ? (
        <ContactCardLoader />
      ) : !files?.length ? (
        emptyFiles()
      ) : (
        files.map((file) => (
          <div key={file.id} className="row" onClick={() => setShowPreview(file)}>
            <div className="cps-padding-right-16">
              <DocIcon doc={file} isPreview={false} />
            </div>
            <div className="fileInfo">
              <div className="cps-ellipsis cps-body">{file.name}</div>
              <div className="cps-color-cool-gray cps-caption cps-ellipsis">
                {`${file.created_by_full_name} added this ${DateTime.fromISO(file.created_at, {
                  zone: "utc",
                }).toRelative()}`}
              </div>
            </div>
          </div>
        ))
      )}
      {showPreview && (
        <Suspense fallback={<CpLoader size="lg" />}>
          <Preview
            contactId={props.contactId}
            doc={showPreview}
            handleClosePreview={handleClosePreview}
            actions={["print", "download", "viewDetails", "openInNewTab"]}
            features={["annotations", "notes"]}
          />
        </Suspense>
      )}
    </div>
  );
}

const styles = `
& .row {
  align-items: center;
}
& .full > .row:last-of-type {
  border-bottom: none;
}
& .fileInfo {
  max-width: 80%;
}
& .full {
  display: flex;
  flex-direction: column;
  height: 100%;
}
`;
