import { get } from "lodash";

export const contactToModel = {
  topSection: topSection,
  emails: mapEmailToModel,
  phones: mapPhoneToModel,
  addresses,
  bottomSection,
  always,
};

function always(contact) {
  let created_on;
  if (contact.created_on) {
    created_on = new Date(contact.created_on);
  }

  const always = {
    id: contact.id,
    created_on: created_on,
    owner_id: contact.contact_owner_id,
    note: contact.notes,
    is_hidden: contact.is_hidden,
    custom_fields: parseCustomFields(contact),
    filing_status: contact.filing_status,
    external_id: contact.external_id,
  };

  return always;
}

function parseCustomFields(contact) {
  const arrayRepresentation = contact.custom_fields || [];
  let objectRepresentation = {};

  arrayRepresentation.map((field) => {
    if (field.type === "dropdown") {
      if (field.dropdown_values.length) {
        const selection = field.dropdown_values[0];
        objectRepresentation[field.field_id] = {
          type: field.type,
          value: { key: selection.id, value: selection.value },
        };
      }
    } else if (field.type === "multiselect") {
      if (field.dropdown_values.length) {
        const selections = field.dropdown_values.map((selection) => {
          return { key: selection.id, value: selection.value };
        });
        objectRepresentation[field.field_id] = { type: field.type, value: selections };
      }
    } else {
      objectRepresentation[field.field_id] = { type: field.type, value: field.value };
    }
  });
  return objectRepresentation;
}

function topSection(contact) {
  const always = {
    first_name: contact.first_name,
    middle_name: contact.middle_name,
    last_name: contact.last_name,
    is_business: contact.is_business,
    contact_type: contact.contact_type,
    business_name: contact.business_name,
    is_active: contact.is_active,
    display_name: contact.display_name,
  };
  let maybe = {};

  if (contact.client_since) {
    maybe.client_since = new Date(contact.client_since);
  }

  return {
    ...always,
    ...maybe,
  };
}

function bottomSection(contact) {
  let bottomSectionBusinessFields = {};
  let bottomSectionIndividualFields = {};
  if (contact.is_business === true) {
    bottomSectionBusinessFields = {
      ein: contact.tin,
      business_type: contact.business_type,
      industry: contact.industry,
      contact_person: contact.contact_person,
    };
    if (contact.date_established) {
      bottomSectionBusinessFields.date_established = new Date(contact.date_established);
    }
  } else {
    bottomSectionIndividualFields = {
      ssn: contact.tin,
      employer: contact.employer_name,
      occupation: contact.occupation,
    };
    if (contact.birthdate) {
      bottomSectionIndividualFields.birth_date = new Date(contact.birthdate);
    }
  }
  const tagsModel = tags(contact);
  const sourceModel = source(contact);
  return {
    ...bottomSectionBusinessFields,
    ...bottomSectionIndividualFields,
    ...tagsModel,
    note: contact.notes,
    ...sourceModel,
    contact_owner: contact.contact_owner_id,
  };
}

function tags(contact) {
  if (contact.relationships && contact.relationships.tags) {
    return {
      tags: contact.relationships.tags.map((tag) => tag.name),
    };
  } else {
    return {};
  }
}

function source(contact) {
  const contact_sources = get(contact, "relationships.contact_sources", []);
  const source = get(contact_sources, "[0]", {});
  const referralReference = get(contact, "relationships.contacts", [])
    .filter((relationship) => relationship.relationship_type === "referral")
    .reduce((acc, relationship, index) => {
      if (index === 0) {
        acc = {
          relationship_type: relationship.relationship_type,
          id: relationship.id,
          name: relationship.name,
          user_has_access: relationship.user_has_access,
        };
      }
      return acc;
    }, undefined);
  if (referralReference !== undefined) {
    source.selected = referralReference;
  }
  return { source };
}

function mapPhoneToModel(contact) {
  const numbers = get(contact, "phones", [])
    .map((phone) => {
      if (phone.id !== undefined) {
        const [splitNumber, extension] = phone.value.split(" x ");
        return {
          id: phone.id,
          number: phone.number || splitNumber,
          ext: phone.extension || extension,
          type: phone.key === "main" ? "work" : phone.key,
          is_primary: phone.isPrimary,
        };
      }
    })
    .filter((phone) => !!phone);
  return {
    phone_numbers: numbers.length > 0 ? numbers : undefined,
  };
}

function mapContactSinceToModel(contact) {
  return {
    client_since: contact.client_since,
  };
}

function mapEmailToModel(contact) {
  const emailArray = get(contact, "emails", [])
    .map((email) => {
      if (email.id) {
        return {
          id: email.id,
          type: email.key === "main" ? "work" : email.key,
          address: email.value,
          is_primary: email.isPrimary,
        };
      }
    })
    .filter((email) => !!email);
  return {
    email_addresses: emailArray.length > 0 ? emailArray : undefined,
  };
}

function addresses(contact) {
  const addressArray = get(contact, "addresses", [])
    .map((address) => {
      if (checkForAnyAddressValue(address.value)) {
        const mappedAddress = mapAddress(address.value);
        return {
          id: address.id,
          type: addressKeyMap(address.key),
          is_primary: address.isPrimary,
          ...mappedAddress,
        };
      }
    })
    .filter((address) => !!address);
  return {
    addresses: addressArray.length > 0 ? addressArray : undefined,
  };
}

function checkForAnyAddressValue(address) {
  return (
    address.address_1 ||
    address.address_2 ||
    address.country ||
    address.region ||
    address.locality ||
    address.postal_code
  );
}

function addressKeyMap(key) {
  if (key === "home") {
    return "physical";
  } else {
    return key;
  }
}

function mapAddress(address) {
  return {
    line_1: address.address_1,
    line_2: address.address_2,
    country: address.country,
    region: address.region,
    city: address.locality,
    postal_code: address.postal_code,
  };
}
