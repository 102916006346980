import React from "react";
import { CpButton } from "canopy-styleguide!sofe";

export default function TemplateConfirmation({ close, onApply, isManageAssignments }) {
  return (
    <div className="cp-m-16">
      <div className="cp-mb-16 cp-subheader-sm">Apply Client Record Template</div>
      <div className="cp-mb-16 cp-body-sm">
        {isManageAssignments
          ? "Some role fields have already been filled. Applying this template will replace all existing role selections with those in the template."
          : "Some fields have already been filled. Applying this template will replace all existing content in these fields with the template's content."}
      </div>
      <div>
        <CpButton btnType="primary" className="cp-mr-8" onClick={onApply} type="button">
          Apply template
        </CpButton>
        <CpButton btnType="flat" onClick={close} type="button">
          Cancel
        </CpButton>
      </div>
    </div>
  );
}
