import React from "react";
import { SimpleSelect } from "src/form/select-input/select-input.component.js";

export function CustomFieldSingleSelectDropdown({ value, onChange, field }) {
  const options = mapDropdownToOptions();

  return (
    <SimpleSelect
      labelText={field.field_name}
      options={options}
      value={value?.key}
      updateValue={updateValue}
      clearable
    />
  );

  function updateValue(key) {
    const selectedValue = field.dropdown_values.find((value) => value.id === key);
    onChange({ key, value: selectedValue ? selectedValue.value : "" });
  }

  function mapDropdownToOptions() {
    let dropdownOptions = [];
    field.dropdown_values.map((option) => {
      dropdownOptions.push({ key: option.id, value: option.value });
    });

    return dropdownOptions;
  }
}
