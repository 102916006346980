import React, { useCallback, useRef, useState, useMemo } from "react";
import { useVirtual } from "react-virtual";
import { m } from "kremling";
import { CpLoader, CpEmptyState } from "canopy-styleguide!sofe";
import SelectCell from "./cells/select-cell.component";
import ContextMenu from "./context-menu.component";
import styles from "./table-body.styles.css";

export default function TableBody({
  tableContainerRef,
  orderedColumns,
  columnDefs,
  clients,
  selection,
  toggleSelection,
  isLoading,
  isSearching,
  contextMenuActions,
}) {
  const contextMenuRef = useRef();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: clients.length,
    // NOTE: If you see bugs with sticky borders disappearing while scrolling it may be due to this estimate size.
    // If the estimate is not exact then for some reason borders on sticky columns can disappear during scroll.
    estimateSize: useCallback(() => 44.5, []),
    overscan: 20,
  });

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom = items.length > 0 ? rowVirtualizer.totalSize - items[items.length - 1].end : 0;

  const [contextMenuClientId, setContextMenuClientId] = useState();
  const openContextMenu = useCallback((e, clientId) => {
    contextMenuRef.current.openContextMenu(e, clientId);
    setContextMenuClientId(clientId);
  }, []);

  if (isLoading || clients.length <= 0) {
    return (
      <tbody className={styles.emptyStateBody}>
        <tr>
          <td className={styles.emptyStateCell}>
            {isLoading ? (
              <CpLoader size="lg" />
            ) : isSearching ? (
              <CpEmptyState
                img="es_contacts_search"
                text="No search results"
                subText="Please refine your search criteria."
              />
            ) : (
              <CpEmptyState img="es_filter" text="No results" subText="Please refine your filters." />
            )}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px`, border: "none" }} />
        </tr>
      )}
      {rowVirtualizer.virtualItems.map((virtualRow) => {
        const client = clients[virtualRow.index];
        return (
          <MemoRow
            client={client}
            key={
              // TODO: Adding virtual index to allow for duplicates, we should probably just use client.id once we get the new endpoint in and can ensure that there will be no duplicates.
              client.id + "_" + virtualRow.index
            }
            columnDefs={columnDefs}
            orderedColumns={orderedColumns}
            openContextMenu={openContextMenu}
            contextMenuOpenOnRow={contextMenuClientId === client.id}
            selection={selection}
            toggleSelection={toggleSelection}
          />
        );
      })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px`, border: "none" }} />
        </tr>
      )}
      <tr className={styles.hiddenRow}>
        <td className={styles.hiddenCell}>
          <ContextMenu ref={contextMenuRef} actions={contextMenuActions} onClose={() => setContextMenuClientId(null)} />
        </td>
      </tr>
    </tbody>
  );
}

function Row({
  client,
  columnDefs,
  orderedColumns,
  openContextMenu,
  contextMenuOpenOnRow,
  selection,
  toggleSelection,
}) {
  return (
    <tr
      onContextMenu={(e) => openContextMenu(e, client.id)}
      className={contextMenuOpenOnRow ? styles.contextMenuHighlight : ""}
    >
      <td className={styles.selectCell}>
        <SelectCell client={client} selection={selection} toggleSelection={toggleSelection} />
      </td>
      <MemoDataRow orderedColumns={orderedColumns} columnDefs={columnDefs} client={client} />
    </tr>
  );
}
const MemoRow = React.memo(Row);

function DataRow({ orderedColumns, columnDefs, client }) {
  const assignmentsByRoleId = useMemo(() => {
    if (client.role_assignments?.length > 0) {
      return client.role_assignments.reduce((acc, assignment) => {
        acc[assignment.role_id] = assignment;
        return acc;
      }, {});
    } else {
      return {};
    }
  }, [client.role_assignments]);

  return orderedColumns.map((columnId) => {
    const column = columnDefs[columnId];
    if (!column) return null;
    const CellComponent = column.cell;
    const value = client[columnId];

    return (
      <td key={columnId} className={m(styles.clientNameCell, columnId === "full_name").a("cp-ellipsis")}>
        <CellComponent
          client={client}
          columnId={columnId}
          column={column}
          value={column.formatValue ? column.formatValue(value) : value}
          assignmentsByRoleId={assignmentsByRoleId}
        />
      </td>
    );
  });
}
const MemoDataRow = React.memo(DataRow);
