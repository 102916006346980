// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-common-client-record-fields-field-label-field-label-styles__fieldLabel {\n  display: inline-block;\n  padding-top: 0.3rem;\n  margin-bottom: 0.4rem;\n}\n", ""]);
// Exports
exports.locals = {
	"fieldLabel": "src-common-client-record-fields-field-label-field-label-styles__fieldLabel"
};
module.exports = exports;
