import React from "react";
import Downshift from "downshift";
import { Scoped } from "kremling";
// project
import styles from "./typeahead-input.krem.css";
import { InputDecorator } from "src/forms-lib";
import TypeaheadMenu from "./typeahead-menu/typeahead-menu.component.js";
import { useTypeaheadSearch } from "./typeahead-hooks.js";

export function SimpleTypeahead(props) {
  const { value, updateValue, itemToString, items } = props;
  if (props.selectedItem !== undefined && props.shouldUpdateViaSideEffect !== undefined) {
    // special logic to allow me to set the value inside the form context as a side effect related to creating a new contact
    if (props.shouldUpdateViaSideEffect(value)) {
      updateValue(props.selectedItem);
    }
  }
  const [triggerSearch, search, loading, foundItems] = useTypeaheadSearch(
    props.debounceTime,
    props.Observable,
    props.items,
    itemToString
  );

  return (
    <Downshift
      onSelect={props.onSelect}
      onInputValueChange={(inputValue) => triggerSearch(inputValue)}
      onChange={(selection) => updateValue(selection)}
      selectedItem={value}
      itemToString={itemToString}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex }) => {
        const { placeholder, clearSelectionOnEmptyInputBlur = true, emptySelectedItem } = props;
        return (
          <div
            onBlur={() => {
              if (
                (inputValue === "" || inputValue === undefined) &&
                value !== "" &&
                value !== undefined &&
                clearSelectionOnEmptyInputBlur
              ) {
                updateValue(emptySelectedItem);
              }
            }}
          >
            <Scoped css={styles}>
              <div>
                <div>
                  {showLabel()}
                  <input
                    className="cps-form-control"
                    placeholder={placeholder}
                    {...getInputProps({
                      value: inputValue || "",
                    })}
                  />
                </div>
                <TypeaheadMenu
                  isOpen={isOpen}
                  highlightedIndex={highlightedIndex}
                  getItemProps={getItemProps}
                  getMenuProps={getMenuProps}
                  items={foundItems}
                  search={search}
                  itemToString={itemToString}
                  resultFormat={props.resultFormat}
                  resultsAppendBlock={props.resultsAppendBlock}
                  loading={loading}
                  itemKey={props.itemKey}
                />
              </div>
            </Scoped>
          </div>
        );
      }}
    </Downshift>
  );

  function showLabel() {
    const { label } = props;
    if (label) {
      if (typeof label === "function") {
        return label({ valid: props.valid });
      } else {
        return label;
      }
    }
  }
}

export default InputDecorator(SimpleTypeahead);
