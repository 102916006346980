import React from "react";
import { a } from "kremling";
import { CpButton } from "canopy-styleguide!sofe";
import styles from "./client-business-toggle.styles.css";

export function ClientBusinessToggle({ className, value, onChange }) {
  return (
    <div className={a(styles.container).a(className || "")}>
      <CpButton
        btnType={!value ? "flat" : "tertiary"}
        type="button"
        className={a(styles.button).m(styles.active, !value).a("flex-1")}
        icon="person"
        onClick={() => onChange(false)}
      >
        Individual
      </CpButton>
      <CpButton
        btnType={!!value ? "flat" : "tertiary"}
        type="button"
        className={a(styles.button).m(styles.active, !!value).a("flex-1")}
        icon="misc-buildings"
        onClick={() => onChange(true)}
      >
        Business
      </CpButton>
    </div>
  );
}
