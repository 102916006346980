import { pluck } from "rxjs/operators";
import { fetchAsObservable, fetchWithSharedCache } from "fetcher!sofe";
import { partial } from "lodash";

export function getSources(forceBust = false) {
  return fetchWithSharedCache(`api/contact_sources`, "#/client", forceBust).pipe(pluck("contact_sources"));
}

export function getContactAndAll(contactId, forceBust = false) {
  if (!contactId || contactId === "") {
    throw new Error("cannot get contact without a contactID");
  }
  const include = "users,contacts,tags,contact_for,contact_sources,integrations,roles,general_assigned";
  return fetchWithSharedCache(
    `api/clients/${contactId}?include=${include}`,
    partial(untilClientIdHasChanged, contactId),
    forceBust
  ).pipe(pluck("clients"));
}

export function getUsers(role) {
  return fetchAsObservable(`wg/users${role ? "?role=" + role : ""}&basic=true`).pipe(pluck("users"));
}

function untilClientIdHasChanged(clientId) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}
