import React, { useState } from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

export default function ArchiveModal({ onArchive, onClose, totalToArchive }) {
  const [show, setShow] = useState(true);
  const close = () => setShow(false);

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose}>
      <CpModal.Header title="Archive" />
      <CpModal.Body>
        <div>The selected {totalToArchive > 1 ? `${totalToArchive} clients` : "client"} will be archived.</div>
        <div className="cp-mt-12">Visit the Archived Clients page to move clients back to active.</div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={onArchive} className="cp-mr-8">
          Continue
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
