import { forOwn } from "lodash";

export function isUserIncluded(currentUserId, assignments) {
  let currentUserAssigned = false;
  forOwn(assignments, (val) => {
    const includesCurrentUser = val.some((item) => {
      if (item?.role === "TeamMember") {
        return item.id === currentUserId;
      } else {
        // check if currentUserId included by a team
        return !!item?.users.some((u) => u.id === currentUserId);
      }
    });

    if (includesCurrentUser) currentUserAssigned = true;
  });

  return currentUserAssigned;
}
