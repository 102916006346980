import { useQuery } from "src/react-query";
import { fetchAsObservable } from "fetcher!sofe";
import { catchError } from "auto-trace";

export function useTagsQuery() {
  const query = useQuery({
    queryKey: ["tags"],
    queryFn: () =>
      new Promise((resolve, reject) => {
        fetchAsObservable(`api/tags`).subscribe(
          (result) => {
            resolve(result.tags);
          },
          catchError((err, throwErr) => {
            reject(err);
            throwErr(err);
          })
        );
      }),
    staleTime: 10000, // 10 secs
  });

  return {
    tagsQuery: query,
    tags: query.data,
  };
}
