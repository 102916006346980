import React, { useContext, useEffect, useState } from "react";
import { CpCheckbox, CpCheckboxGroup, CpWell } from "canopy-styleguide!sofe";
import styles from "./columns-section.styles.css";
import { isEmpty } from "lodash";
import { FilterContext } from "../../client-list.component";

export default function ColumnsSection({ title, readOnly, columns, visibleColumns, onColumnVisibilityChange }) {
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const { getFilter, getSort } = useContext(FilterContext);

  const onCheckboxChange = (columnIds) => {
    setCheckboxValues(columnIds);
    onColumnVisibilityChange(columnIds);
  };

  const onSelectAllChange = (checked) => {
    let columnIds = [];
    if (checked) {
      columnIds = columns?.map((column) => column.id);
    }
    onCheckboxChange(columnIds);
    setSelectAll(checked);
  };

  useEffect(() => {
    setCheckboxValues(visibleColumns?.map((column) => column.id));
  }, [visibleColumns]);

  useEffect(() => {
    if (checkboxValues?.length === columns?.length) {
      setSelectAll(true);
    } else if (checkboxValues?.length === 0) {
      setSelectAll(false);
    } else {
      setSelectAll("indeterminate");
    }
  }, [checkboxValues?.length, columns?.length]);

  return (
    <CpWell className="cp-p-24" level={1}>
      <div className="cp-mb-16 cp-body cp-wt-semibold">{title}</div>
      {!!columns?.length && (
        <div>
          <div>
            <CpCheckbox
              className={styles.checkbox}
              checked={selectAll}
              onChange={onSelectAllChange}
              disabled={readOnly}
            >
              Select All
            </CpCheckbox>
          </div>
          <CpCheckboxGroup className={styles.checkboxGroup} value={checkboxValues} onChange={onCheckboxChange}>
            {columns.map((value) => {
              const hasFiltersApplied =
                !isEmpty(getFilter(value.filterField)?.[0]) || !!getSort(value.filterField)?.sort;
              return (
                <CpCheckboxGroup.Item className={styles.checkbox} id={value.id} key={value.id} disabled={readOnly}>
                  <div>{value.label}</div>
                  {hasFiltersApplied && (
                    <div className="cp-caption cp-color-app-secondary-text">
                      <i>Filters applied</i>
                    </div>
                  )}
                </CpCheckboxGroup.Item>
              );
            })}
          </CpCheckboxGroup>
        </div>
      )}
    </CpWell>
  );
}
