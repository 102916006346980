import React, { useContext } from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import SelectTemplateButton from "../../templates/select-template-button/select-template-button.component";
import useTeamMembersQuery from "../../client-list/use-team-members-query.hook";
import useCustomFieldsQuery from "../../client-list/use-custom-fields-query.hook";
import { Context } from "src/forms-lib";
import useSharedState from "src/forms-lib/use-shared-value.hook";
import { useTagsQuery } from "src/common/use-tags-query.hook";
import { mapIdsToItems } from "src/common/utils.helpers";
import {
  templateCustomFieldsToFormData,
  templateRoleAssignmentsToFormData,
  templateUserTeamsToFormData,
} from "./create-edit-contact-form.helpers";
import { has, isEqual, isEmpty } from "lodash";

export function SelectTemplateSection({ isBusiness, isEdit }) {
  const { teamMembers, teams } = useTeamMembersQuery(false);
  const { customFields } = useCustomFieldsQuery();
  const context = useContext(Context);
  const { updateSharedValue } = context;
  const sharedState = useSharedState();
  const canSetActive = useHasAccess("contacts_toggle_active");
  const { tags } = useTagsQuery();

  function onApply(template, formData) {
    updateSharedValue(formData, true);
  }

  function checkIsFormDirty(template) {
    const contactOwner = teamMembers.find((tm) => tm.id === template.contact_owner_id);
    const customFieldsFormData = templateCustomFieldsToFormData(customFields, template.custom_fields);
    const roleAssignmentsFormData = templateRoleAssignmentsToFormData(
      teamMembers,
      teams,
      template.roles?.role_assignments
    );

    const newValues = {
      contact_type: template.type,
      business_type: template.business_type,
      filing_status: template.filing_status,
      industry: template.industry,
      custom_fields: customFieldsFormData,
      roles_fields: roleAssignmentsFormData,
      users_teams_field: templateUserTeamsToFormData(teamMembers, teams, template.roles),
      tags: mapIdsToItems(template.tags, tags),
    };

    if (canSetActive) newValues.is_active = template.is_active;
    if (!isEdit) newValues.is_business = template.is_business;
    if (contactOwner) newValues.contact_owner = contactOwner;

    const isFormDirty = !isFormDataEqual(newValues, sharedState);
    return [isFormDirty, newValues];
  }

  // Compares two sets of form data and returns true if they contain the same information
  function isFormDataEqual(newValues, sharedState) {
    const fieldsToCheck = [
      "is_business",
      "business_type",
      "is_active",
      "filing_status",
      "industry",
      "contact_owner",
      "custom_fields",
      "tags",
      "roles_fields",
      "users_teams_field",
    ];
    let equal = true;

    for (const field of fieldsToCheck) {
      if (!has(newValues, field) || isEmpty(sharedState[field])) {
        // newValues doesn't have this field, or the current form field is empty
        continue;
      } else if (!isEqual(newValues[field], sharedState[field])) {
        equal = false;
        break;
      }
    }
    return equal;
  }

  return <SelectTemplateButton isBusiness={isBusiness} onApplyTemplate={onApply} validateForm={checkIsFormDirty} />;
}
