import { includes } from "lodash";

export function registerRef(refs, refArray) {
  validateRefs(refs);
  let fieldRefs = [...refArray];
  if (Array.isArray(refs)) {
    if (refs.indexOf(undefined) !== -1) {
      throw new Error("cannot register an undefined ref");
    }
    fieldRefs = [...fieldRefs, ...refs.filter((ref) => ref !== undefined && ref !== null)];
  } else if (typeof refs === "object" && refs !== undefined && refs !== null) {
    fieldRefs = [...fieldRefs, refs];
  } else {
    throw new Error(`cannot register a ref with the typeof as ${typeof refs}`);
  }

  return fieldRefs;
}

export function deregisterRef(refs, refArray) {
  const prev = [...refArray];
  let filteredRefs;
  let fieldRefs = [...prev];
  if (Array.isArray(refs)) {
    filteredRefs = prev.filter((fieldRef) => !includes(refs, fieldRef));
    fieldRefs = [...filteredRefs];
  } else if (typeof refs === "object") {
    filteredRefs = prev.filter((fieldRef) => fieldRef !== refs);
    fieldRefs = [...filteredRefs];
  }

  return fieldRefs;
}

export function validateRefs(refs) {
  if (Array.isArray(refs)) {
    refs.forEach((ref) => {
      validateRef(ref);
    });
  } else {
    validateRef(refs);
  }
}

export function validateRef(ref) {
  if (ref === undefined || ref === null) {
    throw new Error("cannot add a ref that is undefined or null");
  } else if (typeof ref === "object") {
    const getName = typeof ref.getName;
    const getValue = typeof ref.getValue;
    const isInvalid = typeof ref.isInvalid;
    const focus = typeof ref.focus;
    if (getName !== "function") {
      throw new Error(`ref was added without a getName method`);
    }
    if (getValue !== "function") {
      throw new Error(`ref was added without a getValue method`);
    }
    if (isInvalid !== "function" && isInvalid !== "undefined") {
      throw new Error(`ref was added with an invalid 'isInvalid' key. It must be a function or undefined`);
    }
    if (focus !== "function" && focus !== "undefined") {
      throw new Error(`ref was added with an invalid 'focus' key. It must be a function or undefined`);
    }
  }
}
