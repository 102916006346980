import React from "react";

export default function TextHeader({ column }) {
  const { label } = column;

  return (
    <div className="cp-flex-center">
      <div className="cp-ellipsis">{label}</div>
    </div>
  );
}
