import React from "react";
import PropTypes from "prop-types";
import { Input } from "src/form/input/input.component";
import { FieldLabel } from "src/common/client-record-fields";

CustomFieldText.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export function CustomFieldText({ field, value, onChange }) {
  return (
    <Input
      label={
        <FieldLabel>
          <span>{field.field_name}</span>
        </FieldLabel>
      }
      value={value}
      updateValue={onChange}
    />
  );
}
