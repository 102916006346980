import { useMemo } from "react";
import { useQuery } from "src/react-query";
import { EMPTY } from "rxjs";
import { expand, reduce, pluck } from "rxjs/operators";
import { catchSyncStacktrace } from "auto-trace";
import { sortBy } from "lodash";
import { fetchAsObservable } from "fetcher!sofe";

export function getUsers(onlyActive = true) {
  return fetchAsObservable(`/wg/users?role=TeamMember&basic=true&onlyActive=${onlyActive}`).pipe(pluck("users"));
}

export function getTeams(page = "1") {
  return fetchAsObservable(`/wg/user-teams?limit=100&page=${page}`);
}

export function useTeamMembersQuery(onlyActive = true) {
  const query = useQuery({
    queryKey: ["team-members", onlyActive],
    queryFn: () =>
      new Promise((resolve, reject) => {
        getUsers(onlyActive).subscribe((users) => {
          getTeams()
            .pipe(
              expand((result) => {
                const nextPage = result.meta.paginator.next_page;
                return nextPage ? getTeams(nextPage) : EMPTY;
              }),
              reduce((acc, data) => acc.concat(data?.teams), [])
            )
            .subscribe((teams) => {
              resolve({
                users: sortBy(users, "name"),
                teams: teams,
              });
            }, reject);
        }, reject);
      }),
    onError: (err) => catchSyncStacktrace(err),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes in ms
  });

  const { teamMembers, teams } = useMemo(
    () => ({
      teamMembers: query.data?.users || [],
      teams: query.data?.teams || [],
    }),
    [query.data]
  );

  return {
    teamMembersQuery: query,
    teamMembers,
    teams,
  };
}
export default useTeamMembersQuery;
