// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-common-client-record-fields-client-type-picker-client-type-picker-styles__typeOption {\n  border-radius: 26px;\n  text-decoration: none;\n  -webkit-user-select: none;\n          user-select: none;\n  font-size: 1.3rem;\n  padding: 6px 12px;\n  background-color: var(--cps-color-bumble);\n  border: 1px solid var(--cps-color-af);\n  color: var(--cps-color-monsoon);\n  margin-left: 0px;\n}\n\n.src-common-client-record-fields-client-type-picker-client-type-picker-styles__typeOption:hover,\n.src-common-client-record-fields-client-type-picker-client-type-picker-styles__typeOption:focus {\n  outline: 0;\n  cursor: pointer;\n  background-color: var(--cps-color-ash);\n  border: 1px solid var(--cps-color-monsoon);\n  color: var(--cps-color-cool-gray);\n}\n\n.src-common-client-record-fields-client-type-picker-client-type-picker-styles__selectedTypeOption {\n  background-color: rgba(var(--cps-color-primary-rgb), 0.05);\n  color: var(--cps-color-primary);\n  border: 1px solid var(--cps-color-primary);\n}\n\n.src-common-client-record-fields-client-type-picker-client-type-picker-styles__selectedTypeOption:hover,\n.src-common-client-record-fields-client-type-picker-client-type-picker-styles__selectedTypeOption:focus {\n  outline: 0;\n  background-color: rgba(var(--cps-color-primary-rgb), 0.05);\n  color: var(--cps-color-primary);\n  border: 1px solid var(--cps-color-primary);\n}\n", ""]);
// Exports
exports.locals = {
	"typeOption": "src-common-client-record-fields-client-type-picker-client-type-picker-styles__typeOption",
	"selectedTypeOption": "src-common-client-record-fields-client-type-picker-client-type-picker-styles__selectedTypeOption"
};
module.exports = exports;
