import React, { useEffect, useMemo, useRef, useState } from "react";
import { CpSelectSingle, useDebounce } from "canopy-styleguide!sofe";
import { forOwn, difference, xor } from "lodash";
import { useQueryClient } from "src/react-query";
import { catchError } from "auto-trace";
import { buildColumnDefs, getDefaultVisibleColumns } from "../../table/columns.helper";
import { patchFilterView } from "../../client-list.resource";
import useCustomFieldsQuery from "src/client-list/use-custom-fields-query.hook";
import useQboIntegrationQuery from "src/client-list/use-qbo-integration-query.hook";
import { track } from "src/resources/analytics.resource";
import ColumnsSection from "./columns-section.component";
import useRolesQuery from "src/create-edit-contact/create-edit-contact-form/roles-section/use-roles-query-hook.js";

export default function CustomizeColumnsBody({
  filterViews,
  filterView,
  filterViewOverrides,
  onColumnVisibilityChange,
}) {
  const [selectedFilterView, setSelectedFilterView] = useState(filterView);
  const [search, setSearch] = useState("");
  const queryClient = useQueryClient();
  const { customFields } = useCustomFieldsQuery();
  const { roles } = useRolesQuery();

  const columnDefs = useMemo(() => {
    if (customFields) {
      return buildColumnDefs(customFields, roles);
    } else {
      return {};
    }
  }, [customFields, roles]);

  const defaultVisibleColumns = useMemo(
    () => getDefaultVisibleColumns(customFields?.map((f) => f.field_id)),
    [customFields]
  );

  const { qboIntegrationInfo } = useQboIntegrationQuery();

  const { read_only: readOnly } = selectedFilterView;
  const numTeamMembersShared = selectedFilterView?.sharing_data?.filter((val) => val.type === "user").length;
  const numTeamsShared = selectedFilterView?.sharing_data?.filter((val) => val.type === "team").length;

  const visibleColumns = useMemo(() => {
    const override = filterViewOverrides[selectedFilterView?.id];
    return override?.visible_columns || selectedFilterView.filter_data.visible_columns || defaultVisibleColumns;
  }, [selectedFilterView, defaultVisibleColumns, filterViewOverrides]);

  const columnsBySection = useMemo(() => {
    const map = {};
    forOwn(columnDefs, (column) => {
      if (!column.section) return;
      if (column.id === "qbo" && !qboIntegrationInfo?.connected) return;

      if (!map[column.section]) {
        map[column.section] = [column];
      } else {
        map[column.section].push(column);
      }
    });
    return map;
  }, [columnDefs, qboIntegrationInfo]);

  const visibleColumnsBySection = useMemo(() => {
    const map = {};
    forOwn(columnsBySection, (columns, section) => {
      map[section] = columns.filter((c) => visibleColumns.includes(c.id));
    });
    return map;
  }, [columnsBySection, visibleColumns]);

  // Using a ref so that we can keep track of optimistic changes with the debounce
  const newVisibleColumnsBySection = useRef(null);
  useEffect(() => {
    newVisibleColumnsBySection.current = null;
  }, [selectedFilterView]);

  useEffect(() => {
    setSelectedFilterView((selectedFilterView) => {
      const updatedView = filterViews.find((filterView) => filterView.id === selectedFilterView.id);
      return updatedView ? updatedView : selectedFilterView;
    });
  }, [filterViews]);

  const visibleSections = [
    { id: "client", title: "Client attributes" },
    { id: "firm", title: "Firm attributes" },
    { id: "business", title: "Business attributes" },
    ...(!!columnsBySection["roles"] ? [{ id: "roles", title: "Roles" }] : []),
    ...(!!columnsBySection["custom"] ? [{ id: "custom", title: "Custom fields" }] : []),
  ];

  function trackColumnsAdded(filterView, columnsAdded) {
    if (columnsAdded?.length) {
      track("client_list.column-customization-attributes-added", {
        filterViewId: filterView.id,
        filterViewName: filterView.name,
        columnsAdded,
      });
    }
  }

  function updateVisibleColumns({ selectedFilterView, filterViewOverrides, defaultVisibleColumns, queryClient }) {
    const newVisibleColumns = [];
    forOwn(newVisibleColumnsBySection.current, (columns) => {
      newVisibleColumns.push(...columns.map((col) => col.id));
    });

    const allVisibleColumns = ["full_name", ...newVisibleColumns];
    const override = filterViewOverrides[selectedFilterView.id];
    const prevVisibleColumns =
      override?.visible_columns || selectedFilterView.filter_data.visible_columns || defaultVisibleColumns;

    if (xor(prevVisibleColumns, allVisibleColumns).length === 0) {
      return;
    }

    if (!readOnly && selectedFilterView.created_by !== "__DEFAULT") {
      const filter_data = {
        ...selectedFilterView.filter_data,
        visible_columns: allVisibleColumns,
      };
      patchFilterView(selectedFilterView.id, { filter_data }).subscribe(() => {
        queryClient.invalidateQueries(["filter-views"]);
      }, catchError());
    } else {
      onColumnVisibilityChange(selectedFilterView.id, allVisibleColumns);
    }
    const columnsAdded = difference(allVisibleColumns, prevVisibleColumns);
    trackColumnsAdded(selectedFilterView, columnsAdded);
  }
  const debouncedUpdateVisibleColumns = useDebounce(updateVisibleColumns, 1000, {}, []);

  return (
    <div>
      <div className="cp-mb-24">
        <div className="cp-body-sm">Choose which columns to show on</div>
        <CpSelectSingle
          className="cp-mb-8"
          data={filterViews}
          placeholder="Select a filter view"
          onChange={setSelectedFilterView}
          value={selectedFilterView}
          searchFilter
          searchOnChange={setSearch}
          searchValue={search}
          triggerIsBlock
          transformData={(view) => ({
            ...view,
            icon: !!view.sharing_data?.length ? "person-people" : null,
          })}
        />
        {!!selectedFilterView.sharing_data?.length && (
          <div className="cp-body-sm">
            {readOnly ? (
              <i>This filter view has been shared with you. Only the owner of the filter view can make changes.</i>
            ) : (
              <i>
                You are sharing this filter with
                {!!numTeamMembersShared
                  ? ` ${numTeamMembersShared} team member${numTeamMembersShared > 1 ? "s" : ""}`
                  : ""}
                {!!numTeamMembersShared && !!numTeamsShared ? " and " : ""}
                {!!numTeamsShared ? ` ${numTeamsShared} team${numTeamsShared > 1 ? "s" : ""}` : ""}. Any changes made
                will be updated for those team members on their next login.
              </i>
            )}
          </div>
        )}
        {selectedFilterView.created_by === "__DEFAULT" ? (
          <div className="cp-body-sm">
            <i>
              Changes made to a default filter view will not be saved if the page is refreshed. To save any column
              changes or filters you will need to create a new filter view.
            </i>
          </div>
        ) : (
          !readOnly && (
            <div className="cp-body-sm cp-mt-8">
              <i>Changes made to columns will be updated automatically.</i>
            </div>
          )
        )}
      </div>
      <div className="flex flex-col cp-gap-24">
        {visibleSections.map((section) => (
          <ColumnsSection
            key={section.id}
            title={section.title}
            columns={columnsBySection[section.id]}
            visibleColumns={visibleColumnsBySection[section.id]}
            readOnly={readOnly}
            onColumnVisibilityChange={(visibleColumnsForSection) => {
              if (!newVisibleColumnsBySection.current) {
                newVisibleColumnsBySection.current = visibleColumnsBySection;
              }

              const newVisibleColumns = new Set(visibleColumnsForSection);
              forOwn(newVisibleColumnsBySection.current, (columns, sectionId) => {
                if (sectionId !== section.id) {
                  columns.forEach((col) => {
                    newVisibleColumns.add(col.id);
                  });
                }
              });
              newVisibleColumnsBySection.current[section.id] = columnsBySection[section.id].filter((c) =>
                newVisibleColumns.has(c.id)
              );

              debouncedUpdateVisibleColumns({
                selectedFilterView,
                filterViewOverrides,
                defaultVisibleColumns,
                queryClient,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}
