export const conditionOpts = {
  string: [
    {
      id: "contains",
      name: "Contains",
    },
    {
      id: "include_empty",
      name: "Show all blanks",
    },
    {
      id: "starts_with",
      name: "Starts with",
    },
  ],
  number: [
    {
      id: "equal_to",
      name: "Equal to",
    },
    {
      id: "greater_than",
      name: "Greater than",
    },
    {
      id: "less_than",
      name: "Less than",
    },
    {
      id: "between",
      name: "Between",
    },
    {
      id: "include_empty",
      name: "Show all blanks",
    },
  ],
};

export const formFieldNames = {
  selectOne: "filterOneType",
  inputOne: "valueOne",
  betweenInputOne: "betweenValueOne",
  betweenInputTwo: "betweenValueTwo",
  radio: "operator",
  selectTwo: "filterTwoType",
  inputTwo: "valueTwo",
};

export function getFilterValues(prevFilter = {}, type) {
  const inputValues = {
    operator: prevFilter?.operator || null,
  };

  conditionOpts[type].forEach((condition) => {
    let conditionValue = prevFilter[condition.id];
    if (!conditionValue) return;

    if (condition.id === "include_empty") {
      conditionValue = [""];
    }

    conditionValue?.forEach((val) => {
      if (!inputValues.selectOne) {
        inputValues.selectOne = condition;
        if (condition.id === "between") {
          inputValues.inputOne = val[0];
          inputValues.betweenInputOne = val[1];
        } else {
          inputValues.inputOne = val;
        }
      } else if (!inputValues.selectTwo) {
        inputValues.selectTwo = condition;
        if (condition.id === "between") {
          inputValues.inputTwo = val[0];
          inputValues.betweenInputTwo = val[1];
        } else {
          inputValues.inputTwo = val;
        }
      }
    });
  });
  if (!inputValues.operator) delete inputValues.operator;
  return inputValues;
}
