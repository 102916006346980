import { resolution_actions, lookUpColumnLabel, lookUpColumnDataType } from "../dedupe-modal.helper.js";

export function mergeContacts(conflictingContacts = [], mergeChoices = {}) {
  const mergedContact = conflictingContacts.reduce((prev, curr) => {
    return { ...prev, ...curr };
  });
  const resolvedContact = { ...mergedContact, ...mergeChoices, action: resolution_actions.merge };

  delete resolvedContact.conflicting_columns;
  delete resolvedContact.matching_columns;
  delete resolvedContact.conflicting_canopy_contacts;

  return resolvedContact;
}

export function determineConflicts(duplicateSet, conflictingColumns, crmColumns) {
  const conflicts = conflictingColumns.map((conflictingColumn) => {
    return {
      name: conflictingColumn,
      label: lookUpColumnLabel(crmColumns, conflictingColumn),
      values: duplicateSet.map((duplicate) => duplicate[conflictingColumn]),
      dataType: lookUpColumnDataType(crmColumns, conflictingColumn),
    };
  });

  return conflicts;
}
