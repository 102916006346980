import { fetchAsObservable, fetchWithSharedCache } from "fetcher!sofe";

function getTemplatesParams({ search, exactMatch, type, limit, page }) {
  const urlParams = new URLSearchParams();
  urlParams.append("search", search || "");
  urlParams.append("exact_match", exactMatch || false);
  urlParams.append("type", type || "both");
  urlParams.append("limit", limit || 50);
  urlParams.append("page", page || 1);
  return urlParams;
}

export function getTemplates(opts) {
  const params = getTemplatesParams(opts);
  return fetchAsObservable(`api/templates/client_records?${params.toString()}`);
}

export function getTemplatesCached(buster, opts) {
  const params = getTemplatesParams(opts);
  return fetchWithSharedCache(`api/templates/client_records?${params.toString()}`, buster);
}

export function getTemplate(id) {
  return fetchAsObservable(`api/templates/client_records/${id}`);
}

export function deleteTemplate(id) {
  return fetchAsObservable(`api/templates/client_records/${id}`, {
    method: "DELETE",
  });
}

export function createTemplate(body) {
  return fetchAsObservable(`api/templates/client_records`, {
    method: "POST",
    body,
  });
}

export function updateTemplate(id, body) {
  return fetchAsObservable(`api/templates/client_records/${id}`, {
    method: "PUT",
    body,
  });
}
