import React from "react";
import { DateTime } from "luxon";
import ListCell from "./list-cell.component";

export default function CustomFieldCell({ column, client, columnId }) {
  const value = client?.custom_fields?.[column.customField.field_name];
  if (!value) return "—";

  switch (column.customField.field_type) {
    case "date":
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    case "multiselect":
      return <ListCell value={value.split(",")} />;
    default:
      return value;
  }
}
