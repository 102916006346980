import React from "react";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import useUserPrefsQuery from "src/client-list/use-user-prefs-query.hook";

export default function ClientNameCell({ client }) {
  const [user] = useWithUserAndTenant();
  const { userPrefs } = useUserPrefsQuery(user);

  return (
    <a href={`#/client/${client.id}`} onContextMenu={(e) => e.stopPropagation()}>
      {userPrefs?.contactNameDisplayBy === "last" && !!client.last_name
        ? `${[client.last_name, client.first_name].filter((val) => val).join(", ")}`
        : client.name}
    </a>
  );
}
