import { useState, useEffect, useMemo } from "react";
import { useInfiniteQuery } from "src/react-query";
import { catchSyncStacktrace } from "auto-trace";
import { getClients } from "./client-list.resource.js";

export default function useClientsQuery({
  page,
  limit,
  infiniteScroll,
  filters,
  sortData,
  search,
  visibleColumns,
  disabled,
}) {
  const [pageCount, setPageCount] = useState();
  const [totalClientsCount, setTotalClientsCount] = useState();

  // Order is not important for visible columns, so we can sort and flatten as a string to use as the cache key
  const visibleColumnsKey = useMemo(() => [...visibleColumns].sort().join(), [visibleColumns]);

  const queryKey = ["clients", page, limit, infiniteScroll, filters, sortData, search, visibleColumnsKey];
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, signal }) =>
      new Promise((resolve, reject) => {
        const pageToFetch = (infiniteScroll ? pageParam : page) || 1;
        const pageLimit = infiniteScroll ? 50 : limit;
        const sub = getClients({
          page: pageToFetch,
          limit: pageLimit,
          sort_data: sortData.filter((data) => !!data.sort),
          filters,
          search,
          visibleColumns,
        }).subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            catchSyncStacktrace(err);
            reject(err);
          }
        );
        signal?.addEventListener("abort", () => sub.unsubscribe());
      }),
    enabled: !disabled && !!limit && !!filters,
    // Cache for 5 minutes (ms). Do not cache infinite queries to prevent refetching all pages
    cacheTime: infiniteScroll ? 0 : 300000,
    // Stale time for 15 secs to prevent excessive re-fetching when quickly switching between tabs
    staleTime: 15000,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination;
      if (pagination.current_page < pagination.total_pages) {
        return pagination.current_page + 1;
      } else {
        return false;
      }
    },
  });

  useEffect(() => {
    if (query.data?.pages?.length > 0) {
      const latestPage = query.data.pages[query.data.pages.length - 1];
      setPageCount(latestPage.pagination.total_pages);
      setTotalClientsCount(latestPage.pagination.total_count);
    }
  }, [query.data]);

  const clients = useMemo(() => query.data?.pages?.flatMap((page) => page.contacts) || [], [query.data]);

  return {
    clientsQuery: query,
    clients,
    pageCount,
    totalClientsCount,
  };
}
