import { fetchAsObservable } from "fetcher!sofe";
import { pluck, map } from "rxjs/operators";
import { sortBy } from "lodash";

export function getCustomFields() {
  return fetchAsObservable("/api/custom_fields").pipe(
    pluck("custom_fields"),
    map((fields) => {
      return sortBy(fields, (field) => {
        return field.field_name.toLowerCase();
      });
    })
  );
}

export function createCustomField(mappedField) {
  const body = { ...mappedField };

  return fetchAsObservable(`api/custom_fields`, {
    method: "POST",
    body: body,
  });
}

export function checkCustomFieldAvailability(customFieldName) {
  return fetchAsObservable(`api/custom_fields?field_name=${customFieldName}`);
}

export function editCustomField(mappedField) {
  const body = { ...mappedField };
  // Only use PUT request for dropdowns, PATCH for text/date
  const requestMethod = mappedField.dropdown_values ? "PUT" : "PATCH";

  return fetchAsObservable(`api/custom_fields/${mappedField.field_id}`, {
    method: requestMethod,
    body: body,
  });
}

export function deleteCustomField(customFieldId) {
  return fetchAsObservable(`api/custom_fields/${customFieldId}`, {
    method: "DELETE",
  });
}
