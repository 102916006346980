import React, { useState } from "react";
import { catchError } from "auto-trace";
import { useQueryClient } from "src/react-query";
import { CpModal, CpButton, CpEmptyState, constants } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { useHasAccess, isContactLimitModel, useWithUserAndTenant } from "cp-client-auth!sofe";
import { getContactsUsage } from "src/resources/contacts.resource";
import { patchClients } from "src/client-list/client-list.resource";

export default function SubViewActionButton({ className, subView, filters, selection, deselectAll, search }) {
  const queryClient = useQueryClient();
  const { clientActionName } = subView;
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [clientUsage, setClientUsage] = useState({});
  const { clientLimit, clientsRemaining } = clientUsage;

  const hasArchiveAccess = useHasAccess("contacts_archive");
  const hasActivateAccess = useHasAccess("contacts_toggle_active");
  const [user, tenant] = useWithUserAndTenant();

  function onClick() {
    if (!isContactLimitModel(tenant?.contact_limit)) {
      updateClients();
    } else {
      getContactsUsage().subscribe((res) => {
        if (selection.totalSelected + res.used > res.allowed) {
          setClientUsage({
            clientLimit: res.allowed,
            clientsRemaining: res.allowed - res.used,
          });
          setShowLimitModal(true);
        } else {
          updateClients();
        }
      }, catchError());
    }
  }

  function updateClients() {
    if (clientActionName === "Activate") {
      const count = selection.totalSelected;
      patchClients({
        search,
        filters,
        action: "activate",
        [selection.type]: selection.toArray(),
      }).subscribe((res) => {
        successToast(`${count} client${count > 1 ? "s have" : " has"} been moved to the active client list.`);
        queryClient.invalidateQueries(["clients"]);
      }, catchError());
    } else if (clientActionName === "Unarchive") {
      const count = selection.totalSelected;
      patchClients({
        search,
        filters,
        action: "unarchive",
        [selection.type]: selection.toArray(),
      }).subscribe((res) => {
        successToast(`${count} client${count > 1 ? "s" : ""} unarchived and moved to the active client list`);
        queryClient.invalidateQueries(["clients"]);
      }, catchError());
    }
    deselectAll();
  }

  function onClose() {
    setShowLimitModal(false);
  }

  if (
    (clientActionName === "Activate" && !hasActivateAccess) ||
    (clientActionName === "Unarchive" && !hasArchiveAccess)
  ) {
    return null;
  }

  return (
    <>
      <CpButton btnType="flat" className={className} onClick={onClick}>
        {clientActionName} {selection.totalSelected} client{selection.totalSelected > 1 ? "s" : ""}
      </CpButton>
      <CpModal show={showLimitModal} onClose={onClose} className="cp-p-20">
        <div className="cp-p-8" style={{ position: "absolute", right: 0, top: 0 }}>
          <CpButton btnType="icon" aria-label="Close" icon="close-small" onClick={onClose} />
        </div>
        <CpEmptyState
          img="es_contacts"
          text="Time to upgrade"
          subText={`You only have ${clientsRemaining} of ${clientLimit} clients available. Please select only ${clientsRemaining} clients to ${clientActionName.toLowerCase()}. Call ${
            constants.canopyPhone
          } to upgrade.`}
          cta={
            <CpButton btnType="flat" anchor href="#/global-settings/account-management">
              View my subscription
            </CpButton>
          }
        />
      </CpModal>
    </>
  );
}
