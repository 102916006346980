import React, { useEffect, useMemo } from "react";
import styles from "./mld-filter.styles.css";
import { CpButton, CpInputField, CpSelectSingleField } from "canopy-styleguide!sofe";
import { conditionOpts } from "./mld-filter.helper";

export default function MLDFilterForm(props) {
  const {
    control,
    hideBlanksOption,
    inputFieldOneName,
    inputFieldTwoName,
    onRemove,
    resetField,
    selectFieldName,
    type,
    watch,
  } = props;

  function removeCondition() {
    resetField(selectFieldName, { defaultValue: null });
    resetField(inputFieldOneName, { defaultValue: "" });
    inputFieldTwoName && resetField(inputFieldTwoName, { defaultValue: "" });
    onRemove();
  }
  const watchSelectField = watch(selectFieldName);
  const isBetween = watchSelectField?.id === "between";

  const selectFieldOptions = useMemo(() => {
    const conditions = type === "string" ? conditionOpts.string : conditionOpts.number;
    return hideBlanksOption ? conditions.filter((c) => c.id !== "include_empty") : conditions;
  }, [type, hideBlanksOption]);

  useEffect(() => {
    if (watchSelectField?.id === "include_empty") {
      resetField(inputFieldOneName, { defaultValue: "" });
      resetField(inputFieldTwoName, { defaultValue: "" });
    }
  }, [watchSelectField]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  return (
    <div className="cp-mt-8">
      <label className="cp-body-sm">Select a condition</label>
      <div className="cp-flex">
        <CpSelectSingleField
          control={control}
          fieldName={selectFieldName}
          className={styles.selectField}
          data={selectFieldOptions}
          placeholder="Select condition"
          triggerIsBlock
          contentWidth="block"
        />
        {!!onRemove && (
          <CpButton
            aria-label="Remove condition"
            className="cp-ml-8"
            btnType="icon"
            icon="close-small"
            onClick={removeCondition}
            type="button"
          />
        )}
      </div>
      <div className={isBetween ? "cp-flex cp-pr-16" : ""}>
        <CpInputField
          control={control}
          fieldName={inputFieldOneName}
          disabled={!watchSelectField?.id || watchSelectField?.id === "include_empty"}
          placeholder={"Add value"}
          type={type === "string" ? "text" : "number"}
        />
        {isBetween && (
          <CpInputField
            className="cp-ml-16"
            control={control}
            fieldName={inputFieldTwoName}
            placeholder="Add value"
            type="number"
          />
        )}
      </div>
    </div>
  );
}
