import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CpArea, CpIcon } from "canopy-styleguide!sofe";
import styles from "./sort.styles.css";

export default function Sort({ setSortDir, sortDir, sortType, ascSortLabel, descSortLabel }) {
  const textBySortType = useMemo(() => {
    const text = {};
    switch (sortType) {
      case "number":
        text.ascending = "Lowest - Highest";
        text.descending = "Highest - Lowest";
        break;
      case "date":
        text.ascending = "Oldest - Newest";
        text.descending = "Newest - Oldest";
        break;
      case "string":
      default:
        text.ascending = "A-Z";
        text.descending = "Z-A";
    }

    if (ascSortLabel) text.ascending = ascSortLabel;
    if (descSortLabel) text.descending = descSortLabel;

    return text;
  }, [sortType, ascSortLabel, descSortLabel]);

  const isAsc = sortDir === "asc";
  const isDesc = sortDir === "desc";
  const setSortAsc = () => (isAsc ? setSortDir(null) : setSortDir("asc"));
  const setSortDesc = () => (isDesc ? setSortDir(null) : setSortDir("desc"));

  return (
    <div className={`cp-flex-column ${styles.sortContainer}`}>
      <CpArea className={`cp-mb-8 cp-body ${styles.row} ${isAsc && styles.row__selected}`} onClick={() => setSortAsc()}>
        <span>
          Sort <b>{textBySortType.ascending}</b>
        </span>
        {isAsc && <CpIcon name="checkmark-small" />}
      </CpArea>
      <CpArea
        className={`cp-mb-8 cp-body ${styles.row} ${isDesc && styles.row__selected}`}
        onClick={() => setSortDesc()}
      >
        <span>
          Sort <b>{textBySortType.descending}</b>
        </span>
        {isDesc && <CpIcon name="checkmark-small" />}
      </CpArea>
    </div>
  );
}

Sort.propTypes = {
  setSortDir: PropTypes.func.isRequired,
  sortDir: PropTypes.oneOf(["asc", "desc", null]),
  sortType: PropTypes.oneOf(["string", "number", "date"]).isRequired,
};
