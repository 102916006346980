import React, { useState } from "react";
import { CpArea, CpButton, CpIcon, CpModal, CpTooltip } from "canopy-styleguide!sofe";
import { a } from "kremling";
import styles from "./column-customization.styles.css";
import { useQueryClient } from "src/react-query";
import { catchError } from "auto-trace";
import { deleteFilterViews } from "../client-list.resource";
import CustomizeColumnsBody from "./customize-columns/customize-columns-body.component";
import EditFilterViewsBody from "./edit-filter-views/edit-filter-views-body.component";
import { track } from "src/resources/analytics.resource";

export default function ColumnCustomization({
  filterViews,
  filterView,
  filterViewOverrides,
  onColumnVisibilityChange,
}) {
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState("columns"); // options: ['columns', 'filter-views']
  const [filterViewsToDelete, setFilterViewsToDelete] = useState([]);
  const queryClient = useQueryClient();

  function closeModal() {
    if (filterViewsToDelete.length > 0) {
      deleteFilterViews(filterViewsToDelete).subscribe(() => {
        setFilterViewsToDelete([]);
        queryClient.invalidateQueries(["filter-views"]);
      }, catchError());
    }
    setShowModal(false);
  }

  return (
    <>
      <CpTooltip text="Customize client list">
        <CpButton
          aria-label="Customize client list"
          btnType="icon"
          className="cp-mr-8"
          icon="organize-boards"
          onClick={() => {
            setShowModal(true);
            track("client_list.column-customization-button-clicked");
          }}
        />
      </CpTooltip>
      <CpModal show={showModal} onClose={closeModal} width="832">
        <CpModal.Header title="Customize Client List" />
        <div className={styles.modalBody}>
          <div className={`cp-body ${styles.columnLeft}`}>
            <CpArea
              className={a(styles.columnLeftItem).m(styles.columnLeftItemSelected, tab === "columns")}
              onClick={() => setTab("columns")}
            >
              <CpIcon
                name="misc-table"
                className="cp-mr-12"
                fill={tab === "columns" ? "var(--cp-color-button-icon-active-text)" : "var(--cp-color-app-icon)"}
              />
              Customize columns
            </CpArea>
            <CpArea
              className={a(styles.columnLeftItem).m(styles.columnLeftItemSelected, tab === "filter-views")}
              onClick={() => setTab("filter-views")}
            >
              <CpIcon
                name="af-reorder"
                className="cp-mr-12"
                fill={tab === "filter-views" ? "var(--cp-color-button-icon-active-text)" : "var(--cp-color-app-icon)"}
              />
              Edit filter views
            </CpArea>
          </div>
          <div className={styles.columnRight}>
            {tab === "columns" && filterView && (
              <CustomizeColumnsBody
                filterViews={filterViews.filter((fv) => !filterViewsToDelete.includes(fv.id))}
                filterView={filterView}
                filterViewOverrides={filterViewOverrides}
                onColumnVisibilityChange={onColumnVisibilityChange}
              />
            )}
            {tab === "filter-views" && (
              <EditFilterViewsBody
                filterViews={filterViews}
                filterViewsToDelete={filterViewsToDelete}
                setFilterViewsToDelete={setFilterViewsToDelete}
              />
            )}
          </div>
        </div>
        <CpModal.Footer>
          <CpButton className="cp-mr-8" onClick={closeModal}>
            Done
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </>
  );
}
