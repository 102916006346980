import { isEqual, forOwn } from "lodash";

const filterConditionDefinitions = {
  is_any_of: {
    explicit_match: false,
    include_empty: false,
    select_all: false,
    values: true,
  },
  is_all_of: {
    explicit_match: true,
    include_empty: false,
    select_all: false,
    values: true,
  },
  is_not_any_of: {
    explicit_match: false,
    include_empty: false,
    select_all: true,
    values: true,
  },
  is_blank: {
    explicit_match: false,
    include_empty: true,
    select_all: false,
    values: false,
  },
  has_any_value: {
    explicit_match: false,
    include_empty: false,
    select_all: true,
    values: false,
  },
};

export const conditionOpts = [
  {
    id: "is_any_of",
    name: "Is any of",
    buildFilter: (values) => ({
      ...filterConditionDefinitions.is_any_of,
      values,
    }),
    validateHasValues: true,
  },
  {
    id: "is_all_of",
    name: "Is all of",
    buildFilter: (values) => ({
      ...filterConditionDefinitions.is_all_of,
      values,
    }),
    validateHasValues: true,
  },
  {
    id: "is_not_any_of",
    name: "Is not any of",
    buildFilter: (values) => ({
      ...filterConditionDefinitions.is_not_any_of,
      values,
    }),
    validateHasValues: true,
  },
  {
    id: "is_blank",
    name: "Is blank",
    buildFilter: () => {
      const filter = filterConditionDefinitions.is_blank;
      delete filter.values;
      return filter;
    },
    validateHasValues: false,
  },
  {
    id: "has_any_value",
    name: "Has any value",
    buildFilter: () => {
      const filter = filterConditionDefinitions.has_any_value;
      delete filter.values;
      return filter;
    },
    validateHasValues: false,
  },
];

export const formFieldNames = {
  selectOne: "filterOneType",
  inputOne: "valueOne",
  selectTwo: "filterTwoType",
  inputTwo: "valueTwo",
};

function getConditionFromFilter(filter) {
  let conditionName;
  const { field_type, ...remainder } = filter;
  if (remainder.values) remainder.values = true;
  forOwn(filterConditionDefinitions, (value, key) => {
    if (isEqual(value, remainder)) {
      conditionName = key;
      return false;
    }
  });
  const condition = conditionOpts.find((c) => c.id === conditionName);
  return condition;
}

export function getFilterValues(filters = []) {
  if (!filters.length) return;

  const translatedFilters = [];
  filters.forEach((filter) => {
    translatedFilters.push({
      condition: getConditionFromFilter(filter),
      values: filter.values,
    });
  });

  return translatedFilters;
}
