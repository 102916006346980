// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-modal-upgrade-contacts-modal-upgrade-contacts-card-styles__card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.src-modal-upgrade-contacts-modal-upgrade-contacts-card-styles__icon {\n  width: 9.6rem;\n  height: 9.6rem;\n  margin-bottom: 2.4rem;\n}\n\n.src-modal-upgrade-contacts-modal-upgrade-contacts-card-styles__text {\n  margin-top: 0.6rem;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "src-modal-upgrade-contacts-modal-upgrade-contacts-card-styles__card",
	"icon": "src-modal-upgrade-contacts-modal-upgrade-contacts-card-styles__icon",
	"text": "src-modal-upgrade-contacts-modal-upgrade-contacts-card-styles__text"
};
module.exports = exports;
