import React from "react";
import { DateTime } from "luxon";
import { find, isEmpty, isString } from "lodash";

/**
 * Formats a 10-digit US phone number. Handles explicit extensions in the form of 'number x extension'
 *
 * @param phoneNumber
 * @param USonly: If true, assume digits past 10 are part of an extension. If false, assume >10 digit numbers are international, and do not attempt to format them.
 * @returns String
 *
 * Examples:
 *
 * formatPhoneNumber('8001234567') => '(800) 123-4567'
 * formatPhoneNumber('800 123 4567') => '(800) 123-4567'
 * formatPhoneNumber('18001234567') => '(800) 123-4567'
 * formatPhoneNumber(8001234567) => '(800) 123-4567'
 * formatPhoneNumber('800-123-4567') => '(800) 123-4567'
 * formatPhoneNumber('800123456789', true) => '(800) 123-4567 x 89'
 * formatPhoneNumber('800123456789') => '800123456789'
 * formatPhoneNumber('8001234567 x 89') => '(800) 123-4567 x 89'
 */
export function formatPhoneNumber(phoneNumber, USonly = false) {
  phoneNumber = phoneNumber ? phoneNumber.toString() : "";
  let extension = "";

  // Check for an explicit extension
  let extParts = phoneNumber.split("x");
  if (extParts.length === 2) {
    phoneNumber = extParts[0].trim();
    extension = extParts[1].trim();
  }

  // Remove all non-digits and whitespace
  let strippedNumber = phoneNumber.replace(/[\D\s]/g, "");

  if (strippedNumber.charAt(0) === "1") {
    strippedNumber = strippedNumber.substr(1);
  }

  if (USonly && strippedNumber.length > 10) {
    return (
      "(" +
      strippedNumber.substr(0, 3) +
      ") " +
      strippedNumber.substr(3, 3) +
      "-" +
      strippedNumber.substr(6, 4) +
      " x " +
      strippedNumber.substr(10)
    );
  } else if (strippedNumber.length === 10) {
    return (
      "(" +
      strippedNumber.substr(0, 3) +
      ") " +
      strippedNumber.substr(3, 3) +
      "-" +
      strippedNumber.substr(6, 4) +
      (extension.length > 0 ? " x " + extension : "")
    );
  }
  return phoneNumber + (extension.length > 0 ? " x " + extension : "");
}

export function formatAddress(address, multiline = true) {
  const country = find(countries, { abbreviation: address.country });

  let addressString = "";

  if (multiline) {
    if (address.address_1) addressString = address.address_1 + "\n";
    if (address.address_2) addressString += address.address_2 + "\n";
    if (address.locality) addressString += address.locality + "\n";
    if (address.region) addressString += address.region + "\n";
    if (address.postal_code) addressString += address.postal_code;
    if (address.country && address.country != "United States")
      addressString += "\n" + (country && country.name ? country.name : address.country);
  } else {
    let addressString = "";
    if (address.address_1) addressString = address.address_1 + ", ";
    if (address.address_2) addressString += address.address_2 + ", ";
    if (address.locality) addressString += address.locality + ", ";
    if (address.region) addressString += address.region + ", ";
    if (address.postal_code) addressString += address.postal_code;
    if (address.country && address.country != "United States")
      addressString += ", " + (country && country.name ? country.name : address.country);
  }
  return addressString;
}

export function formatSsn(value, opts = {}) {
  const mask = window.tenant.mask_ssn_ein || opts.mask || false;
  if (mask) {
    return `••• •• ••••`;
  }
  if (value && isString(value) && value.length === 9) {
    return value.substring(0, 3) + "-" + value.substring(3, 5) + "-" + value.substring(5, 9);
  }
  return value;
}

export function formatEin(value, opts = {}) {
  const mask = window.tenant.mask_ssn_ein || opts.mask || false;
  if (mask) {
    return `•• •••••••`;
  }
  if (value && isString(value) && value.length === 9) {
    return value.substring(0, 2) + "-" + value.substring(2, 9);
  }
  return value;
}

export function formatAddressJsx(address) {
  const addressString = formatAddress(address);
  return (
    <div>
      {addressString.split("\n").map((line, index) => (
        <div key={`contact-address-${index}`} className="cps-ellipsis">
          {line}
        </div>
      ))}
    </div>
  );
}

export function isValidEmail(email) {
  // From http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function formatDate(date, format) {
  return DateTime.fromMillis(date).toFormat(format);
}

export function buildSelectOptions(arr) {
  return arr.map((x) => ({ value: x, key: x }));
}

export function getInitials(contact) {
  return contact.initials || (contact.is_business ? getBusinessInitials(contact) : getIndividualInitials(contact));
}

function getBusinessInitials(contact) {
  return contact.business_name ? contact.business_name.charAt(0).toUpperCase() + contact.business_name.charAt(1) : "";
}

function getIndividualInitials(contact) {
  return contact.first_name && contact.last_name
    ? contact.first_name[0].toUpperCase() + contact.last_name[0].toUpperCase()
    : "";
}

export function ensurePrimary(items, field = "value") {
  const primary = items.find((item) => item.isPrimary);
  if (!primary || isEmpty(primary[field])) {
    const firstIndex = items.findIndex((item) => !isEmpty(item[field]));
    return firstIndex !== -1 ? items.map((item, i) => ({ ...item, isPrimary: firstIndex === i })) : items;
  } else {
    return items;
  }
}

// http://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = "fixed";
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = "2em";
  textArea.style.height = "2em";

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = "transparent";

  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  if (document.execCommand) {
    var successful = document.execCommand("copy");
    if (!successful) {
      throw new Error(`Not able to copy to clipboard. ${successful}`);
    }
  }

  document.body.removeChild(textArea);
}

export var countries = [
  { abbreviation: "US", name: "United States" },
  { abbreviation: "AF", name: "Afghanistan" },
  { abbreviation: "AL", name: "Albania" },
  { abbreviation: "DZ", name: "Algeria" },
  { abbreviation: "AS", name: "American Samoa" },
  { abbreviation: "AD", name: "Andorra" },
  { abbreviation: "AO", name: "Angola" },
  { abbreviation: "AI", name: "Anguilla" },
  { abbreviation: "AQ", name: "Antarctica" },
  { abbreviation: "AG", name: "Antigua And Barbuda" },
  { abbreviation: "AR", name: "Argentina" },
  { abbreviation: "AM", name: "Armenia" },
  { abbreviation: "AW", name: "Aruba" },
  { abbreviation: "AU", name: "Australia" },
  { abbreviation: "AT", name: "Austria" },
  { abbreviation: "AZ", name: "Azerbaijan" },
  { abbreviation: "BS", name: "Bahamas" },
  { abbreviation: "BH", name: "Bahrain" },
  { abbreviation: "BD", name: "Bangladesh" },
  { abbreviation: "BB", name: "Barbados" },
  { abbreviation: "BY", name: "Belarus" },
  { abbreviation: "BE", name: "Belgium" },
  { abbreviation: "BZ", name: "Belize" },
  { abbreviation: "BJ", name: "Benin" },
  { abbreviation: "BM", name: "Bermuda" },
  { abbreviation: "BT", name: "Bhutan" },
  { abbreviation: "BO", name: "Bolivia" },
  { abbreviation: "BA", name: "Bosnia And Herzegowina" },
  { abbreviation: "BW", name: "Botswana" },
  { abbreviation: "BV", name: "Bouvet Island" },
  { abbreviation: "BR", name: "Brazil" },
  { abbreviation: "IO", name: "British Indian Ocean Territory" },
  { abbreviation: "BN", name: "Brunei Darussalam" },
  { abbreviation: "BG", name: "Bulgaria" },
  { abbreviation: "BF", name: "Burkina Faso" },
  { abbreviation: "BI", name: "Burundi" },
  { abbreviation: "KH", name: "Cambodia" },
  { abbreviation: "CM", name: "Cameroon" },
  { abbreviation: "CA", name: "Canada" },
  { abbreviation: "CV", name: "Cape Verde" },
  { abbreviation: "KY", name: "Cayman Islands" },
  { abbreviation: "CF", name: "Central African Republic" },
  { abbreviation: "TD", name: "Chad" },
  { abbreviation: "CL", name: "Chile" },
  { abbreviation: "CN", name: "China" },
  { abbreviation: "CX", name: "Christmas Island" },
  { abbreviation: "CC", name: "Cocos (Keeling) Islands" },
  { abbreviation: "CO", name: "Colombia" },
  { abbreviation: "KM", name: "Comoros" },
  { abbreviation: "CG", name: "Congo" },
  { abbreviation: "CD", name: "Congo, The Democratic Republic Of The" },
  { abbreviation: "CK", name: "Cook Islands" },
  { abbreviation: "CR", name: "Costa Rica" },
  { abbreviation: "CI", name: "Cote D'Ivoire" },
  { abbreviation: "HR", name: "Croatia (Local Name: Hrvatska)" },
  { abbreviation: "CU", name: "Cuba" },
  { abbreviation: "CY", name: "Cyprus" },
  { abbreviation: "CZ", name: "Czech Republic" },
  { abbreviation: "DK", name: "Denmark" },
  { abbreviation: "DJ", name: "Djibouti" },
  { abbreviation: "DM", name: "Dominica" },
  { abbreviation: "DO", name: "Dominican Republic" },
  { abbreviation: "TP", name: "East Timor" },
  { abbreviation: "EC", name: "Ecuador" },
  { abbreviation: "EG", name: "Egypt" },
  { abbreviation: "SV", name: "El Salvador" },
  { abbreviation: "GQ", name: "Equatorial Guinea" },
  { abbreviation: "ER", name: "Eritrea" },
  { abbreviation: "EE", name: "Estonia" },
  { abbreviation: "ET", name: "Ethiopia" },
  { abbreviation: "FK", name: "Falkland Islands (Malvinas)" },
  { abbreviation: "FO", name: "Faroe Islands" },
  { abbreviation: "FJ", name: "Fiji" },
  { abbreviation: "FI", name: "Finland" },
  { abbreviation: "FR", name: "France" },
  { abbreviation: "FX", name: "France, Metropolitan" },
  { abbreviation: "GF", name: "French Guiana" },
  { abbreviation: "PF", name: "French Polynesia" },
  { abbreviation: "TF", name: "French Southern Territories" },
  { abbreviation: "GA", name: "Gabon" },
  { abbreviation: "GM", name: "Gambia" },
  { abbreviation: "GE", name: "Georgia" },
  { abbreviation: "DE", name: "Germany" },
  { abbreviation: "GH", name: "Ghana" },
  { abbreviation: "GI", name: "Gibraltar" },
  { abbreviation: "GR", name: "Greece" },
  { abbreviation: "GL", name: "Greenland" },
  { abbreviation: "GD", name: "Grenada" },
  { abbreviation: "GP", name: "Guadeloupe" },
  { abbreviation: "GU", name: "Guam" },
  { abbreviation: "GT", name: "Guatemala" },
  { abbreviation: "GN", name: "Guinea" },
  { abbreviation: "GW", name: "Guinea-Bissau" },
  { abbreviation: "GY", name: "Guyana" },
  { abbreviation: "HT", name: "Haiti" },
  { abbreviation: "HM", name: "Heard And Mc Donald Islands" },
  { abbreviation: "VA", name: "Holy See (Vatican City State)" },
  { abbreviation: "HN", name: "Honduras" },
  { abbreviation: "HK", name: "Hong Kong" },
  { abbreviation: "HU", name: "Hungary" },
  { abbreviation: "IS", name: "Iceland" },
  { abbreviation: "IN", name: "India" },
  { abbreviation: "ID", name: "Indonesia" },
  { abbreviation: "IR", name: "Iran (Islamic Republic Of)" },
  { abbreviation: "IQ", name: "Iraq" },
  { abbreviation: "IE", name: "Ireland" },
  { abbreviation: "IL", name: "Israel" },
  { abbreviation: "IT", name: "Italy" },
  { abbreviation: "CI", name: "Ivory Coast" },
  { abbreviation: "JM", name: "Jamaica" },
  { abbreviation: "JP", name: "Japan" },
  { abbreviation: "JO", name: "Jordan" },
  { abbreviation: "KZ", name: "Kazakhstan" },
  { abbreviation: "KE", name: "Kenya" },
  { abbreviation: "KI", name: "Kiribati" },
  { abbreviation: "KP", name: "Korea, Democratic People'S Republic Of" },
  { abbreviation: "KR", name: "Korea, Republic Of" },
  { abbreviation: "XK", name: "Kosovo" },
  { abbreviation: "KW", name: "Kuwait" },
  { abbreviation: "KG", name: "Kyrgyzstan" },
  { abbreviation: "LA", name: "Lao People'S Democratic Republic" },
  { abbreviation: "LV", name: "Latvia" },
  { abbreviation: "LB", name: "Lebanon" },
  { abbreviation: "LS", name: "Lesotho" },
  { abbreviation: "LR", name: "Liberia" },
  { abbreviation: "LY", name: "Libyan Arab Jamahiriya" },
  { abbreviation: "LI", name: "Liechtenstein" },
  { abbreviation: "LT", name: "Lithuania" },
  { abbreviation: "LU", name: "Luxembourg" },
  { abbreviation: "MO", name: "Macau" },
  { abbreviation: "MK", name: "Macedonia, Former Yugoslav Republic Of" },
  { abbreviation: "MG", name: "Madagascar" },
  { abbreviation: "MW", name: "Malawi" },
  { abbreviation: "MY", name: "Malaysia" },
  { abbreviation: "MV", name: "Maldives" },
  { abbreviation: "ML", name: "Mali" },
  { abbreviation: "MT", name: "Malta" },
  { abbreviation: "MH", name: "Marshall Islands" },
  { abbreviation: "MQ", name: "Martinique" },
  { abbreviation: "MR", name: "Mauritania" },
  { abbreviation: "MU", name: "Mauritius" },
  { abbreviation: "YT", name: "Mayotte" },
  { abbreviation: "MX", name: "Mexico" },
  { abbreviation: "FM", name: "Micronesia, Federated States Of" },
  { abbreviation: "MD", name: "Moldova, Republic Of" },
  { abbreviation: "MC", name: "Monaco" },
  { abbreviation: "MN", name: "Mongolia" },
  { abbreviation: "MS", name: "Montserrat" },
  { abbreviation: "MA", name: "Morocco" },
  { abbreviation: "MZ", name: "Mozambique" },
  { abbreviation: "MM", name: "Myanmar" },
  { abbreviation: "NA", name: "Namibia" },
  { abbreviation: "NR", name: "Nauru" },
  { abbreviation: "NP", name: "Nepal" },
  { abbreviation: "NL", name: "Netherlands" },
  { abbreviation: "AN", name: "Netherlands Antilles" },
  { abbreviation: "NC", name: "New Caledonia" },
  { abbreviation: "NZ", name: "New Zealand" },
  { abbreviation: "NI", name: "Nicaragua" },
  { abbreviation: "NE", name: "Niger" },
  { abbreviation: "NG", name: "Nigeria" },
  { abbreviation: "NU", name: "Niue" },
  { abbreviation: "NF", name: "Norfolk Island" },
  { abbreviation: "MP", name: "Northern Mariana Islands" },
  { abbreviation: "NO", name: "Norway" },
  { abbreviation: "OM", name: "Oman" },
  { abbreviation: "PK", name: "Pakistan" },
  { abbreviation: "PW", name: "Palau" },
  { abbreviation: "PA", name: "Panama" },
  { abbreviation: "PG", name: "Papua New Guinea" },
  { abbreviation: "PY", name: "Paraguay" },
  { abbreviation: "PE", name: "Peru" },
  { abbreviation: "PH", name: "Philippines" },
  { abbreviation: "PN", name: "Pitcairn" },
  { abbreviation: "PL", name: "Poland" },
  { abbreviation: "PT", name: "Portugal" },
  { abbreviation: "PR", name: "Puerto Rico" },
  { abbreviation: "QA", name: "Qatar" },
  { abbreviation: "RE", name: "Reunion" },
  { abbreviation: "RO", name: "Romania" },
  { abbreviation: "RU", name: "Russian Federation" },
  { abbreviation: "RW", name: "Rwanda" },
  { abbreviation: "KN", name: "Saint Kitts And Nevis" },
  { abbreviation: "LC", name: "Saint Lucia" },
  { abbreviation: "VC", name: "Saint Vincent And The Grenadines" },
  { abbreviation: "WS", name: "Samoa" },
  { abbreviation: "SM", name: "San Marino" },
  { abbreviation: "ST", name: "Sao Tome And Principe" },
  { abbreviation: "SA", name: "Saudi Arabia" },
  { abbreviation: "SN", name: "Senegal" },
  { abbreviation: "RS", name: "Serbia" },
  { abbreviation: "SC", name: "Seychelles" },
  { abbreviation: "SL", name: "Sierra Leone" },
  { abbreviation: "SG", name: "Singapore" },
  { abbreviation: "SK", name: "Slovakia (Slovak Republic)" },
  { abbreviation: "SI", name: "Slovenia" },
  { abbreviation: "SB", name: "Solomon Islands" },
  { abbreviation: "SO", name: "Somalia" },
  { abbreviation: "ZA", name: "South Africa" },
  { abbreviation: "GS", name: "South Georgia, South Sandwich Islands" },
  { abbreviation: "ES", name: "Spain" },
  { abbreviation: "LK", name: "Sri Lanka" },
  { abbreviation: "SH", name: "St. Helena" },
  { abbreviation: "PM", name: "St. Pierre And Miquelon" },
  { abbreviation: "SD", name: "Sudan" },
  { abbreviation: "SR", name: "Suriname" },
  { abbreviation: "SJ", name: "Svalbard And Jan Mayen Islands" },
  { abbreviation: "SZ", name: "Swaziland" },
  { abbreviation: "SE", name: "Sweden" },
  { abbreviation: "CH", name: "Switzerland" },
  { abbreviation: "SY", name: "Syrian Arab Republic" },
  { abbreviation: "TW", name: "Taiwan" },
  { abbreviation: "TJ", name: "Tajikistan" },
  { abbreviation: "TZ", name: "Tanzania, United Republic Of" },
  { abbreviation: "TH", name: "Thailand" },
  { abbreviation: "TG", name: "Togo" },
  { abbreviation: "TK", name: "Tokelau" },
  { abbreviation: "TO", name: "Tonga" },
  { abbreviation: "TT", name: "Trinidad And Tobago" },
  { abbreviation: "TN", name: "Tunisia" },
  { abbreviation: "TR", name: "Turkey" },
  { abbreviation: "TM", name: "Turkmenistan" },
  { abbreviation: "TC", name: "Turks And Caicos Islands" },
  { abbreviation: "TV", name: "Tuvalu" },
  { abbreviation: "UG", name: "Uganda" },
  { abbreviation: "UA", name: "Ukraine" },
  { abbreviation: "AE", name: "United Arab Emirates" },
  { abbreviation: "GB", name: "United Kingdom" },
  { abbreviation: "UM", name: "United States Minor Outlying Islands" },
  { abbreviation: "UY", name: "Uruguay" },
  { abbreviation: "UZ", name: "Uzbekistan" },
  { abbreviation: "VU", name: "Vanuatu" },
  { abbreviation: "VE", name: "Venezuela" },
  { abbreviation: "VN", name: "Viet Nam" },
  { abbreviation: "VG", name: "Virgin Islands (British)" },
  { abbreviation: "VI", name: "Virgin Islands (U.S.)" },
  { abbreviation: "WF", name: "Wallis And Futuna Islands" },
  { abbreviation: "EH", name: "Western Sahara" },
  { abbreviation: "YE", name: "Yemen" },
  { abbreviation: "YU", name: "Yugoslavia" },
  { abbreviation: "ZM", name: "Zambia" },
  { abbreviation: "ZW", name: "Zimbabwe" },
];

export const selectCountries = countries.map((country) => ({ value: country.name, key: country.name }));

export const businessTypes = [
  "C-Corporation",
  "S-Corporation",
  "LLC",
  "LLC - M",
  "LLC - S",
  "Partnership",
  "Sole Proprietorship",
  "Estate",
  "Trust",
  "Non-Profit",
  "Other",
];

// Creates a temp anchor tag with an href containing the file content, and then clicks the tag resulting in a download.
export function downloadText(fileName, text, mimeType = "text/plain") {
  // Apparently there's no other way to do this client-side. So we're limited by URL constraints I believe. If this becomes a problem then BE will need to create the file and give us a link
  const el = document.createElement("a");
  const file = new Blob([text], { type: mimeType });
  el.href = URL.createObjectURL(file);
  el.download = fileName;
  el.click();
}

export function mapIdsToItems(itemIds, dbItems) {
  const out = [];
  itemIds?.forEach((itemId) => {
    const dbItem = dbItems?.find((item) => item.id === itemId);
    if (dbItem) out.push(dbItem);
  });
  return out;
}
