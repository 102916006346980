import React from "react";
import styles from "./invite-client-modal.styles.css";
import { catchAsyncStacktrace, catchSyncStacktrace } from "auto-trace";
import { successToast, warningToast } from "toast-service!sofe";
import { forceBustCache } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";
import { forkJoin } from "rxjs";
import { CprLoader, CpModal } from "canopy-styleguide!sofe";
import ModalHeader from "./modal-header.component.js";
import AddInvites from "./add-invites.component.js";
import { getContact } from "src/resources/contacts.resource.js";
import { postUser, getUsers } from "src/resources/users.resource.js";
import { postClientUsers } from "src/resources/client-users.resource.js";
import { flatten, omit, partial } from "lodash";
import { getExistingUser, groupNewAndExistingUsers, getSuggestedInvites } from "./invite-client-modal.helpers.js";
import Cancelable from "react-disposable-decorator";
import { CancelClientInviteModal } from "./cancel-client-invite-modal.component.js";
import NotificationMessage from "src/common/notification-message.component.js";

@Cancelable
export default class InviteClientModal extends React.Component {
  constructor(props) {
    super(props);
    this.defaultMessage = "";
    this.notificationMessage = {};

    this.defaultSurveyNotification = this.props.defaultNotification || {
      header: "Welcome! This is going to be easy!",
      body: "We want you to have the best possible experience as you work with us for your tax needs. With that in mind we've prepared this survey to simplify and streamline the gathering of important information that we will need from you. This survey paints a full picture of your financial and tax scenario for us. It's easy to fill out, and we're always happy to answer any questions you might have.",
      closing: `Regards,\n${window.loggedInUser.name}`,
    };

    this.state = {
      // Array of emails to invite, including any checked suggested invites or new invites
      invites: [],
      // Array of newly added emails to invite
      newInvites: [],
      showCancelInviteModal: false,
    };
    this.hasHeaderAndClosing = this.props.surveyNotification;
  }

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      getContact(this.props.contactId, "users,contacts").subscribe((contact) => {
        this.setState({
          contact,
        });
      }, catchAsyncStacktrace())
    );

    this.props.cancelWhenUnmounted(
      getUsers(true).subscribe((users) => {
        this.setState({
          allUsers: users,
        });
      }, catchAsyncStacktrace())
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.contact || prevState.contact.id != this.props.contactId) {
      this.props.cancelWhenUnmounted(
        getContact(this.props.contactId, "users,contacts", true).subscribe((contact) => {
          this.setState({
            contact,
          });
        }, catchAsyncStacktrace())
      );
    }
    if (prevState.invites.length !== this.state.invites.length) {
      this.props.newUsersToAdd && this.state.invites.length === 0 && this.setState({ showCancelInviteModal: true });
    }
  }

  renderSurveyOrNotification = () => {
    if (this.props.surveyNotification) {
      return (
        <div className={styles.notificationMessageContainer}>
          <NotificationMessage
            // we include a header and closing on Surveys
            setupFunction={this.setupNotificationMessage}
            defaultMessage={this.defaultSurveyNotification.body}
            header={this.defaultSurveyNotification.header}
            closing={this.defaultSurveyNotification.closing}
            hasHeader={this.hasHeaderAndClosing}
            hasClosing={this.hasHeaderAndClosing}
          />
        </div>
      );
    }

    if (!this.props.dontSendNotification) {
      return (
        <div className={styles.notificationMessageContainer}>
          <NotificationMessage
            defaultMessage={this.props.defaultNotification?.body}
            header={this.props.defaultNotification?.header}
            closing={this.props.defaultNotification?.closing}
            setupFunction={this.setupNotificationMessage}
          />
        </div>
      );
    }
  };

  handleClose = (cancelModal) => {
    // onSubmit is passed to this modal only when rendered from modalService
    this.props?.onSubmit?.(cancelModal);
    this.props.closeModal(cancelModal);
  };

  render() {
    if (!this.state.contact) {
      return (
        <div className={styles.loaderContainer}>
          <CprLoader page="true" />
        </div>
      );
    }

    let suggestedInvites = getSuggestedInvites(this.state.contact).filter(
      (invite) =>
        // Remove suggestions that are already invited
        !this.state.contact.users.find((usr) => usr.email.toLowerCase() === invite.email.toLowerCase())
    );

    if (this.props.includeExistingUsers) {
      // Put existing users at the beginning of suggested
      const existingUserInvites = this.state.contact.users
        .filter((user) => user.role === "Client")
        .map((user) => ({
          email: user.email,
          name: user.name,
        }));
      suggestedInvites = [...existingUserInvites, ...suggestedInvites];
    }

    return (
      <>
        <CpModal show onClose={this.handleClose} width={700}>
          <ModalHeader closeModal={this.handleClose} />
          <div className={`cps-card__body ${styles.scrollableArea}`}>
            <div className={`cps-body cps-padding-8 cps-margin-bottom-16 cps-margin-left-16`}>
              {this.props.customMessage ? (
                this.props.customMessage
              ) : (
                <div className={"cps-body"}>
                  Invite {this.state.contact.first_name || this.state.contact.name} to a unique and secure portal
                  experience.
                  {suggestedInvites && suggestedInvites.length ? " Here's a list of suggested people to invite." : ""}
                  &nbsp;You can also invite anyone else who should have access.
                </div>
              )}
            </div>
            <AddInvites
              allInvites={[...suggestedInvites, ...this.state.newInvites]}
              addNewInvite={this.addNewInvite.bind(this)}
              newUsersToAdd={this.props.newUsersToAdd}
              getExistingUser={partial(getExistingUser, this.state.allUsers)}
              invites={this.state.invites}
              updateInvites={this.updateInvites.bind(this)}
            />
            {this.renderSurveyOrNotification()}
          </div>
          <div className={`cps-modal__dialog__actions ${styles.actionButtons}`}>
            <button
              onClick={this.sendInvites.bind(this)}
              className={`cps-btn +primary ${styles.actionButtonSend}`}
              disabled={this.state.invites.length === 0 || this.state.sendingInvites}
            >
              Send
            </button>
            <a onClick={this.handleClose} className={`cps-link`}>
              {this.props.closeButtonText}
            </a>
          </div>
        </CpModal>
        <CancelClientInviteModal
          inviteSuccessCallback={this.props.inviteSuccessCallback}
          setShowCancelInviteModal={this.closeShowCancelInviteModal.bind(this)}
          showModal={this.state.showCancelInviteModal}
          handleClose={this.handleClose}
        />
      </>
    );
  }

  closeShowCancelInviteModal(val) {
    this.setState({ showCancelInviteModal: val });
  }

  sendInvites() {
    this.setState({
      sendingInvites: true,
    });

    const userInvites = this.state.invites.map((invite) => ({
      clients: [this.state.contact.id],
      email: invite.email,
      first_name: invite.first_name,
      last_name: invite.last_name,
      permissions: { superuser: false },
      role: "Client",
    }));
    const { newUsers, existingUserIds } = groupNewAndExistingUsers(userInvites, this.state.allUsers);

    let requests =
      newUsers.length > 0 ? [...newUsers.map((user) => postUser(user, getNotifications.call(this, true)))] : [];
    if (existingUserIds.length > 0)
      requests = [
        ...requests,
        postClientUsers(this.state.contact.id, existingUserIds, getNotifications.call(this, false)),
      ];

    forkJoin(...requests).subscribe(
      (users) => {
        if (!this.props.dontSendNotification) successToast("Successfully sent invitations.");
        if (this.props.inviteSuccessCallback && typeof this.props.inviteSuccessCallback === "function") {
          const surveyNotification = {
            header: this.notificationMessage.header,
            closing: this.notificationMessage.closing,
            body: this.notificationMessage.message,
          };
          this.props.inviteSuccessCallback(flatten(users), surveyNotification);
        }
        forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${this.state.contact.id}`);
        this.handleClose(false); // (cancelModal=false) not a cancel but a complete
      },
      (err) => {
        if (err.status === 409) {
          warningToast(
            `The email "${err.requestBody.users.email}" is already in use by another account. Please enter a different email or contact support if you believe this is in error.`
          );
          this.setState({
            sendingInvites: false,
          });
        } else {
          catchSyncStacktrace(err);
        }
      }
    );

    function getNotifications(isNewUser) {
      if (this.props.dontSendNotification) {
        return {};
      }

      const includeMessage = this.notificationMessage.includeMessage;
      const message = includeMessage ? this.notificationMessage.message || this.defaultMessage : this.defaultMessage;
      const url = `${window.location.origin}/m/clients/${this.state.contact.id}`;
      const customMergeTags = { CLIENT_FULL_NAME: this.state.contact.name };

      if (isNewUser) {
        return {
          message,
          url,
          customMergeTags,
        };
      } else {
        return {
          messages: {
            message,
          },
          url,
          customMergeTags,
          only_new_users: true,
        };
      }
    }
  }
  updateInvites(invites) {
    this.setState({
      invites,
    });
  }
  addNewInvite(invite) {
    this.setState({
      newInvites: [...this.state.newInvites, invite],
      invites: [...this.state.invites, invite],
    });
  }
  toggleIncludeMessage(includeMessage) {
    this.setState({
      includeMessage,
    });
  }

  setupNotificationMessage = (notificationMessage) => {
    this.notificationMessage = this.hasHeaderAndClosing
      ? notificationMessage
      : omit(notificationMessage, ["header", "closing"]);
  };
}
