// @flow
import * as React from "react";
// project
import { selectCountries } from "src/common/utils.helpers.js";
import { SimpleTypeahead } from "src/form/typeahead-input/typeahead-input.component.js";

type State = {
  states: Array<any>,
};

type Props = {
  onChange: Function,
  selected: string,
  cancelWhenUnmounted: Function,
};

export default class CountrySelect extends React.Component<Props, State> {
  static defaultProps = {
    cancelWhenUnmounted: () => {},
  };

  render() {
    const { selected, onChange, ...rest } = this.props;
    let found = selectCountries.find((country) => country.key === selected);
    return (
      <SimpleTypeahead
        itemToString={itemToString}
        debounceTime={0}
        itemKey={(item) => item.key}
        items={selectCountries}
        value={found}
        updateValue={(obj) => obj && onChange(obj.value)}
        clearSelectionOnEmptyInputBlur={false}
        emptySelectedItem={{}}
        {...rest}
      />
    );
  }
}

function itemToString(item) {
  return item ? item.value : "";
}
