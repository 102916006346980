import { useMemo } from "react";
import { catchSyncStacktrace } from "auto-trace";
import { getCustomFields } from "./client-list.resource.js";
import { useQuery } from "src/react-query";

export default function useCustomFieldsQuery({ individualOrBusiness = "both" } = {}) {
  const query = useQuery({
    queryKey: ["custom-fields"],
    queryFn: () =>
      new Promise((resolve, reject) => {
        getCustomFields().subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            catchSyncStacktrace(err);
            reject(err);
          }
        );
      }),
    staleTime: 10000, // 10 secs
  });

  const customFields = useMemo(() => {
    if (individualOrBusiness === "both") {
      return query.data;
    } else {
      return query.data?.filter((field) => {
        const filterKey = individualOrBusiness === "business" ? "businesses" : "individuals";
        return field.contact_type === filterKey || field.contact_type === "both";
      });
    }
  }, [query.data, individualOrBusiness]);

  return {
    customFieldsQuery: query,
    customFields,
  };
}
