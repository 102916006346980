// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__container {\n  background: var(--cp-color-well-l3-bg);\n  border-radius: 0.8rem;\n  width: 100%;\n  display: flex;\n  padding: 0.2rem;\n}\n\n.src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__button {\n  height: 4.4rem;\n  width: 27.3rem;\n}\n\n.src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__button.src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__active {\n  background: var(--cp-color-input-bg);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__container",
	"button": "src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__button",
	"active": "src-common-client-record-fields-client-business-toggle-client-business-toggle-styles__active"
};
module.exports = exports;
