// @flow
import React from "react";
// project
import Star from "../../star.component.js";
// sofe
import { CprButtonIcon } from "canopy-styleguide!sofe";

type Props = {
  starHtml: string,
  isPrimary: boolean,
  setPrimary: Function,
  remove: Function,
  trashHtml: string,
};

export default function Actions(props: Props) {
  return (
    <>
      <Star isPrimary={props.isPrimary} setPrimary={props.setPrimary} html={props.starHtml} />
      <CprButtonIcon
        onClick={props.remove}
        icon={"trash"}
        tooltip={props.trashHtml}
        customClass={"cps-color-monsoon"}
      />
    </>
  );
}
