import React from "react";
import { CpCheckbox } from "canopy-styleguide!sofe";

export default function SelectCell({ selection, client, toggleSelection }) {
  const isSelected = selection.byId[client.id] || false;
  return (
    <CpCheckbox
      checked={selection.type === "excludes" ? !isSelected : selection.allSelected || isSelected}
      onChange={() => toggleSelection(client.id)}
    />
  );
}
