import { concat, cloneDeep } from "lodash";
import { catchError, map, pluck } from "rxjs/operators";

import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable, forceBustCache, fetchWithSharedCache } from "fetcher!sofe";

import { checkForFailedFetch } from "./resource.helpers.js";
import { partial } from "lodash";

let pagesCache = [];

export function getContactNames(search, archivedOnly, includeInactive) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/users/0/contact-names?limit=20&name=${search}${
      archivedOnly ? "&archived_only=true" : ""
    }${includeInactive ? "&exclude_inactive_contacts=false" : ""}`
  ).pipe(pluck("contacts"), catchError(checkForFailedFetch));
}

export function getContacts(page, jql = defaultJql, limit = 100, sort = "name", asc = true) {
  if (!page) throw new Error("Cannot get contacts without being given a page");

  return fetchAsObservable(`
    ${canopyUrls.getWorkflowUrl()}/api/contacts?jql=${encodeURIComponent(
    JSON.stringify(jql)
  )}&page=${page}&limit=${limit}&sort=${!asc ? "-" : ""}${sort}
  `).pipe(
    map((resp) => {
      const contacts = resp.contacts;
      pagesCache[page - 1] = contacts;
      return {
        ...resp,
        contacts: concat(...pagesCache.slice(0, page)),
      };
    }),
    catchError(checkForFailedFetch)
  );
}

export function searchContacts(name, limit = 5) {
  return fetchAsObservable(`
    ${canopyUrls.getWorkflowUrl()}/api/contacts?jql=${encodeURIComponent(
    JSON.stringify(buildQuery(name.toLowerCase()))
  )}&limit=${limit}&sort=name
  `).pipe(pluck("contacts"), catchError(checkForFailedFetch));
}

// Includes: a comma-separated list of includes. Available includes: users, contacts, tags, contact_for, contact_sources
export function getContact(id, includes, bustCache = false) {
  return fetchWithSharedCache(
    `api/clients/${id}${includes ? `?include=${includes}` : ""}`,
    partial(untilClientIdHasChanged, id),
    bustCache
  ).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

function untilClientIdHasChanged(clientId) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}

export function unarchiveContact(id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/contacts/${id}/unarchive`, {
    method: "POST",
  });
}

export function deleteContact(id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/contacts/${id}`, {
    method: "DELETE",
  }).pipe(pluck("contacts"), catchError(checkForFailedFetch));
}

export function patchContact(id, data) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/contacts/${id}?include=users,contacts,tags,contact_for,contact_sources`,
    {
      method: "PATCH",
      body: { contacts: data },
    }
  ).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function putContact(id, data) {
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/contacts/${id}?include=users,contacts,tags,contact_for,contact_sources`,
    {
      method: "PUT",
      body: { contacts: data },
    }
  ).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function postContact(data) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/contacts`, {
    method: "POST",
    body: { contacts: data },
  }).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function contactsBulkAction(action, ids) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/contacts?action=${action}`, {
    method: "PATCH",
    body: { contact_ids: ids },
  }).pipe(pluck("contacts"), catchError(checkForFailedFetch));
}

export function getContactFromCache(contactId, includes) {
  if (!contactId) {
    throw new Error(`Must provide contactId to getContactFromCache`);
  }
  const query = includes ? `?include=${includes}` : "";
  return fetchAsObservable(
    `${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}${query}`,
    untilContactIdChanges
  ).pipe(pluck("clients"), map(cloneDeep));

  function untilContactIdChanges() {
    const regex = new RegExp(`clients?/${contactId}`);
    return !regex.test(window.location.hash);
  }
}

export function bustContactCache(contactId) {
  return forceBustCache(`${canopyUrls.getWorkflowUrl()}/api/clients/${contactId}`);
}

export function buildCsvUrl() {
  return `${canopyUrls.getWorkflowUrl()}/api/contacts/csv?jql=${encodeURIComponent(JSON.stringify(defaultJql))}`;
}

export const defaultJql = [
  {
    field: "is_archived",
    operator: "eq",
    value: false,
  },
  {
    field: "is_hidden",
    operator: "eq",
    value: false,
  },
];

function buildQuery(term) {
  return [
    ...defaultJql,
    {
      OR: [
        {
          field: "person_name",
          operator: "beginswith",
          value: term,
        },
        {
          field: "business_name",
          operator: "beginswith",
          value: term,
        },
        {
          field: "last_name",
          operator: "beginswith",
          value: term,
        },
      ],
    },
  ];
}

export function getContactsUsage() {
  return fetchAsObservable(`api/contacts/reached_limit`);
}

// QBO
export function getIntegrations() {
  return fetchAsObservable(`/wg/accounting-integrations`).pipe(pluck("integrations"));
}

export function putRoleAssignments(clientId, body) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}
