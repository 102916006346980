import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { CpButton, CpCard } from "canopy-styleguide!sofe";
import SearchAndSelectFilterForm from "./search-and-select-filter-form.component";
import Sort from "../common/sort.component";
import { formFieldNames, getFilterValues, conditionOpts } from "./search-and-select-filter.helper";
import { FilterContext } from "../../client-list.component";

export default function SearchAndSelectFilter({ column, onClose = () => {} }) {
  const { sortable, filterField, sortField } = column;
  const { getFilter, setFilter, getSort, setSort } = useContext(FilterContext);
  const currentFilter = useMemo(() => getFilter(filterField), [getFilter, filterField]);
  const currentSortDir = useMemo(() => getSort(sortField)?.sort, [getSort, sortField]);
  const prevFilterValues = useMemo(() => getFilterValues(currentFilter, column), [currentFilter, column]);
  const [sortDir, setSortDir] = useState(currentSortDir || null);
  const [showConditionTwo, setShowConditionTwo] = useState(prevFilterValues?.length > 1 || false);

  const { control, getValues, handleSubmit, reset, resetField, setValue, setError, watch } = useForm({
    defaultValues: {
      [formFieldNames.inputOne]: prevFilterValues?.[0]?.values || [],
      [formFieldNames.inputTwo]: prevFilterValues?.[1]?.values || [],
      [formFieldNames.selectOne]: prevFilterValues?.[0]?.condition || conditionOpts[0] || null,
      [formFieldNames.selectTwo]: prevFilterValues?.[1]?.condition || null,
    },
  });

  function onApply() {
    setSort({ field: sortField, sort: sortDir });

    const { filterOneType, valueOne, filterTwoType, valueTwo } = getValues();

    if (
      (filterOneType?.validateHasValues || !filterOneType) &&
      !valueOne?.length &&
      !filterTwoType &&
      !valueTwo?.length
    ) {
      setFilter(filterField, [], column);
      onClose();
      return;
    }

    let hasError = false;
    const emptyInputError = { type: "empty_field", message: "Please add values" };
    if (filterOneType?.validateHasValues && !valueOne.length && filterTwoType?.validateHasValues) {
      setError(formFieldNames.inputOne, emptyInputError);
      hasError = true;
    }
    if (filterTwoType?.validateHasValues && !valueTwo.length) {
      setError(formFieldNames.inputTwo, emptyInputError);
      hasError = true;
    }
    if (hasError) return;

    const filterOne = filterOneType.buildFilter(valueOne.map((v) => v.value));
    const filterTwo = filterTwoType?.buildFilter(valueTwo.map((v) => v.value));
    const filters = [filterOne];
    !!filterTwo && filters.push(filterTwo);

    setFilter(filterField, filters, column);
    onClose();
  }
  function onCancel() {
    onClose();
  }
  function onClear() {
    reset({
      [formFieldNames.selectOne]: null,
      [formFieldNames.selectTwo]: null,
      [formFieldNames.inputOne]: [],
      [formFieldNames.inputTwo]: [],
    });
    setShowConditionTwo(false);
    setSortDir(null);
  }

  return (
    <CpCard>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onApply)();
        }}
      >
        <CpCard.Body>
          {column.sortable && (
            <>
              <Sort setSortDir={setSortDir} sortDir={sortDir} sortType="string" />
              <div className="cp-divider" />
            </>
          )}
          <SearchAndSelectFilterForm
            column={column}
            control={control}
            inputFieldName={formFieldNames.inputOne}
            resetField={resetField}
            selectFieldName={formFieldNames.selectOne}
            setValue={setValue}
            watch={watch}
          />
          {showConditionTwo ? (
            <div className="cp-mt-16">
              <div className="cp-wt-semibold">And</div>
              <SearchAndSelectFilterForm
                column={column}
                control={control}
                inputFieldName={formFieldNames.inputTwo}
                onRemove={() => {
                  resetField(formFieldNames.radio, { defaultValue: null });
                  setShowConditionTwo(false);
                }}
                resetField={resetField}
                selectFieldName={formFieldNames.selectTwo}
                setValue={setValue}
                watch={watch}
              />
            </div>
          ) : (
            <CpButton
              aria-label="Add condition"
              btnType="tertiary"
              className="cp-mt-16"
              icon="add-small"
              onClick={() => setShowConditionTwo(true)}
              type="button"
            >
              Add condition
            </CpButton>
          )}
        </CpCard.Body>
        <CpCard.Footer>
          <div className="cp-flex-spread">
            <div className="cp-flex">
              <CpButton aria-label="Apply" btnType="primary" className="cp-mr-8" type="submit">
                Apply
              </CpButton>
              <CpButton aria-label="Cancel" btnType="flat" onClick={onCancel} type="button">
                Cancel
              </CpButton>
            </div>
            <CpButton aria-label="Reset" btnType="flat" onClick={onClear} type="button">
              Clear
            </CpButton>
          </div>
        </CpCard.Footer>
      </form>
    </CpCard>
  );
}

SearchAndSelectFilter.propTypes = {
  column: PropTypes.object,
  onClose: PropTypes.func,
};
