export function buildFixedColumnArray(duplicateSet) {
  let fixedColumnArray = [];

  for (let i = 0, len = duplicateSet.length; i < len; i++) {
    let fixedColumnElement = {
      name: getNameFromRowItem(duplicateSet[i]),
      isCanopy: !!duplicateSet[i].id,
      importRowId: duplicateSet[i].row_id,
    };

    fixedColumnArray.push(fixedColumnElement);
  }

  return fixedColumnArray;
}

export function getNameFromRowItem(rowItem) {
  return rowItem.is_business ? rowItem.business_name : `${rowItem.first_name} ${rowItem.last_name}`;
}
