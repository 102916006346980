import React, { useContext, useEffect, useState } from "react";
import { CpButton, CpCard, CpCheckboxGroup } from "canopy-styleguide!sofe";
import Sort from "../common/sort.component";
import { FilterContext } from "../../client-list.component";
import styles from "./bool-filter.styles.css";

export default function BoolFilter({ onClose, column }) {
  const { filterField, sortField, label: columnName } = column;

  const { ascSortLabel, descSortLabel, trueValueLabel, falseValueLabel } = column.boolFilterOpts || {};

  const { getFilter, setFilter, getSort, setSort } = useContext(FilterContext);
  const prevFilter = getFilter(filterField)?.[0];
  const prevSortDir = getSort(sortField)?.sort;

  const [checkboxGroup, setCheckboxGroup] = useState([]);
  const [sortDir, setSortDir] = useState(prevSortDir || null);

  useEffect(() => {
    if (prevFilter?.hasOwnProperty("equal_to")) {
      setCheckboxGroup([prevFilter.equal_to.toString()]);
    } else {
      setCheckboxGroup(["true", "false"]);
    }
  }, [prevFilter]);

  function onApply() {
    if (checkboxGroup.length === 1) {
      setFilter(filterField, [{ equal_to: checkboxGroup[0] === "true" }], column);
    } else {
      setFilter(filterField, [], column);
    }
    setSort({ field: sortField, sort: sortDir });
    onClose();
  }

  function onCancel() {
    onClose();
  }

  function onClear() {
    setCheckboxGroup(["true", "false"]);
    setSortDir(null);
  }

  return (
    <CpCard>
      <CpCard.Body>
        {column.sortable && (
          <>
            <Sort
              setSortDir={setSortDir}
              sortDir={sortDir}
              sortType="string"
              ascSortLabel={ascSortLabel}
              descSortLabel={descSortLabel}
            />
            <div className="cp-divider" />
          </>
        )}
        <CpCheckboxGroup value={checkboxGroup} onChange={setCheckboxGroup}>
          <div className={`${styles.checkbox} cp-mb-16`}>
            <span className="cp-body">{trueValueLabel || "True"}</span>
            <CpCheckboxGroup.Item id={"true"} />
          </div>
          <div className={styles.checkbox}>
            <span className="cp-body">{falseValueLabel || "False"}</span>
            <CpCheckboxGroup.Item id={"false"} />
          </div>
        </CpCheckboxGroup>
      </CpCard.Body>
      <CpCard.Footer className="cp-flex-spread">
        <div>
          <CpButton btnType="primary" onClick={onApply}>
            Apply
          </CpButton>
          <CpButton btnType="flat" className="cp-ml-8" onClick={onCancel}>
            Cancel
          </CpButton>
        </div>
        <CpButton btnType="flat" onClick={onClear}>
          Clear
        </CpButton>
      </CpCard.Footer>
    </CpCard>
  );
}
