import React, { useState, useCallback } from "react";
import styles from "./table-headers.styles.css";
import { m } from "kremling";

import SelectAllHeader from "./headers/select-all-header.component";
import ColumnHeader from "./headers/column-header.component";

export default function TableHeaders({
  orderedColumns,
  columnDefs,
  setColumnOrder,
  selectAll,
  deselectAll,
  selection,
  onDrag,
  onDragEnd,
}) {
  const [dragStartId, setDragStartId] = useState(null);
  const [dragTargetId, setDragTargetId] = useState(null);
  const [dragStartPos, setDragStartPos] = useState(0);
  const [dragStyle, setDragStyle] = useState("");

  const onHeaderDrag = useCallback((e) => onDrag(e), []); // eslint-disable-line
  // lint-TODO: Missing dep, need to make sure onDrag is memoized

  const onDragStart = useCallback((e, columnId) => {
    setDragStartId(columnId);
    setDragStartPos(e.clientX);
  }, []);

  const onDragOver = useCallback(
    (e, targetColumnId) => {
      setDragStyle(e.clientX - dragStartPos > 0 ? styles.dragRight : styles.dragLeft);
      setDragTargetId(targetColumnId);
    },
    [dragStartPos]
  );

  const onDrop = useCallback(() => {
    const cols = [...orderedColumns];
    const fromIndex = cols.indexOf(dragStartId);
    const toIndex = cols.indexOf(dragTargetId);
    cols.splice(fromIndex, 1);
    cols.splice(toIndex, 0, dragStartId);
    setColumnOrder(cols);
  }, [orderedColumns, dragStartId, dragTargetId]); // eslint-disable-line
  // lint-TODO: Missing dep, need to make sure setColumnOrder is memoized

  const onHeaderDragEnd = useCallback(() => {
    setDragStartId(null);
    setDragTargetId(null);
    setDragStyle("");
    onDragEnd?.();
  }, []); // eslint-disable-line
  // lint-TODO: Missing dep, need to make sure onDragEnd is memoized

  return (
    <thead className={m(styles.isDragging, !!dragStartId).a(styles.tableHeader)}>
      <tr>
        <th className={styles.selectCell}>
          <SelectAllHeader selection={selection} selectAll={selectAll} deselectAll={deselectAll} />
        </th>
        {orderedColumns.map((columnId) => {
          const isPinned = columnId === "full_name";
          const column = columnDefs[columnId];
          if (!column) return null;
          const HeaderComponent = column.header;
          return (
            <ColumnHeader
              key={columnId}
              HeaderComponent={HeaderComponent}
              column={column}
              isPinned={isPinned}
              isDragTarget={columnId === dragTargetId}
              dragStyle={dragStyle}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragEnd={onHeaderDragEnd}
              onDrag={onHeaderDrag}
              // This is only needed for the client name cell. Passing 0 to memoize the other headers
              selectionCount={columnId === "full_name" ? selection.totalSelected : 0}
            />
          );
        })}
      </tr>
    </thead>
  );
}
