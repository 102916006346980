import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

export const CancelClientInviteModal = ({
  inviteSuccessCallback,
  handleClose,
  showModal,
  setShowCancelInviteModal,
}) => {
  const handleSendEsign = () => {
    inviteSuccessCallback();
    handleClose();
    setShowCancelInviteModal(false);
  };

  return (
    <div>
      <CpModal show={showModal} onClose={() => setShowCancelInviteModal(false)}>
        <CpModal.Header title="Cancel Client Portal Invite"></CpModal.Header>
        <CpModal.Body>Continue sending eSign requests without client portal invites.</CpModal.Body>
        <CpModal.Footer>
          <CpButton btnType="primary" onClick={() => handleSendEsign()} className="cp-mr-8">
            Send eSign request
          </CpButton>
          <CpButton btnType="flat" onClick={() => setShowCancelInviteModal(false)}>
            Go back
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </div>
  );
};
