// @flow
import * as React from "react";
import { Scoped } from "kremling";
import { uniqueId } from "lodash";
// project
import styles from "./phone-numbers-section.krem.css";
import PhoneNumber from "./phone-number/phone-number.component.js";
import DeletedItem from "../list-of-items/deleted-item.component.js";
import ListOfItems from "../list-of-items/list-of-items.component.js";
// sofe
import { CprButton } from "canopy-styleguide!sofe";

type Props = {};

export type phoneNumber = {
  id: string,
  type: string,
  is_primary: boolean,
  number: string,
  ext: string,
};

export type TNOption = {
  value: string,
  key: string,
};
const phoneNumberOptions: Array<TNOption> = [
  { value: "Mobile phone", key: "mobile" },
  { value: "Home phone", key: "home" },
  { value: "Work phone", key: "work" },
  { value: "Fax phone", key: "fax" },
  { value: "Other phone", key: "other" },
];

const arbitraryMaxCount = 25;

export default class PhoneNumbersSection extends React.Component<Props> {
  render() {
    return (
      <Scoped css={styles}>
        <div className="phoneNumbersSection">
          <ListOfItems
            initialValue={[this.getEmptyPhoneNumber()]}
            name="phone_numbers"
            getEmptyItem={this.getEmptyPhoneNumber}
            validationFn={this.validationFn}
            maxCount={arbitraryMaxCount}
            item={({
              item,
              addRef,
              removeRef,
              removeItemById,
              showInvalid,
              setPrimaryById,
              updateItem,
              itemsLength,
            }) => {
              return (
                <div className="ListItem" key={item.id}>
                  <PhoneNumber
                    ref={(el) => addRef(el)}
                    name={item.id}
                    phoneNumber={item}
                    showInvalid={showInvalid}
                    removeTn={removeItemById}
                    removeRef={removeRef}
                    setPrimaryById={setPrimaryById}
                    showActions={itemsLength >= 2}
                    updatePhoneNumber={updateItem}
                    options={phoneNumberOptions}
                  />
                </div>
              );
            }}
            deleted={({ item, deleteItem, cancelPendingDelete }) => {
              return (
                <DeletedItem
                  key={item.id}
                  item={item}
                  deleteItem={deleteItem}
                  cancelPendingDelete={cancelPendingDelete}
                  itemDescription={"Phone number"}
                />
              );
            }}
            button={({ allItems, addItem, disabledButton }) => {
              return (
                <CprButton type="button" actionType="flat" onClick={addItem} disabled={disabledButton}>
                  + Add phone
                </CprButton>
              );
            }}
          />
        </div>
      </Scoped>
    );
  }

  validationFn = (value: Array<phoneNumber>) => {
    return value.reduce((acc, tn) => {
      if (tn.number && tn.number.length !== 0 && tn.number.length < 10) {
        return false && acc;
      }
      return acc;
    }, true);
  };

  getEmptyPhoneNumber = () => {
    return {
      id: uniqueId("create_phoneNumber"),
      number: "",
      ext: "",
      type: phoneNumberOptions[0].key,
      is_primary: false,
    };
  };
}
