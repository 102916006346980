// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-invite-client-modal-modal-header-styles__header {\n  background-color: #3d464d;\n  color: var(--cp-color-nav-active-text);\n  border-top-left-radius: 0.8rem;\n  border-top-right-radius: 0.8rem;\n}\n.src-invite-client-modal-modal-header-styles__closeHeader {\n  color: var(--cp-color-nav-active-text);\n}\n.src-invite-client-modal-modal-header-styles__imagesRow {\n  display: flex;\n  justify-content: space-around;\n  align-items: flex-end;\n  padding-top: 12px;\n  margin: 0px 66px;\n}\n.src-invite-client-modal-modal-header-styles__imageContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 12px 24px;\n}\n.src-invite-client-modal-modal-header-styles__image {\n  padding: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "src-invite-client-modal-modal-header-styles__header",
	"closeHeader": "src-invite-client-modal-modal-header-styles__closeHeader",
	"imagesRow": "src-invite-client-modal-modal-header-styles__imagesRow",
	"imageContainer": "src-invite-client-modal-modal-header-styles__imageContainer",
	"image": "src-invite-client-modal-modal-header-styles__image"
};
module.exports = exports;
