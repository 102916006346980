// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__filterViewContainer {\n  display: flex;\n  width: 100%;\n  padding: 0 0 1.6rem 0;\n  align-items: center;\n}\n.src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__filterViewText {\n  width: 85%;\n}\n.src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__handle {\n  flex: 0 0 3.2rem;\n}\n.src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__well {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1.6rem 2.4rem;\n  flex: 1 0 0;\n  min-width: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"filterViewContainer": "src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__filterViewContainer",
	"filterViewText": "src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__filterViewText",
	"handle": "src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__handle",
	"well": "src-client-list-column-customization-modal-edit-filter-views-edit-filter-views-body-styles__well"
};
module.exports = exports;
