import React, { useRef } from "react";
import { CpDropdown, CpButton } from "canopy-styleguide!sofe";

import MultiLayerDynamicFilter from "../../column-filters/multi-layer-dynamic-filter/mld-filter.component";
import SearchAndSelectFilter from "../../column-filters/search-and-select-filter/search-and-select-filter.component";
import DateFilter from "../../column-filters/date-filter/date-filter.component";
import BoolFilter from "../../column-filters/bool-filter/bool-filter.component";
import styles from "./filter-header.styles.css";

const customFields = {
  custom_field_text: {
    type: "string",
    filter: "dynamic",
  },
  custom_field_multiselect: {
    type: "string",
    filter: "search",
  },
  custom_field_dropdown: {
    type: "string",
    filter: "search",
  },
  custom_field_date: {
    type: "date",
    filter: "date",
  },
};

export default function FilterHeader({ column }) {
  const { label } = column;
  const dropdownRef = useRef();

  const isCustom = !!column.customField;
  const type = isCustom ? customFields[column.type].type : column.type;
  const filter = isCustom ? customFields[column.type].filter : column.filter;
  return (
    <div className="cp-flex-center">
      <div className={`${styles.filterLabel} cp-ellipsis`} onClick={() => dropdownRef?.current?.toggle()}>
        {label}
      </div>
      <CpDropdown
        ref={dropdownRef}
        allowContentClicks
        contentWidth={filter === "date" ? 500 : 300}
        renderWhenClosed={false}
        renderTrigger={({ toggle }) => (
          <CpButton
            small
            btnType="icon"
            icon="caret-small-down"
            className={styles.filterButton}
            onClick={toggle}
            aria-label={`Filter ${label}`}
          />
        )}
        renderContent={({ close }) => (
          <>
            {filter === "dynamic" && <MultiLayerDynamicFilter column={column} type={type} onClose={close} />}
            {filter === "search" && <SearchAndSelectFilter onClose={close} column={column} />}
            {filter === "date" && <DateFilter column={column} onClose={close} />}
            {filter === "boolean" && <BoolFilter column={column} onClose={close} />}
          </>
        )}
      />
    </div>
  );
}
