import React from "react";
// project
import { InputDecorator } from "src/forms-lib";
import { ContactOwnerInput } from "src/common/client-record-fields";

function ContactOwner(props) {
  const { value, updateValue } = props;

  return (
    <div className="cps-margin-bottom-16 width48">
      <ContactOwnerInput value={value} onChange={updateValue} />
    </div>
  );
}

export default InputDecorator(ContactOwner);
