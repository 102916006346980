import { fetchWithSharedCache } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";
import { of } from "rxjs";
import { flatMap, pluck } from "rxjs/operators";

export function getStatesWithCache() {
  const neverBustCache = () => false;
  return fetchWithSharedCache(`${canopyUrls.getWorkflowUrl()}/api/states`, neverBustCache).pipe(pluck("states"));
}

export function getFormatedStates() {
  return getStatesWithCache().pipe(
    flatMap((item) => {
      const keys = Object.keys(item);
      const array = [];

      keys.forEach((key) => {
        array.push({ key: key, value: item[key] });
      });

      return of(array);
    })
  );
}
