import React from "react";
import FilterHeader from "./filter-header.component";
import styles from "./client-name-header.styles.css";

export default function ClientNameHeader({ column, selectionCount }) {
  return (
    <div className="cp-flex-center">
      <FilterHeader column={column} />
      {selectionCount > 0 && (
        <div className={`${styles.selectionText} cp-ellipsis`}>{selectionCount.toLocaleString()} selected</div>
      )}
    </div>
  );
}
