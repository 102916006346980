import { useMemo } from "react";
import { useQuery } from "src/react-query";
import { catchSyncStacktrace } from "auto-trace";
import { getFilterViews } from "./client-list.resource.js";

export default function useFilterViewsQuery() {
  const query = useQuery({
    queryKey: ["filter-views"],
    queryFn: () =>
      new Promise((resolve, reject) => {
        getFilterViews().subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            catchSyncStacktrace(err);
            reject(err);
          }
        );
      }),
    staleTime: 10000, // 10 secs
  });

  const filterViews = useMemo(
    () => query?.data?.filter_views?.sort((a, b) => a.filter_position - b.filter_position) || [],
    [query.data]
  );

  return {
    filterViewsQuery: query,
    filterViews,
  };
}
