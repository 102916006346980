// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-column-customization-modal-column-customization-styles__modalBody {\n  display: flex;\n  max-height: 89.6rem;\n  overflow: hidden;\n}\n.src-client-list-column-customization-modal-column-customization-styles__columnLeft {\n  border-right: solid 0.1rem var(--cp-color-card-border);\n  flex: 0 0 auto;\n  overflow: auto;\n}\n.src-client-list-column-customization-modal-column-customization-styles__columnLeftItem {\n  display: flex;\n  align-items: center;\n  padding: 1.6rem;\n  border-bottom: solid 0.1rem var(--cp-color-card-border);\n}\n.src-client-list-column-customization-modal-column-customization-styles__columnLeft > .src-client-list-column-customization-modal-column-customization-styles__columnLeftItemSelected {\n  color: var(--cp-color-button-icon-active-text);\n}\n.src-client-list-column-customization-modal-column-customization-styles__columnRight {\n  padding: 1.6rem 2.4rem 1.6rem 2.4rem;\n  flex: 1 1 auto;\n  overflow: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"modalBody": "src-client-list-column-customization-modal-column-customization-styles__modalBody",
	"columnLeft": "src-client-list-column-customization-modal-column-customization-styles__columnLeft",
	"columnLeftItem": "src-client-list-column-customization-modal-column-customization-styles__columnLeftItem",
	"columnLeftItemSelected": "src-client-list-column-customization-modal-column-customization-styles__columnLeftItemSelected",
	"columnRight": "src-client-list-column-customization-modal-column-customization-styles__columnRight"
};
module.exports = exports;
