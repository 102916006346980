import { catchError, map, pluck } from "rxjs/operators";
import canopyUrls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";
import { checkForFailedFetch } from "./resource.helpers.js";

const headers =
  typeof Headers !== "undefined"
    ? new Headers({
        "Content-Type": "application/json",
      })
    : {};

export function getUsers(basic = true, role, userIds) {
  const roleParam = role ? `&role=${role}` : "";
  const userIdsParam = userIds ? `&userIds=${userIds}` : "";

  return fetchAsObservable(`${canopyUrls.getAPIUrl()}/users?basic=${basic}${roleParam}${userIdsParam}`).pipe(
    pluck("users"),
    catchError(checkForFailedFetch)
  );
}

export function postUser(users, notifications = {}) {
  let body = { users };
  if (notifications) {
    body.notifications = notifications;
  }
  return fetchAsObservable(`${canopyUrls.getAPIUrl()}/users`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  }).pipe(
    map((resp) => resp.users.id),
    catchError((error) => {
      // Attach the request body so that we can use the email
      // in the error message to the user
      error.requestBody = body;
      return checkForFailedFetch(error);
    })
  );
}

export function patchUser(userId, data) {
  return fetchAsObservable(`${canopyUrls.getAPIUrl()}/users/${userId}`, {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({ users: data }),
  }).pipe(pluck("users"), catchError(checkForFailedFetch));
}
