import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { CpButton, CpCard, CpRadioField } from "canopy-styleguide!sofe";
import { omitBy, isEmpty } from "lodash";
import MLDFilterForm from "./mld-filter-form.component";
import Sort from "../common/sort.component";
import { formFieldNames, getFilterValues, conditionOpts } from "./mld-filter.helper";
import { FilterContext } from "../../client-list.component";

export default function MultiLayerDynamicFilter({ column, type, onClose = () => {} }) {
  const { sortable, filterField, sortField, hideBlanksOption } = column;
  const { getFilter, setFilter, getSort, setSort } = useContext(FilterContext);
  const currentFilter = getFilter(filterField)?.[0];
  const currentSortDir = getSort(sortField)?.sort;

  let prevFilterValues = getFilterValues(currentFilter, type);

  const [sortDir, setSortDir] = useState(currentSortDir || null);
  const [showConditionTwo, setShowConditionTwo] = useState(!!prevFilterValues?.operator || false);

  const { control, getValues, handleSubmit, reset, resetField, watch, setError } = useForm({
    defaultValues: {
      [formFieldNames.inputOne]: prevFilterValues?.inputOne || "",
      [formFieldNames.betweenInputOne]: prevFilterValues?.betweenInputOne || "",
      [formFieldNames.betweenInputTwo]: prevFilterValues?.betweenInputTwo || "",
      [formFieldNames.inputTwo]: prevFilterValues?.inputTwo || "",
      [formFieldNames.selectOne]: prevFilterValues?.selectOne || conditionOpts[type][0] || null,
      [formFieldNames.selectTwo]: prevFilterValues?.selectTwo || null,
      [formFieldNames.radio]: prevFilterValues?.operator || null,
    },
  });

  function onApply() {
    const { filterOneType, valueOne, betweenValueOne, betweenValueTwo, operator, filterTwoType, valueTwo } =
      getValues();

    const filters = [
      {
        type: filterOneType?.id,
        inputs: [
          { name: formFieldNames.inputOne, value: valueOne },
          { name: formFieldNames.betweenInputOne, value: betweenValueOne },
        ],
      },
      {
        type: filterTwoType?.id,
        inputs: [
          { name: formFieldNames.inputTwo, value: valueTwo },
          { name: formFieldNames.betweenInputTwo, value: betweenValueTwo },
        ],
      },
    ];
    const filterToApply = {
      starts_with: [],
      contains: [],
      equal_to: [],
      less_than: [],
      greater_than: [],
      between: [],
      operator,
    };
    let hasError = false;
    const emptyInputError = { type: "empty_field", message: "Please add a value" };
    const emptyOperatorError = { type: "empty_field", message: "Please select an operator" };
    filters.forEach((filter) => {
      switch (filter.type) {
        case undefined:
          break;
        case "include_empty":
          filterToApply.include_empty = true;
          break;
        case "between":
          filter.inputs.forEach((input) => {
            if (!input.value) {
              setError(input.name, emptyInputError);
              hasError = true;
            }
          });
          if (hasError) break;
          const sortedValues = [filter.inputs[0].value, filter.inputs[1].value].sort((a, b) => a - b);
          filterToApply.between.push(sortedValues);
          break;
        default:
          if (filter.inputs[0].value) {
            filterToApply[filter.type].push(filter.inputs[0].value);
          }
      }
    });
    if (!operator && filters[0].type && filters[1].type) {
      setError(formFieldNames.radio, emptyOperatorError);
      hasError = true;
    }
    if (hasError) return;

    const filterToApplyCleaned = omitBy(filterToApply, (value) => {
      return !(value === true || value === false) && isEmpty(value);
    });

    // If we have only 1 filter and it's the operator then don't update filters.
    if (!(Object.keys(filterToApplyCleaned).length === 1 && !!filterToApplyCleaned.operator)) {
      setFilter(filterField, isEmpty(filterToApplyCleaned) ? [] : [filterToApplyCleaned], column);
    }

    setSort({ field: sortField, sort: sortDir });
    onClose();
  }
  function onCancel() {
    onClose();
  }
  function onClear() {
    reset({
      [formFieldNames.selectOne]: null,
      [formFieldNames.selectTwo]: null,
      [formFieldNames.radio]: null,
      [formFieldNames.inputOne]: "",
      [formFieldNames.inputTwo]: "",
      [formFieldNames.betweenInputOne]: "",
      [formFieldNames.betweenInputTwo]: "",
    });
    setShowConditionTwo(false);
    setSortDir(null);
  }

  return (
    <CpCard>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onApply)();
        }}
      >
        <CpCard.Body>
          {column.sortable && (
            <>
              <Sort setSortDir={setSortDir} sortDir={sortDir} sortType={type} />
              <div className="cp-divider" />
            </>
          )}
          <MLDFilterForm
            control={control}
            hideBlanksOption={hideBlanksOption}
            inputFieldOneName={formFieldNames.inputOne}
            inputFieldTwoName={formFieldNames.betweenInputOne}
            resetField={resetField}
            selectFieldName={formFieldNames.selectOne}
            type={type}
            watch={watch}
          />
          {showConditionTwo ? (
            <div className="cp-mt-16">
              <CpRadioField control={control} fieldName={formFieldNames.radio} inline name={formFieldNames.radio}>
                <CpRadioField.Item id="or">Or</CpRadioField.Item>
                <CpRadioField.Item id="and">And</CpRadioField.Item>
              </CpRadioField>
              <MLDFilterForm
                control={control}
                hideBlanksOption={hideBlanksOption}
                inputFieldOneName={formFieldNames.inputTwo}
                inputFieldTwoName={formFieldNames.betweenInputTwo}
                onRemove={() => {
                  resetField(formFieldNames.radio, { defaultValue: null });
                  setShowConditionTwo(false);
                }}
                resetField={resetField}
                selectFieldName={formFieldNames.selectTwo}
                type={type}
                watch={watch}
              />
            </div>
          ) : (
            <CpButton
              aria-label="Add condition"
              btnType="tertiary"
              className="cp-mt-16"
              icon="add-small"
              onClick={() => setShowConditionTwo(true)}
              type="button"
            >
              Add condition
            </CpButton>
          )}
        </CpCard.Body>
        <CpCard.Footer>
          <div className="cp-flex-spread">
            <div className="cp-flex">
              <CpButton aria-label="Apply" btnType="primary" className="cp-mr-8" type="submit">
                Apply
              </CpButton>
              <CpButton aria-label="Cancel" btnType="flat" onClick={onCancel} type="button">
                Cancel
              </CpButton>
            </div>
            <CpButton aria-label="Reset" btnType="flat" onClick={onClear} type="button">
              Clear
            </CpButton>
          </div>
        </CpCard.Footer>
      </form>
    </CpCard>
  );
}

MultiLayerDynamicFilter.propTypes = {
  column: PropTypes.object,
  type: PropTypes.oneOf(["string", "number"]).isRequired,
  onClose: PropTypes.func,
};
