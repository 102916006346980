import React from "react";
import RenameFilterView from "./rename-filter-view.component";
import ShareFilterView from "./share-filter-view.component";
import { cloneDeep } from "lodash";
import { catchError } from "auto-trace";
import { createFilterView, patchFilterView } from "../../client-list.resource";

export const actions = [
  {
    id: "rename",
    label: "Rename",
    icon: "crud-pencil",
    execute: ({ showSubView }) => {
      showSubView("rename");
    },
    renderView: ({ filterView, closeSubView }) => <RenameFilterView filterView={filterView} onClose={closeSubView} />,
  },
  {
    id: "share",
    label: "Share list",
    icon: "misc-share",
    execute: ({ showSubView }) => {
      showSubView("share");
    },
    renderView: ({ filterView, closeSubView }) => <ShareFilterView filterView={filterView} onClose={closeSubView} />,
  },
  {
    id: "hide",
    label: "Hide filter view",
    icon: "misc-closed-eye",
    execute: ({ filterView, queryClient, onClose }) => {
      patchFilterView(filterView.id, {
        hidden: true,
      }).subscribe(() => {
        onClose && onClose();
        queryClient.invalidateQueries(["filter-views"]);
      }, catchError());
    },
  },
  {
    id: "show",
    label: "Show filter view",
    icon: "misc-open-eye",
    execute: ({ filterView, queryClient, onClose }) => {
      patchFilterView(filterView.id, {
        hidden: false,
      }).subscribe(() => {
        onClose && onClose();
        queryClient.invalidateQueries(["filter-views"]);
      }, catchError());
    },
  },
  {
    id: "duplicate",
    label: "Duplicate",
    icon: "crud-duplicate",
    execute: ({ filterView, onClose, queryClient }) => {
      const body = cloneDeep(filterView);
      delete body.id;
      body.name = body.name + " (copy)";
      body.sharing_data = [];

      createFilterView(body).subscribe((res) => {
        queryClient.invalidateQueries(["filter-views"]);
        onClose();
      }, catchError());
    },
  },
  {
    id: "delete",
    label: "Delete",
    icon: "crud-trash-large",
    execute: ({ filterView, onClose, markFilterViewForDelete }) => {
      markFilterViewForDelete(filterView.id);
      onClose();
    },
  },
  {
    id: "undoDelete",
    label: "Undo delete",
    icon: "af-undo",
    execute: ({ filterView, onClose, unmarkFilterViewForDelete }) => {
      unmarkFilterViewForDelete(filterView.id);
      onClose();
    },
  },
];
