import React from "react";
import focusTrap from "focus-trap";
import { Scoped } from "kremling";
import { fromEvent } from "rxjs";
import Disposable from "react-disposable-decorator";
// project
import styles from "./modal.krem.css";
import { Dialog } from "primary-navbar!sofe";

@Disposable
export default class Modal extends React.Component {
  modal = React.createRef();
  state = {
    allowDismiss: true,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(document, "keydown", false).subscribe(this.escFunction),
      fromEvent(window, "click").subscribe(this.clickThenActivateTrap),
      fromEvent(this.modal.current, "focusin").subscribe(this.updateLatestFocus)
    );

    this.initializeTrap();
    this.activateTrap();
  }

  render() {
    return (
      <Dialog>
        <Scoped css={styles}>
          <div className="cps-modal" ref={this.modal}>
            <div className="cps-modal__screen"></div>
            <div className="cps-modal__dialog" onClick={this.stopPropagation}>
              {this.props.children &&
                React.Children.map(this.props.children, (child) => {
                  const props = {
                    closeModal: this.handleModalClose,
                    preventDismiss: this.preventDismiss,
                    allowDismiss: this.allowDismiss,
                    ...child.props,
                  };
                  return React.cloneElement(child, props);
                })}
            </div>
          </div>
        </Scoped>
      </Dialog>
    );
  }

  componentWillUnmount() {
    this.deactivateTrap();
  }

  stopPropagation = (evt) => {
    evt.stopPropagation();
  };

  updateLatestFocus = (evt) => {
    this.lastFocusedElement = evt.target;
  };

  clickThenActivateTrap = (evt) => {
    // reactivate trap
    this.activateTrap();
    if (this.lastFocusedElement && this.lastFocusedElement.focus) {
      this.lastFocusedElement.focus();
    }
  };

  initializeTrap = () => {
    const configuration = {
      clickOutsideDeactivates: true,
    };
    if (this.props.getInitialFocus) {
      configuration.initialFocus = () => {
        return this.props.getInitialFocus(this.modal.current);
      };
    }
    if (this.modal.current) {
      this.trap = focusTrap(this.modal.current, configuration);
    }
  };

  activateTrap = () => {
    this.trap && this.trap.activate();
  };

  deactivateTrap = () => {
    this.trap && this.trap.deactivate();
  };

  handleModalClose = (modalResponse) => {
    if (this.props.onModalHide) {
      this.props.onModalHide(modalResponse);
    }
  };

  allowDismiss = () => {
    this.setState({ allowDismiss: true });
  };

  preventDismiss = () => {
    this.setState({ allowDismiss: false });
  };

  escFunction = (event) => {
    event.stopPropagation();
    if (event.keyCode === 27 && this.state.allowDismiss) {
      this.handleModalClose(undefined);
    }
  };
}
