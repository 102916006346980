import { useState, useEffect, useRef } from "react";
import { Subject, of } from "rxjs";
import { tap, debounceTime, switchMap } from "rxjs/operators";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";

export function useRxjsSubjectAsRef() {
  const subjectRef = useRef(null);
  const fn = function (valueToEmit) {
    if (subjectRef.current && subjectRef.current.next) {
      return subjectRef.current.next(valueToEmit);
    }
  };
  useEffect(() => {
    subjectRef.current = new Subject();
    return () => {
      subjectRef.current.unsubscribe();
    };
  }, []);
  return [subjectRef, fn];
}

export function useTypeaheadSearch(debounce, observable, localItems, itemToString) {
  // changes to [itemToString, observable] will trigger a rerender unless you use
  //  useCallback in your component (assuming you created the function dynamically)
  //  see: https://overreacted.io/a-complete-guide-to-useeffect/
  const [search, setSearch] = useState();
  const [foundItems, setFoundItems] = useState(localItems || []);
  const [loading, setLoading] = useState(false);
  const [input$, emit] = useRxjsSubjectAsRef();
  useEffect(() => {
    if (input$.current) {
      let debounceInMs = 250;

      if (debounce !== undefined) {
        debounceInMs = debounce;
      }

      const subscription = input$.current
        .pipe(
          tap((value) => {
            setSearch(value);
            if (observable) {
              setLoading(true);
            }
          }),
          debounceTime(debounceInMs),
          switchMap((value) => localOrRemoteSearch(value))
        )
        .subscribe(
          (itemsFromResponse) => {
            setFoundItems(itemsFromResponse || []);
            setLoading(false);
          },
          asyncStacktrace((err) => {
            setLoading(false);
            catchSyncStacktrace(err);
          })
        );
      return () => {
        subscription.unsubscribe();
      };
    }

    function localOrRemoteSearch(searchString = "") {
      if (observable) {
        return observable(searchString);
      } else {
        return of(localItems.filter((item) => itemToString(item).toLowerCase().includes(searchString.toLowerCase())));
      }
    }
  }, [debounceTime, localItems, itemToString, observable]); //eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  return [emit, search, loading, foundItems];
}
