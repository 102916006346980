// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-table-client-list-table-styles__tableContainer {\n  height: 100%;\n  overflow: auto;\n}\n\n.src-client-list-table-client-list-table-styles__clientTable {\n  border-spacing: 0;\n  table-layout: fixed;\n  white-space: nowrap;\n  width: 100%;\n}\n\n.src-client-list-table-client-list-table-styles__clientTable tr td {\n  transition: background-color 0.1s;\n}\n.src-client-list-table-client-list-table-styles__clientTable tr:hover td {\n  background-color: var(--cp-color-nav-active-border);\n}\n\n.src-client-list-table-client-list-table-styles__clientTable td {\n  padding: 1.2rem;\n  overflow: hidden;\n  border-right: 0.1rem solid var(--cp-color-app-border);\n  border-bottom: 0.1rem solid var(--cp-color-app-border);\n}\n", ""]);
// Exports
exports.locals = {
	"tableContainer": "src-client-list-table-client-list-table-styles__tableContainer",
	"clientTable": "src-client-list-table-client-list-table-styles__clientTable"
};
module.exports = exports;
