// @flow
import React from "react";
// project
import { useFormInput } from "src/forms-lib";
import { ClientTypePicker } from "src/common/client-record-fields";

type Props = {
  shareValue: boolean,
  initialValue: string,
  name: string,
};

export default function TypeSection(props: Props) {
  const [type, updateType] = useFormInput({
    name: props.name,
    initialValue: props.initialValue,
    shared: props.shareValue,
  });

  return <ClientTypePicker value={type} onChange={(value) => setType(value)} />;

  function setType(type: string) {
    updateType(type);
  }
}
