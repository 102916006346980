import { useMemo } from "react";
import { catchError } from "auto-trace";
import { getRoles } from "./roles.resource";
import { useQuery } from "src/react-query";

export function useRolesQuery() {
  const query = useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        getRoles().subscribe(resolve, reject);
      });
    },
    onError: catchError(),
    staleTime: 60000,
  });

  const roles = useMemo(() => query?.data?.roles || [], [query.data]);

  return {
    rolesQuery: query,
    roles,
  };
}
export default useRolesQuery;
