import React, { useImperativeHandle, useState, useRef, useMemo, useCallback } from "react";
import { useQueryClient } from "src/react-query";
import { catchError } from "auto-trace";
import { CpContextDropdown, CpIcon, CpModal, CpEmptyState, CpButton, constants } from "canopy-styleguide!sofe";
import { useWithUserAndTenant, hasAccess, isContactLimitModel } from "cp-client-auth!sofe";
import { successToast } from "toast-service!sofe";
import CreateEditContactModal from "src/create-edit-contact/create-edit-contact-modal/create-edit-contact-modal.component";
import AssignUsersModal from "../../assign-users-modal/assign-users-modal.component";
import AssignContactOwnerModal from "src/client-list/assign-contact-owner-modal.component";
import ArchiveModal from "src/common/archive-modal.component";
import { deleteContact, getContactsUsage, putContact } from "src/resources/contacts.resource.js";

const contextMenuActions = [
  {
    id: "edit",
    permissions: ["contacts_create_edit"],
  },
  {
    id: "assign_team_member",
    permissions: ["contacts_assign_team_members"],
  },
  {
    id: "assign_contact_owner",
    permissions: ["contacts_assign_team_members"],
  },
  {
    id: "archive",
    permissions: ["contacts_archive"],
  },
  {
    id: "unarchive",
    permissions: ["contacts_archive"],
  },
  {
    id: "delete",
    permissions: ["contacts_delete"],
  },
];

const ContextMenu = React.forwardRef((props, ref) => {
  const {
    actions, // Array of available actions, pass falsey if you want all
    onClose,
  } = props;

  const [user, tenant] = useWithUserAndTenant();
  const [contextMenuClientId, setContextMenuClientId] = useState();
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [showContactOwnerModal, setShowContactOwnerModal] = useState(false);
  const [showAssignmentsModal, setShowAssignmentsModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const contextMenuRef = useRef();
  const queryClient = useQueryClient();
  const [reachedContactLimit, setReachedContactLimit] = useState(false);

  const allowedActions = useMemo(() => {
    const actionIds = contextMenuActions
      .filter((action) => {
        return (
          hasAccess(user, true)(action.permissions) &&
          (actions ? actions.includes(action.id) : !["delete", "unarchive"].some((i) => action.id === i))
        );
      })
      .map((action) => action.id);
    return new Set(actionIds);
  }, [actions, user]);

  const openContextMenu = useCallback(
    (e, clientId) => {
      if (allowedActions.size <= 0) return;
      e.preventDefault();
      setContextMenuClientId(clientId);
      contextMenuRef.current.open({
        top: e.pageY,
        left: e.pageX,
      });
    },
    [allowedActions.size]
  );

  useImperativeHandle(
    ref,
    () => ({
      openContextMenu,
    }),
    [openContextMenu]
  );

  const archiveClient = () => {
    putContact(contextMenuClientId, { is_archived: true }).subscribe((client) => {
      successToast(`${client.name} has been archived.`);
      queryClient.invalidateQueries(["clients"]);
    }, catchError());
    setShowArchiveModal(false);
  };

  const attemptUnArchiveClient = () => {
    if (!isContactLimitModel(tenant?.contact_limit)) {
      unArchiveClient();
    } else {
      getContactsUsage().subscribe((res) => {
        if (res.reached_limit) {
          setReachedContactLimit(res);
        } else {
          unArchiveClient();
        }
      }, catchError());
    }
  };

  const unArchiveClient = () => {
    putContact(contextMenuClientId, { is_archived: false }).subscribe((clients) => {
      queryClient.invalidateQueries(["clients"]);
    }, catchError());
  };

  const deleteClient = () => {
    deleteContact(contextMenuClientId).subscribe((resp) => {
      queryClient.invalidateQueries(["clients"]);
      successToast(`Contact successfully deleted.`);
    }, catchError());
  };

  return (
    <>
      <CpContextDropdown
        onClose={onClose}
        ref={contextMenuRef}
        contentWidth="md"
        renderContent={() => (
          <div className="cp-select-list">
            {allowedActions.has("edit") && (
              <button onClick={() => setShowEditContactModal(true)}>
                <CpIcon className="cp-select-list__icon-left" name="crud-pencil" />
                Edit client
              </button>
            )}
            {allowedActions.has("unarchive") && (
              <button onClick={attemptUnArchiveClient}>
                <CpIcon className="cp-select-list__icon-left" name="af-undo" />
                Unarchive client
              </button>
            )}
            {allowedActions.has("delete") && (
              <button onClick={deleteClient}>
                <CpIcon className="cp-select-list__icon-left" name="crud-trash-large" />
                Delete client
              </button>
            )}
            {allowedActions.has("assign_team_member") && (
              <button
                onClick={() => {
                  setShowAssignmentsModal(true);
                }}
              >
                <CpIcon className="cp-select-list__icon-left" name="person-people" />
                Manage assignments
              </button>
            )}
            {allowedActions.has("assign_contact_owner") && (
              <button
                onClick={() => {
                  setShowContactOwnerModal(true);
                }}
              >
                <CpIcon className="cp-select-list__icon-left" name="person" />
                Assign contact owner
              </button>
            )}
            {allowedActions.has("archive") && (
              <button onClick={() => setShowArchiveModal(true)}>
                <CpIcon className="cp-select-list__icon-left" name="crud-archive" />
                Archive client
              </button>
            )}
          </div>
        )}
      />
      {showEditContactModal && (
        <CreateEditContactModal
          contactId={contextMenuClientId}
          redirectAsPrimary={false}
          isCreate={false}
          onModalHide={() => {
            queryClient.invalidateQueries(["clients"]);
            setShowEditContactModal(false);
          }}
        />
      )}
      {showAssignmentsModal && (
        <AssignUsersModal onClose={() => setShowAssignmentsModal(false)} clientId={contextMenuClientId} />
      )}
      {showContactOwnerModal && (
        <AssignContactOwnerModal onClose={() => setShowContactOwnerModal(false)} clientId={contextMenuClientId} />
      )}
      {reachedContactLimit && (
        <CpModal
          show={reachedContactLimit}
          onClose={() => {
            setReachedContactLimit(false);
          }}
          className="cp-p-20"
        >
          <div className="cp-p-8" style={{ position: "absolute", right: 0, top: 0 }}>
            <CpButton
              btnType="icon"
              aria-label="Close"
              icon="close-small"
              onClick={() => {
                setReachedContactLimit(false);
              }}
            />
          </div>
          <CpEmptyState
            img="es_contacts"
            text="Time to upgrade"
            subText={`You've hit your maximum of ${reachedContactLimit.allowed} contacts for your pricing tier. Call ${constants.canopyPhone} to upgrade.`}
            cta={
              <CpButton btnType="flat" anchor href="#/global-settings/account-management">
                View my subscription
              </CpButton>
            }
          />
        </CpModal>
      )}
      {showArchiveModal && (
        <ArchiveModal onArchive={archiveClient} onClose={() => setShowArchiveModal(false)} totalToArchive={1} />
      )}
    </>
  );
});

export default ContextMenu;
