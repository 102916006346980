import { map, has, pick } from "lodash";

// This is used in https://code.canopy.dev/front-end/red/tax-prep-ui/-/blob/master/src/dashboard/create-edit-task/create-edit-task.wrapper.js
// I think red must be passing in a bulkTaskJql prop otherwise this would have been broken while the new client list was being used. We'll need to clarify if the new client list is supposed to be returning something here and if not we should remove it here and in that tax-prep-ui component.
export function getJql() {
  return null;
}

// Cleans up filters json before saving within a filter group
export function prepJqlForPersist(jql) {
  // We save the operator index and keep all the operator data client-side
  return map(jql, (filter) => {
    // The entity type filter doesn't need cleanup
    if (filter.field == "is_business") return pick(filter, "field", "operator", "value");

    let operatorIndex = has(filter, "filterDef.operators") ? filter.filterDef.operators.indexOf(filter.operator) : -1;
    return {
      field: filter.field,
      operatorIndex: operatorIndex,
      value: filter.value,
    };
  });
}
