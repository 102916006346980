import { contactToModel } from "./contact-to-js.js";
import { modelToContact } from "./js-to-contact.js";
export function mapContactToModel(contact) {
  if (contact === undefined) {
    return undefined;
  } else {
    const topSection = contactToModel.topSection(contact);
    const emails = contactToModel.emails(contact);
    const phones = contactToModel.phones(contact);
    const addresses = contactToModel.addresses(contact);
    const roleAssignments = contact.role_assignments;
    const usersTeams = contact.general_assigned;
    const bottomSection = contactToModel.bottomSection(contact);
    const always = contactToModel.always(contact);
    return {
      ...topSection,
      ...emails,
      ...phones,
      ...addresses,
      roleAssignments,
      usersTeams,
      ...bottomSection,
      ...always,
    };
  }
}

export function mapModelToAPI(model) {
  const topSection = modelToContact.topSection(model);
  const emails = modelToContact.emails(model);
  const phones = modelToContact.phones(model);
  const addresses = modelToContact.addresses(model);
  const rolesUsersTeams = modelToContact.rolesUsersTeamsSections(model);
  const bottomSection = modelToContact.bottomSection(model);
  const always = modelToContact.always(model);
  return {
    ...topSection,
    ...emails,
    ...phones,
    ...addresses,
    rolesUsersTeams,
    ...bottomSection,
    ...always,
  };
}
