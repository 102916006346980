import React from "react";
import Input from "src/form/input/input.component";

export default function ExternalId(props) {
  return (
    <div className="cps-margin-bottom-16 width48">
      <Input
        type="text"
        label={
          <label>
            <span>External ID</span>
          </label>
        }
        name="external_id"
        placeholder="External ID"
        maxLength={45}
      />
    </div>
  );
}
