import React, { useState, useEffect } from "react";
import { catchAsyncStacktrace } from "auto-trace";
import { find } from "lodash";
// project
import { SimpleTypeahead } from "src/form/typeahead-input/typeahead-input.component.js";
import { getUsers } from "src/contact-page/contact-page.resource.js";
import { FieldLabel } from "src/common/client-record-fields";

export function ContactOwnerInput(props) {
  const { value, onChange } = props;
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const req$ = getUsers("TeamMember").subscribe((results) => {
      if (typeof value === "string") {
        const initialValue = find(results, (tm) => tm.id === value);
        onChange(initialValue || {});
      }
      setTeamMembers(results);
    }, catchAsyncStacktrace());
    return () => req$.unsubscribe();
  }, []); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  return (
    <SimpleTypeahead
      value={value}
      updateValue={(selection) => selection && onChange(selection)}
      items={teamMembers}
      itemToString={itemToString}
      itemKey={(item) => item.id}
      emptySelectedItem={{}}
      label={
        <FieldLabel>
          <span>Contact owner</span>
        </FieldLabel>
      }
    />
  );
}

function itemToString(item) {
  return item?.name;
}
