import { DateTime } from "luxon";

export function getColumn(filterField, hideNoDateAdded) {
  const now = DateTime.local();
  const today = now.toISODate();
  const currentMonth = {
    after: now.startOf("month").toISODate(),
    before: now.endOf("month").toISODate(),
  };
  const lastMonth = {
    after: now.minus({ months: 1 }).startOf("month").toISODate(),
    before: now.minus({ months: 1 }).endOf("month").toISODate(),
  };
  const currentQuarter = {
    after: now.startOf("quarter").toISODate(),
    before: now.endOf("quarter").toISODate(),
  };
  const yearToDate = {
    after: DateTime.local().startOf("year").toISODate(),
    before: today,
  };
  const custom = {
    after: today,
    before: today,
  };

  const column = {
    filterType: "date-range",
    maxSortValue: "Newest",
    minSortValue: "Oldest",
    sortParam: filterField,
    dateRanges: {
      noDateAdded: {
        filter: {},
        label: "No date added",
      },
      currentMonth: {
        filter: currentMonth,
        label: "Current month",
      },
      lastMonth: {
        filter: lastMonth,
        label: "Last month",
      },
      currentQuarter: {
        filter: currentQuarter,
        label: "Current quarter",
      },
      yearToDate: {
        filter: yearToDate,
        label: "Year-to-date",
      },
      custom: {
        filter: custom,
        label: "Custom",
      },
    },
  };
  if (hideNoDateAdded) delete column.dateRanges.noDateAdded;
  return column;
}
