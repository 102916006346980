import { of } from "rxjs";
import { map, concatMap } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";
import { get } from "lodash";
import { patchContactRelationships } from "./create-edit-contact.resource.js";

export function updateSource(contact, prevContactResults) {
  const { source } = contact;
  let updateContactSources$;
  if (source && (source.name || source.name === null)) {
    updateContactSources$ = fetchAsObservable(`api/contact_sources`, {
      method: "POST",
      body: {
        contact_sources: {
          name: source.name,
          contact_id: prevContactResults.id,
        },
      },
    });
  } else {
    updateContactSources$ = of("");
  }

  const prevReferrals = get(prevContactResults, "relationships.contacts", []).filter(
    (relationship) => relationship.relationship_type === "referral"
  );

  const deletedPrevReferrals = prevReferrals.map((referral) => ({
    id: referral.id,
    relationship_type: "referral",
    delete: true,
  }));
  const relationships = {};
  let contacts = [];

  if (source && source.name === "Referral" && source.selected && source.selected.id) {
    if (deletedPrevReferrals.find((prevRef) => prevRef.id === source.selected.id) === undefined) {
      contacts = [...deletedPrevReferrals, { id: source.selected.id, relationship_type: "referral" }];
    } else {
      contacts = prevReferrals.map((referral) => {
        const newReferral = { id: referral.id, relationship_type: "referral" };
        if (newReferral.id !== source.selected.id) {
          newReferral.delete = true;
        }
        return newReferral;
      });
    }
  } else {
    contacts = [...deletedPrevReferrals];
  }

  if (contacts.length > 0) {
    relationships.contacts = contacts;
    return updateContactSources$.pipe(
      concatMap((results) => {
        return patchContactRelationships(prevContactResults, relationships);
      })
    );
  } else {
    return updateContactSources$.pipe(map((value) => prevContactResults));
  }
}
