// @flow
import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./email.krem.css";
import Actions from "../../list-of-items/actions/actions.component.js";
import { Input } from "src/form/input/input.component.js";
import { isValidEmail } from "src/common/utils.helpers.js";
import { SimpleSelect } from "src/form/select-input/select-input.component.js";
// sofe
import type { EmailType, EmailOptionType } from "../email-section.component.js";

type Props = {
  updateEmail: Function,
  removeEmail: Function,
  showInvalid: boolean,
  name: string,
  removeRef: Function,
  setPrimaryById: Function,
  showActions: boolean,
  options: Array<EmailOptionType>,
  email: EmailType,
};

export default class Email extends React.Component<Props> {
  emailInput: ?Input;

  static defaultProps = {
    showInvalid: false,
  };

  render() {
    return (
      <Scoped css={styles}>
        <div className="emailBlock cps-margin-bottom-16 margin-between">
          <div className="middle-selection-dropdown">
            <SimpleSelect
              options={this.getDropdownOptions()}
              value={this.props.email.type}
              updateValue={this.updateType}
              ariaLabelText={"Email Type Select"}
            ></SimpleSelect>
          </div>
          <div className="emailInput">
            <Input
              outerClass="width100"
              className="cps-margin-right-8"
              type="email"
              placeholder="Email address"
              value={this.props.email.address}
              updateValue={this.updateEmailAddress}
              ref={(el) => (this.emailInput = el)}
              valid={this.validEmail()}
              invalidMessage="Invalid email address"
              showInvalid={this.props.showInvalid}
            />
          </div>
          {this.props.showActions && (
            <div>
              <Actions
                isPrimary={this.props.email.is_primary}
                setPrimary={this.setPrimary}
                starHtml={`Primary email`}
                trashHtml={`Remove email`}
                remove={this.removeEmail}
              />
            </div>
          )}
        </div>
      </Scoped>
    );
  }

  focus = () => {
    if (this.emailInput) {
      this.emailInput.focus();
    }
  };

  setPrimary = () => {
    this.props.setPrimaryById(this.props.email.id, !this.props.email.is_primary);
  };

  removePress = (evt: SyntheticKeyboardEvent<HTMLAnchorElement>) => {
    if (evt.key === " " || evt.key === "Enter") {
      this.removeEmail();
    }
  };

  removeEmail = () => {
    this.props.removeRef(this);
    this.props.removeEmail(this.props.email.id);
  };

  updateType = (value: string) => {
    const email = { ...this.props.email, type: value };
    this.props.updateEmail(this.props.email.id, email);
  };

  updateEmailAddress = (value: string) => {
    const email = { ...this.props.email, address: value };
    this.props.updateEmail(email.id, email);
  };

  getDropdownOptions = (): Array<EmailOptionType> => {
    return this.props.options || [];
  };

  isInvalid = () => {
    return !this.validEmail();
  };

  validEmail = () => {
    const address = this.props.email.address;
    if (address && address.length > 0) {
      return isValidEmail(address);
    } else {
      return true;
    }
  };
}
