import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./upgrade-contacts-modal.styles.css";
import { a } from "kremling";
import { CprIcon } from "canopy-styleguide!sofe";
import UpgradeContactsCard from "./upgrade-contacts-card.component";
import useFocusTrap from "src/global-settings/custom-fields/hooks/use-focus-trap.hook.js";

UpgradeContactsModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default function UpgradeContactsModal(props) {
  const modalRef = useRef(null);

  useFocusTrap(modalRef);

  return (
    <div className="cps-modal" ref={modalRef}>
      <div className="cps-modal__screen" />
      <div className="cps-modal__dialog cps-card__height-3">
        <div className={a(styles.header, "cps-card__header cps-subheader")}>
          <span />
          <button onClick={props.close} className={styles.iconButton}>
            <span className="cps-screenreader">Close</span>
            <CprIcon name="close" />
          </button>
        </div>
        <div className={a(styles.body, "cps-card__body cps-body")}>
          <UpgradeContactsCard>{props.children}</UpgradeContactsCard>
        </div>
      </div>
    </div>
  );
}
