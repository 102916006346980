import React from "react";

export default class FormErrorBoundary extends React.Component {
  state = {
    hasError: false,
    toasted: false,
  };

  static getDerivedStateFromError(err) {
    return { hasError: true };
  }

  componentDidCatch(err, info) {
    Promise.all([SystemJS.import("error-logging!sofe"), SystemJS.import("toast-service!sofe")]).then(
      ([errorLogging, toastService]) => {
        errorLogging.captureException(err);
        if (this.state.toasted === false) {
          toastService.warningToast({ message: this.props.message });
          this.setState({ toasted: true });
        }
      }
    );
  }

  render() {
    const { message } = this.props;
    if (this.state.hasError) {
      return <div className="cps-color-mandy">{message || "Unable to show"}</div>;
    } else {
      return this.props.children;
    }
  }
}
