// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-column-filters-search-and-select-filter-search-and-select-filter-styles__selectField {\n  width: 100%;\n  margin-bottom: 1.6rem;\n}\n", ""]);
// Exports
exports.locals = {
	"selectField": "src-client-list-column-filters-search-and-select-filter-search-and-select-filter-styles__selectField"
};
module.exports = exports;
