import React, { lazy, Suspense } from "react";
import { HashRouter, Route, Redirect } from "react-router-dom";
import { hot } from "react-hot-loader";
import { ErrorBoundary } from "error-logging!sofe";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient, queryContext } from "src/react-query";
// project
import ClientList from "./client-list/client-list.component";
import ContactHomePage from "./contact-home-page/contact-home-page.component.js";
const PortalRoutes = lazy(() => import("./portal/portal-routes.component"));
const ContactPage = lazy(() => import("./contact-page/contact-page.component.js"));
const CustomFieldsSettings = lazy(() =>
  import("./global-settings/custom-fields/settings-page/custom-field-settings.component.js")
);
const ImportContactsPage = lazy(() => import("./import/import-contacts-page.component"));
const TemplatesPage = lazy(() => import("./templates/templates-page.component"));
// sofe
import { hasAccess, UserTenantProps } from "cp-client-auth!sofe";
import { CpLoader } from "canopy-styleguide!sofe";

@ErrorBoundary({ featureName: "contacts" })
@hot(module)
@UserTenantProps({ waitForData: true })
export default class Root extends React.Component {
  constructor(props) {
    super(props);
    this.isClient = props.loggedInUser.role === "Client";
  }
  render() {
    if (this.isClient) {
      return null;
    }

    const userHasAccess = hasAccess(this.props.loggedInUser);

    return (
      <QueryClientProvider client={queryClient} context={queryContext}>
        <HashRouter>
          <Suspense
            fallback={
              <div className="cp-flex-center" style={{ height: "100vh" }}>
                <CpLoader size={"lg"} center />
              </div>
            }
          >
            <PrivateRoute
              path="/clients"
              render={(props) => <ClientList {...props} />}
              permitted={userHasAccess("contacts")}
            />
            <Route path="*/client(s)?/:id" component={ContactPage} />
            {this.props.loggedInUser.role !== "Client" && (
              <Route
                path="/client/:id"
                exact
                render={(props) => <ContactHomePage user={this.props.loggedInUser} {...props} />}
              />
            )}
            <PrivateRoute
              path="/contacts/portal"
              permitted={userHasAccess("contacts_manage_client_portals")}
              render={(props) => <PortalRoutes {...props} />}
            />
            <PrivateRoute
              path="/contacts/global-settings/custom-fields"
              permitted={userHasAccess("company_settings_custom_fields")}
              render={(props) => (
                <>
                  <Route
                    exact
                    path={props.match.url}
                    render={() => <Redirect to={props.match.url + "/individuals"} />}
                  />
                  <Route path={props.match.url + "/:contactType"} component={CustomFieldsSettings} />
                </>
              )}
            />
            <PrivateRoute
              path="/contacts/import"
              permitted={userHasAccess("contacts_import")}
              render={() => <ImportContactsPage />}
            />
            <PrivateRoute
              path="/templates/client-records"
              permitted={userHasAccess("templates_client_records")}
              render={() => <TemplatesPage />}
            />
          </Suspense>
        </HashRouter>
      </QueryClientProvider>
    );
  }
}

const PrivateRoute = ({ render, permitted, ...rest }) => (
  <Route {...rest} render={(props) => (permitted ? render(props) : <Redirect to="/403" />)} />
);
