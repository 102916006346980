// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-column-customization-modal-customize-columns-columns-section-styles__checkbox {\n  margin-bottom: 0.8rem;\n  flex: 0 1 16rem;\n  padding-right: 1.6rem;\n  min-height: 4rem;\n  padding-top: 0 !important;\n}\n.src-client-list-column-customization-modal-customize-columns-columns-section-styles__checkboxGroup {\n  display: flex;\n  flex-flow: row wrap;\n}\n", ""]);
// Exports
exports.locals = {
	"checkbox": "src-client-list-column-customization-modal-customize-columns-columns-section-styles__checkbox",
	"checkboxGroup": "src-client-list-column-customization-modal-customize-columns-columns-section-styles__checkboxGroup"
};
module.exports = exports;
