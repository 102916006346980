import React from "react";
import { CprButton } from "canopy-styleguide!sofe";
import AddAnotherPerson from "./add-another-person/add-another-person.component";
import ExistingInvite from "./existing-invite/existing-invite.component";
import { Scoped } from "kremling";
import styles from "./add-invites.krem.css";
export default class AddInvites extends React.Component {
  state = {
    addAnotherPerson: false,
  };

  componentDidMount() {
    this.props.newUsersToAdd && this.props.updateInvites([...this.props.invites, ...this.props.newUsersToAdd]);
  }

  render() {
    return (
      <Scoped css={styles}>
        <div ref={(ref) => (this.inviteScroll = ref)} className={"addInvites"}>
          {this.props.allInvites.map((invite, index) => {
            return (
              <ExistingInvite
                key={invite.email}
                invite={invite}
                invites={this.props.invites}
                handleToggleInvite={this.handleToggleInvite}
              />
            );
          })}
          {this.props.newUsersToAdd?.map((invite, index) => {
            return (
              <ExistingInvite
                key={invite.email}
                invite={invite}
                invites={this.props.invites}
                handleToggleInvite={this.handleToggleInvite}
                newUsersToAdd={this.props.newUsersToAdd}
              />
            );
          })}
          {this.state.addAnotherPerson && (
            <AddAnotherPerson
              removePerson={this.removePerson}
              allInvites={this.props.allInvites}
              getExistingUser={this.props.getExistingUser}
              addNewInvite={this.props.addNewInvite}
            />
          )}
          <div className={"inviteSlat"}>
            <CprButton
              actionType="flat"
              className={"addAnotherPerson"}
              onClick={this.addPerson}
              disabled={this.state.addAnotherPerson}
            >
              +Add another person
            </CprButton>
          </div>
        </div>
      </Scoped>
    );
  }

  addPerson = () => this.setState({ addAnotherPerson: true });

  removePerson = () => this.setState((prevState) => ({ addAnotherPerson: !prevState.addAnotherPerson }));

  handleToggleInvite = (invite, checked) => {
    if (checked) {
      this.props.updateInvites([...this.props.invites, invite]);
    } else {
      this.props.updateInvites(this.props.invites.filter((i) => i.email !== invite.email));
    }
  };
}
