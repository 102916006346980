import { isEmpty } from "lodash";

const SUGGESTED_INVITES_RELATIONSHIP_TYPE_WHITELIST = ["spouse", "other"];

export function getSuggestedInvites(contact) {
  const primaryEmail = contact.emails && contact.emails.find((email) => !isEmpty(email.value) && email.isPrimary);
  const contactInvites = primaryEmail ? [toInvite(contact, primaryEmail)] : [];
  const relationshipsInvites =
    contact.relationships && contact.relationships.contacts
      ? contact.relationships.contacts.reduce((invites, relationship) => {
          const relationshipEmail =
            relationship.emails && relationship.emails.find((email) => !isEmpty(email.value) && email.isPrimary);
          return (
            // Must be a whitelisted relationship type
            SUGGESTED_INVITES_RELATIONSHIP_TYPE_WHITELIST.includes(relationship.relationship_type) &&
              // Must have a non-blank email
              relationshipEmail &&
              relationshipEmail.value &&
              relationshipEmail.value.trim() &&
              // Must not already be included
              !invites.find((i) => i.email.toLowerCase() === relationshipEmail.value.toLowerCase()) &&
              !contactInvites.find((i) => i.email.toLowerCase() === relationshipEmail.value.toLowerCase())
              ? [...invites, toInvite(relationship, relationshipEmail)]
              : invites
          );
        }, [])
      : [];
  return [...contactInvites, ...relationshipsInvites];
}

export function getExistingUser(allUsers, email) {
  return allUsers ? allUsers.find((user) => user.email.toLowerCase() === email.toLowerCase()) : false;
}

export function groupNewAndExistingUsers(userInvites, existingUsers) {
  return userInvites.reduce(
    (all, invite) => {
      const existingUser = getExistingUser(existingUsers, invite.email);
      return existingUser
        ? {
            newUsers: all.newUsers,
            existingUserIds: [...all.existingUserIds, existingUser.id],
          }
        : {
            newUsers: [...all.newUsers, invite],
            existingUserIds: all.existingUserIds,
          };
    },
    { newUsers: [], existingUserIds: [] }
  );
}

function toInvite(contact, primaryEmail) {
  return {
    name: contact.name,
    first_name: contact.first_name,
    last_name: contact.last_name,
    email: primaryEmail.value,
    emailType: primaryEmail.key,
    relationship: contact.contact_type,
  };
}
