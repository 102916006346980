// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-client-list-styles__clientListContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  margin-left: 80px; /* 80px is the hardcoded width of the primary-navbar */\n  background: var(--cp-color-well-l1-bg);\n}\n\n.src-client-list-client-list-styles__topBar {\n  display: flex;\n  justify-content: space-between;\n  padding: 1.2rem;\n  padding-right: 2.4rem;\n}\n\n.src-client-list-client-list-styles__header {\n  display: flex;\n  align-items: center;\n}\n\n.src-client-list-client-list-styles__filterSection {\n  border-top: 0.1rem solid var(--cp-color-app-border);\n  border-bottom: 0.1rem solid var(--cp-color-app-border);\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  padding-left: 1.6rem;\n  padding-right: 2.4rem;\n  display: flex;\n  justify-content: space-between;\n}\n\n.src-client-list-client-list-styles__clientListTable {\n  overflow: auto;\n  flex-grow: 1;\n}\n\n.src-client-list-client-list-styles__bottomBar {\n  display: flex;\n  align-items: center;\n  align-self: center;\n  padding: 1.6rem;\n}\n.src-client-list-client-list-styles__searchInput {\n  width: 28.8rem;\n}\n\n.src-client-list-client-list-styles__limitedBulkWarning {\n  font-style: italic;\n  margin-left: 1.6rem;\n}\n", ""]);
// Exports
exports.locals = {
	"clientListContainer": "src-client-list-client-list-styles__clientListContainer",
	"topBar": "src-client-list-client-list-styles__topBar",
	"header": "src-client-list-client-list-styles__header",
	"filterSection": "src-client-list-client-list-styles__filterSection",
	"clientListTable": "src-client-list-client-list-styles__clientListTable",
	"bottomBar": "src-client-list-client-list-styles__bottomBar",
	"searchInput": "src-client-list-client-list-styles__searchInput",
	"limitedBulkWarning": "src-client-list-client-list-styles__limitedBulkWarning"
};
module.exports = exports;
