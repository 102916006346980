import { useQuery } from "src/react-query";
import { catchError } from "auto-trace";
import { getContact } from "src/resources/contacts.resource";

export default function useClientQuery(clientId, { includes } = {}) {
  const query = useQuery({
    queryKey: ["client", clientId, includes],
    queryFn: ({ signal }) =>
      new Promise((resolve, reject) => {
        const sub = getContact(clientId, includes, true).subscribe(resolve, reject);
        signal?.addEventListener("abort", () => sub.unsubscribe());
      }),
    onError: catchError(),
  });

  return {
    clientQuery: query,
    client: query.data,
  };
}
