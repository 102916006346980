// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-column-filters-common-sort-styles__sortContainer > .src-client-list-column-filters-common-sort-styles__row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  height: 2.4rem;\n}\n.src-client-list-column-filters-common-sort-styles__sortContainer > .src-client-list-column-filters-common-sort-styles__row__selected {\n  color: var(--cp-color-button-icon-active-text);\n}\n", ""]);
// Exports
exports.locals = {
	"sortContainer": "src-client-list-column-filters-common-sort-styles__sortContainer",
	"row": "src-client-list-column-filters-common-sort-styles__row",
	"row__selected": "src-client-list-column-filters-common-sort-styles__row__selected"
};
module.exports = exports;
