// @flow
import * as React from "react";
import { CprButtonIcon } from "canopy-styleguide!sofe";

type Props = {
  isPrimary: boolean,
  setPrimary: Function,
  html?: string,
};

export default function Star(props: Props) {
  return (
    <CprButtonIcon
      tooltip={props.html}
      icon={props.isPrimary ? "favorites" : "favorites-hollow"}
      onClick={props.setPrimary}
      customClass={props.isPrimary ? "cps-color-yellow" : "cps-color-monsoon"}
    />
  );
}
