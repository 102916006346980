// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-table-table-headers-styles__tableHeader {\n  text-align: left;\n  background: var(--cp-color-well-l1-bg);\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n\n.src-client-list-table-table-headers-styles__tableHeader th {\n  padding: 1.2rem;\n  border-right: 0.1rem solid var(--cp-color-app-border);\n  border-bottom: 0.1rem solid var(--cp-color-app-border);\n}\n\nth.src-client-list-table-table-headers-styles__selectCell {\n  background: var(--cp-color-well-l1-bg);\n  position: -webkit-sticky;\n  position: sticky;\n  left: 0;\n  padding: 0;\n  padding-left: 1.6rem;\n  width: 4rem;\n  z-index: 2;\n  border-right: none;\n}\n\n.src-client-list-table-table-headers-styles__dragRight::after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  border-right: 2px solid blue;\n  pointer-events: none;\n}\n\n.src-client-list-table-table-headers-styles__dragLeft::after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 100%;\n  height: 100%;\n  border-left: 2px solid blue;\n  pointer-events: none;\n}\n\n.src-client-list-table-table-headers-styles__isDragging * {\n  cursor: inherit !important;\n}\n", ""]);
// Exports
exports.locals = {
	"tableHeader": "src-client-list-table-table-headers-styles__tableHeader",
	"selectCell": "src-client-list-table-table-headers-styles__selectCell",
	"dragRight": "src-client-list-table-table-headers-styles__dragRight",
	"dragLeft": "src-client-list-table-table-headers-styles__dragLeft",
	"isDragging": "src-client-list-table-table-headers-styles__isDragging"
};
module.exports = exports;
