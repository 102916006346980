import React from "react";
import { Scoped } from "kremling";
// project
import styles from "./contact-information.krem.css";
import IndividualNameSection from "./individual-name-section/individual-name-section.component.js";
import BusinessNameSection from "./business-name/business-name.component.js";
import DisplayNameSection from "./display-name/display-name.component.js";
import { SharedValueDecorator } from "src/forms-lib";

@SharedValueDecorator
export default class ContactInformationSection extends React.Component {
  render() {
    return (
      <Scoped css={styles}>
        <div>{this.props.sharedValue ? <BusinessNameSection /> : <IndividualNameSection />}</div>
        <DisplayNameSection />
      </Scoped>
    );
  }
}
