import React, { useState, useEffect, useContext } from "react";
import styles from "./column-header.styles.css";
import { a } from "kremling";
import { isEmpty } from "lodash";

import { FilterContext } from "../../client-list.component";
import { columnWidthsPx } from "../columns.helper";

function ColumnHeader({
  HeaderComponent,
  column,
  isPinned,
  selectionCount,
  isDragTarget,
  dragStyle,
  onDragStart,
  onDragOver,
  onDrop,
  onDragEnd,
  onDrag,
}) {
  const [resizeStartPos, setResizeStartPos] = useState();
  const [resizeWidth, setResizeWidth] = useState();
  const [widthOverride, setWidthOverride] = useState();

  const defaultWidth = columnWidthsPx[column.width || "md"];
  const currentWidth = widthOverride || defaultWidth;

  useEffect(() => {
    if (!!resizeStartPos) {
      const minWidth = defaultWidth / 2;
      let override = 0;

      const onMouseMove = (e) => {
        const newWidth = currentWidth + (e.screenX - resizeStartPos);
        if (newWidth >= minWidth) {
          override = newWidth;
          setResizeWidth(newWidth);
        }
      };

      const onMouseUp = (e) => {
        setWidthOverride(override);
        setResizeStartPos(null);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp);
      return () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("mouseup", onMouseUp);
      };
    }
  }, [resizeStartPos, defaultWidth, currentWidth]);

  const { getFilter, getSort } = useContext(FilterContext);
  const hasFiltersApplied = !isEmpty(getFilter(column.filterField)?.[0]) || !!getSort(column.sortField)?.sort;

  return (
    <th
      style={{
        width: !!resizeStartPos ? `${resizeWidth}px` : `${currentWidth / 10}rem`,
      }}
      draggable={!isPinned && !resizeStartPos}
      className={a(styles.columnHeader)
        .m(styles.clientNameCell, column.id === "full_name")
        .m(dragStyle, isDragTarget)
        .m(styles.nonStickyColumn, !isPinned)
        .m(styles.filtersApplied, hasFiltersApplied)}
      onDrag={onDrag}
      onDragStart={(e) => {
        if (isPinned) return;
        e.dataTransfer.effectAllowed = "move";
        onDragStart(e, column.id);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        if (!isPinned && !isDragTarget) {
          onDragOver(e, column.id);
        }
      }}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
    >
      <HeaderComponent column={column} selectionCount={selectionCount} />
      <div
        className={styles.resizeHandle}
        onMouseDown={(e) => {
          e.preventDefault();
          setResizeStartPos(e.screenX);
          setResizeWidth(currentWidth);
        }}
      />
    </th>
  );
}
export default React.memo(ColumnHeader);
