import React, { useRef, useMemo, useState, useEffect } from "react";
import { debounce, pull } from "lodash";
import { catchError } from "auto-trace";
import { useQueryClient } from "src/react-query";

import styles from "./client-list-table.styles.css";
import TableHeaders from "./table-headers.component";
import TableBody from "./table-body.component";
import { buildColumnDefs, getDefaultVisibleColumns, sortColumns } from "./columns.helper";
import { patchFilterView } from "src/client-list/client-list.resource";
import useCustomFieldsQuery from "../use-custom-fields-query.hook";
import useQboIntegrationQuery from "../use-qbo-integration-query.hook";
import useEdgeScroll from "../use-edge-scroll.hook";
import useRolesQuery from "src/create-edit-contact/create-edit-contact-form/roles-section/use-roles-query-hook.js";

const initialColumnDefs = buildColumnDefs();

function ClientListTable({
  className,
  filterView,
  filterViewOverrides,
  clients,
  clientsQuery,
  infiniteScroll,
  selection,
  selectAll,
  deselectAll,
  toggleSelection,
  contextMenuActions,
  isSearching,
  onColumnOrderChange: onColumnOrderChangeProp,
  subView,
}) {
  const queryClient = useQueryClient();
  const { qboIntegrationInfo } = useQboIntegrationQuery();
  const tableContainerRef = useRef();

  const fetchNextPage = useMemo(() => debounce(() => clientsQuery.fetchNextPage(), 100), [clientsQuery]);

  const { roles } = useRolesQuery();
  const { customFields } = useCustomFieldsQuery();
  const defaultVisibleColumns = useMemo(() => {
    return getDefaultVisibleColumns(customFields?.map((f) => f.field_id));
  }, [customFields]);

  const [columnDefs, setColumnDefs] = useState(initialColumnDefs);
  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);
  const [columnOrder, setColumnOrder] = useState([]);
  const orderedColumns = useMemo(() => {
    let columns = [...(filterViewOverrides[filterView?.id]?.visible_columns || visibleColumns)];
    if (!qboIntegrationInfo?.connected) {
      pull(columns, "qbo");
    }
    if (subView?.id === "inactive") {
      pull(columns, "is_active");
    }

    const order = filterViewOverrides[filterView?.id]?.column_order || columnOrder;
    return sortColumns(columns, order);
  }, [visibleColumns, columnOrder, qboIntegrationInfo?.connected, filterViewOverrides, filterView, subView]);

  useEffect(() => {
    if (customFields && filterView) {
      const override = filterViewOverrides[filterView.id];
      const newColumnDefs = buildColumnDefs(customFields, roles);
      setColumnDefs(newColumnDefs);
      setColumnOrder(override?.column_order || filterView.filter_data.column_order || defaultVisibleColumns);

      const filterViewColumns = override?.visible_columns || filterView.filter_data.visible_columns;
      if (filterViewColumns?.length > 0) {
        const availableIds = new Set(Object.keys(newColumnDefs));
        setVisibleColumns(filterViewColumns.filter((id) => availableIds.has(id)));
      } else {
        setVisibleColumns(defaultVisibleColumns);
      }
    }
  }, [customFields, filterView, filterViewOverrides, defaultVisibleColumns, roles]);

  function onColumnOrderChange(orderedColumns) {
    setColumnOrder(orderedColumns);
    onColumnOrderChangeProp(orderedColumns);
    if (!filterView.read_only && filterView.created_by !== "__DEFAULT") {
      patchFilterView(filterView.id, {
        filter_data: {
          ...filterView.filter_data,
          column_order: orderedColumns,
        },
      }).subscribe(() => {
        queryClient.invalidateQueries(["filter-views"]);
      }, catchError());
    }
  }

  function onScroll(e) {
    if (!infiniteScroll) return;
    const scrollAmt = e.target.scrollTop + e.target.clientHeight;
    const nearBottom = scrollAmt >= e.target.scrollHeight * 0.85;
    if (nearBottom) {
      if (!clientsQuery.isFetchingNextPage && clientsQuery.hasNextPage) {
        fetchNextPage();
      }
    }
  }

  const { runEdgeScroller, stopEdgeScroller } = useEdgeScroll({
    scrollContainerRef: tableContainerRef,
    leftScrollAt: 280,
    rightScrollAt: 90,
    scrollSpeed: 2,
  });

  function onDrag(e) {
    runEdgeScroller(e);
  }

  function onDragEnd() {
    stopEdgeScroller();
  }

  return (
    <div className={className}>
      <div ref={tableContainerRef} className={styles.tableContainer} onScroll={onScroll}>
        <table className={styles.clientTable}>
          <TableHeaders
            orderedColumns={orderedColumns}
            columnDefs={columnDefs}
            setColumnOrder={onColumnOrderChange}
            selection={selection}
            selectAll={selectAll}
            deselectAll={deselectAll}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
          />
          <TableBody
            tableContainerRef={tableContainerRef}
            orderedColumns={orderedColumns}
            columnDefs={columnDefs}
            clients={clients}
            selection={selection}
            toggleSelection={toggleSelection}
            isLoading={clientsQuery.isLoading}
            isSearching={isSearching}
            contextMenuActions={contextMenuActions}
          />
        </table>
      </div>
    </div>
  );
}

export default React.memo(ClientListTable);
