import React from "react";
import { MultiSelect } from "src/form/multi-select/multi-select-input.component.js";

export function CustomFieldMultiSelectDropdown({ value, onChange, field }) {
  const options = mapDropdownToOptions();

  return (
    <MultiSelect
      labelText={field.field_name}
      value={value}
      updateValue={onChange}
      options={options}
      width={"100%"}
      listWidth={"100%"}
    />
  );

  function mapDropdownToOptions() {
    return field.dropdown_values.map((option) => ({ key: option.id, value: option.value }));
  }
}
