import { of, zip } from "rxjs";
import { pluck, concatMap, map, tap } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";
import { hasAccess } from "cp-client-auth!sofe";
import { find } from "lodash";
import { updateSource } from "./contact-sources.resource.js";

export function updateContact(loggedInUser, contact, originalTags, roles) {
  const rolesUsersTeams = contact.rolesUsersTeams;
  // We don't want to send this up for api/contacts, only for the assignments endpoint
  delete contact.rolesUsersTeams;

  const updateContactStream = fetchAsObservable(
    `api/contacts/${contact.id}?include=users,contacts,tags,contact_for,contact_sources`,
    {
      method: "PUT",
      body: {
        contacts: contact,
      },
    }
  ).pipe(pluck("clients"));

  const updateTags = updateAllTags(originalTags, contact.tags, contact.id);

  let updateRoles;
  if (hasAccess(loggedInUser)("contacts_assign_team_members")) {
    const roleIdsToUpdate = rolesUsersTeams.role_assignments.map((r) => r.role_id);
    const emptyRoles = [];
    // Populate empty roles and concat them to the existing roles so that BE knows to clear them
    roles.forEach((r) => {
      if (!roleIdsToUpdate.includes(r.id)) {
        emptyRoles.push({
          role_id: r.id,
          teams: [],
          users: [],
        });
      }
    });
    updateRoles = putRoleAssignments(contact.id, {
      ...rolesUsersTeams,
      role_assignments: rolesUsersTeams.role_assignments.concat(emptyRoles),
    });
  } else {
    updateRoles = of([]);
  }

  return updateTags.pipe(
    concatMap((tagsResponse) => updateSource(contact, contact)),
    concatMap(() => updateRoles),
    concatMap((res) => updateContactStream),
    tap((contact) => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent(`cp:contacts:${contact.id}-edited`));
      }, 0);
    })
  );
}

export function patchContactRelationships(contact, relationships) {
  return fetchAsObservable(`api/contacts/${contact.id}?include=users,contacts,tags,contact_for,contact_sources`, {
    method: "PATCH",
    body: {
      contacts: {
        relationships,
      },
    },
  }).pipe(pluck("clients"));
}

function updateAllTags(oTags, tags = [], contactId) {
  const tagsToRemove = oTags.reduce((acc, oTag) => {
    if (tags.includes(oTag.name)) {
      return acc;
    } else {
      return acc.concat(oTag.id);
    }
  }, []);
  const tagsToAdd = tags.filter((tag) => !find(oTags, (oTag) => oTag.name === tag));
  return zip(
    tagsToRemove.length > 0 ? removeTags(tagsToRemove, contactId) : of(tagsToRemove),
    tagsToAdd.length > 0 ? addTagsToContact(tagsToAdd, contactId) : of(tagsToAdd)
  );
}

function removeTags(tagIds = [], contactId) {
  const all = tagIds.map((tagId) =>
    fetchAsObservable(`api/contacts/${contactId}/tags/${tagId}`, {
      method: "DELETE",
    })
  );
  return zip(...all);
}

export function createContact(loggedInUser, contact, withEvent = true) {
  const rolesUsersTeams = contact.rolesUsersTeams;
  // We don't want to send this up for api/contacts, only for the assignments endpoint
  delete contact.rolesUsersTeams;

  const tags = contact.tags;
  // We don't want to send this up for api/contacts, only for the tags endpoint
  delete contact.tags;

  const createContactStream = fetchAsObservable(`api/contacts`, {
    method: "POST",
    body: { contacts: contact },
  }).pipe(pluck("clients"));

  return createContactStream.pipe(
    concatMap((contactResults) => {
      let tags$;
      if (tags != undefined && tags.length > 0) {
        tags$ = addTagsToContact(tags, contactResults.id).pipe(map((value) => contactResults));
      } else {
        tags$ = of(contactResults);
      }
      let source$;
      if (contact.source && contact.source.name) {
        source$ = updateSource(contact, contactResults);
      } else {
        source$ = of(contactResults);
      }

      let roles$;
      if (rolesUsersTeams && hasAccess(loggedInUser)("contacts_assign_team_members")) {
        roles$ = putRoleAssignments(contactResults.id, rolesUsersTeams);
      } else {
        roles$ = of(contactResults);
      }

      return zip(tags$, source$, roles$).pipe(
        map(([tags, source, roles]) => {
          return contactResults;
        })
      );
    }),
    tap((contact) => {
      setTimeout(() => {
        if (withEvent) {
          window.dispatchEvent(new CustomEvent(`cp:contacts:global-contact-created`));
        }
      }, 0);
    })
  );
}

function addTagsToContact(tags = [], contactId) {
  if (contactId == undefined) {
    throw new Error("cannot add Tags to an undefined contact");
  }
  return fetchAsObservable(`api/tags`, {
    method: "POST",
    body: {
      tags: createTags(tags, contactId),
    },
  });
}

function createTags(tags, contactId) {
  return tags.map((tag) => {
    return {
      name: tag.name,
      relationship_type: "contacts",
      relationship_id: contactId,
    };
  });
}

export function putRoleAssignments(clientId, body) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}
