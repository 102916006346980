// @flow
import * as React from "react";
import { uniqueId } from "lodash";
import { Scoped } from "kremling";
// project
import styles from "./date-input.krem.css";
import { InputDecorator } from "src/forms-lib";
// sofe
import { CprDatepicker } from "canopy-styleguide!sofe";

type Props = {
  // From Decorator
  name: string,
  value: string,
  updateValue: (string) => void,
  valid?: boolean,
  // other
  label?: *,
  placeholder?: string,
  orientation?: string,
  setFocus?: Function,
};

export class DateInput extends React.Component<Props> {
  static defaultProps = {
    value: "",
    updateValue: () => {},
    setFocus: () => {},
  };

  id: string = uniqueId(`datepicker_`);

  render() {
    return (
      <Scoped css={styles}>
        <div>
          {this.label()}
          <CprDatepicker
            id={this.id}
            placeholder={this.props.placeholder || "mm/dd/yyyy"}
            inputClass={"cps-form-control"}
            orientation={this.props.orientation || "bottom"}
            date={this.props.value}
            events={{
              datechange: this.updateDate,
            }}
            setFocus={this.props.setFocus}
          />
        </div>
      </Scoped>
    );
  }

  label = (): React.Element<*> | null => {
    if (this.props.label) {
      if (typeof this.props.label === "function") {
        return this.props.label({ valid: this.props.valid });
      } else {
        return this.props.label;
      }
    } else {
      return null;
    }
  };

  updateDate = (evt: CustomEvent) => {
    const date = evt.detail;
    this.props.updateValue(date);
  };
}

export default InputDecorator(DateInput);
