// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-table-headers-client-name-header-styles__selectionText {\n  font-weight: normal;\n  font-size: 1.2rem;\n  margin-left: 1.6rem;\n  font-style: italic;\n}\n", ""]);
// Exports
exports.locals = {
	"selectionText": "src-client-list-table-headers-client-name-header-styles__selectionText"
};
module.exports = exports;
