import React from "react";
import PropTypes from "prop-types";
import { lookUpColumnDataType } from "../dedupe-modal.helper.js";
import styles from "./resolved-contact-table.styles.css";
import { formatData, non_display_columns_for_resolved_row } from "../common/common.helper.js";

export default class ResolvedConflictTable extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    crmColumns: PropTypes.array.isRequired,
    rowsToDisplay: PropTypes.array.isRequired,
    deleteResolution: PropTypes.func.isRequired,
  };

  render() {
    const { title, crmColumns, rowsToDisplay, deleteResolution, isBusiness } = this.props;
    const columnKeys = this.getOrderedColumnKeys(crmColumns, rowsToDisplay);

    return (
      <div className={`${styles.tableSpacing}`}>
        <table className={`${styles.resolvedTable} cps-caption cps-medium-gray`} style={{ "border-bottom": "hidden" }}>
          <thead>
            <tr>
              <th className={`${styles.tableHead}`}>
                <span className="cps-pull-left cps-link +disabled cps-caption cps-medium-gray">{title}</span>
                <span onClick={deleteResolution} className="cps-pull-right cps-link cps-caption cps-medium-gray">
                  Undo
                </span>
              </th>
            </tr>
          </thead>
        </table>
        <div style={{ overflow: "auto" }}>
          <table className={`${styles.resolvedTable} cps-caption cps-medium-gray`}>
            <tbody>
              {rowsToDisplay.map((row, position) =>
                this.generateRow(row, columnKeys, position, crmColumns, row.is_business)
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  generateRow(row, columnKeys, position, crmColumns, isBusiness) {
    return (
      <tr key={position} className={`${styles.tableBody}`}>
        {columnKeys.map((key, position) => {
          return !non_display_columns_for_resolved_row.includes(key) ? (
            <td key={position} className="cps-padding-left-16">
              {this.valuesList(row[key], lookUpColumnDataType(crmColumns, key), isBusiness)}
            </td>
          ) : null;
        })}
      </tr>
    );
  }

  valuesList(value, formatType, isBusiness) {
    if (Array.isArray(value)) {
      return value.map((val, pos) => <div key={pos}>{formatData(val, formatType, isBusiness)}</div>);
    } else if (!value) {
      return <div></div>;
    } else {
      return <div>{formatData(value, formatType, isBusiness)}</div>;
    }
  }

  getOrderedColumnKeys(crmColumns, rowsToDisplay) {
    const nameVals = ["first_name", "middle_initial", "last_name", "business_name"];
    //convert to keys and remove nameVals
    const crmColumnKeys = crmColumns.map((col) => col.name).filter((colName) => !~nameVals.indexOf(colName));
    //Show nameVals first, then the all other crmColumns values (minus the nameVals)
    const keyOrder = [...nameVals, ...crmColumnKeys];
    //Remove any keys that aren't used by rowsToDisplay
    return keyOrder.filter((key) => rowsToDisplay.filter((row) => row[key]).length);
  }
}
