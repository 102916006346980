// @flow
import React from "react";
// project
import { SharedValueDecorator } from "src/forms-lib";
import DateInput from "src/form/date-input/date-input.component.js";
import { FieldLabel } from "src/common/client-record-fields";

type Props = {
  sharedValue: string,
};

@SharedValueDecorator
export default class ClientSince extends React.Component<Props> {
  static defaultProps = {
    sharedValue: "",
  };

  initialValue = new Date();

  render() {
    if (this.props.sharedValue === "client") {
      return (
        <DateInput
          name="client_since"
          initialValue={this.initialValue}
          label={
            <FieldLabel>
              <span>Client since</span>
            </FieldLabel>
          }
          orientation="bottom left"
        />
      );
    } else {
      return null;
    }
  }
}
