import { useQuery } from "src/react-query";
import { catchError } from "auto-trace";
import { getIntegrations } from "src/resources/contacts.resource.js";

// Returns whether qbo is connected or not
export default function useQboIntegration() {
  const query = useQuery({
    queryKey: ["qbo-integration"],
    queryFn: () =>
      new Promise((resolve, reject) => {
        getIntegrations().subscribe((integrations) => {
          const qboIntegrationInfo = integrations.find((i) => i.type === "qbo") || {};
          resolve(qboIntegrationInfo);
        }, reject);
      }),
    onError: catchError(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return {
    qboIntegrationInfo: query.data,
  };
}
