import React from "react";
import PropTypes from "prop-types";
import { DateInput } from "src/form/date-input/date-input.component";
import { FieldLabel } from "src/common/client-record-fields";

CustomFieldDate.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
};

export function CustomFieldDate({ field, value, onChange }) {
  return (
    <DateInput
      label={
        <FieldLabel>
          <span>{field.field_name}</span>
        </FieldLabel>
      }
      value={value}
      updateValue={onChange}
    />
  );
}
