import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./individual-name-section.krem.css";
import Input from "src/form/input/input.component.js";
import FirstName from "./first-name.component.js";
import { FieldLabel } from "src/common/client-record-fields";

const maxLength = 150;
export default class IndividualNameSection extends React.Component<Props, State> {
  render() {
    return (
      <Scoped css={styles}>
        <div className="nameBlocks margin-between">
          <div className={"nameBlock firstName"}>
            <FirstName maxLength={maxLength} />
          </div>
          <div className={"nameBlock"}>
            <Input
              type="text"
              placeholder="Middle name"
              validationFn={(name) => (name ? name.length < maxLength : true)}
              name="middle_name"
              label={
                <FieldLabel>
                  <span>Middle</span>
                </FieldLabel>
              }
            />
          </div>
          <div className={"nameBlock lastName"}>
            <Input
              type="text"
              placeholder="Last name"
              validationFn={(name) => name.length < maxLength}
              name="last_name"
              required={true}
              invalidMessage={(value) => {
                if (!value) {
                  return "Last name is required";
                } else if (value.length >= maxLength) {
                  return `Last name must be less than ${maxLength} characters`;
                }
              }}
              label={this.getLastLabel}
            />
          </div>
        </div>
      </Scoped>
    );
  }

  getLastLabel = ({ valid }) => {
    return (
      <FieldLabel>
        <span>Last name</span>
        <span className={valid ? "cps-color-primary" : undefined}>*</span>
      </FieldLabel>
    );
  };

  invalidName = () => {
    return !this.state.firstNameValid || !this.state.lastNameValid;
  };
}
