// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-column-filters-bool-filter-bool-filter-styles__checkbox {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n", ""]);
// Exports
exports.locals = {
	"checkbox": "src-client-list-column-filters-bool-filter-bool-filter-styles__checkbox"
};
module.exports = exports;
