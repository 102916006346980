export function sortConflicts(crmColumns, conflicts) {
  const sortedConflicts = [];
  crmColumns.map((column) => {
    const index = conflicts.findIndex((conflict) => column.name === conflict.name);
    if (~index) {
      sortedConflicts.push(conflicts[index]);
    }
  });
  return sortedConflicts;
}
