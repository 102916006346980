import { track as cpTrack } from "cp-analytics";

export function notifyAnalytics(user, eventName, dataObject) {
  if (!user) {
    return;
  }
  const user_id = user.id;
  const tenant_id = user.tenant_id;
  const dataObj = { user_id, tenant_id, ...dataObject };

  cpTrack("practice_management", "contacts", eventName, dataObj);
}

export const track = (event, data) => {
  return cpTrack("practice_management", "clients", event, data);
};
