// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-dedupe-modal-dedupe-modal-styles__modalContent {\n  position: fixed;\n  top: 0;\n  left: 0;\n  min-width: 500px;\n  width: 100%;\n  height: 100%;\n  background-color: #f4f5f8;\n  display: flex;\n  flex-direction: column;\n  z-index: 10000;\n  overflow: hidden;\n}\n\n.src-dedupe-modal-dedupe-modal-styles__mainContent {\n  padding: 32px 24px;\n  height: calc(100% - 112px);\n  overflow: auto;\n}\n\n.src-dedupe-modal-dedupe-modal-styles__fullHeight {\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"modalContent": "src-dedupe-modal-dedupe-modal-styles__modalContent",
	"mainContent": "src-dedupe-modal-dedupe-modal-styles__mainContent",
	"fullHeight": "src-dedupe-modal-dedupe-modal-styles__fullHeight"
};
module.exports = exports;
