import React, { useState } from "react";
import { CpButton, CpCard, CpInput } from "canopy-styleguide!sofe";
import { catchError } from "auto-trace";
import { useQueryClient } from "src/react-query";
import { patchFilterView } from "../../client-list.resource";

export default function RenameFilterView(props) {
  const { filterView, onClose } = props;
  const queryClient = useQueryClient();

  const [value, setValue] = useState(filterView.name);

  function cancel() {
    onClose();
  }

  function renameFilterView() {
    patchFilterView(filterView.id, { name: value }).subscribe((res) => {
      queryClient.invalidateQueries(["filter-views"]);
      onClose();
    }, catchError());
  }

  return (
    <CpCard>
      <CpCard.Body>
        <CpInput
          label="Rename"
          value={value}
          onChange={setValue}
          onKeyDown={(e) => e.code === "Enter" && renameFilterView()}
        />
      </CpCard.Body>
      <CpCard.Footer className="cp-flex">
        <CpButton btnType="primary" className="cp-mr-8" disabled={value === filterView.name} onClick={renameFilterView}>
          Done
        </CpButton>
        <CpButton btnType="flat" onClick={cancel}>
          Cancel
        </CpButton>
      </CpCard.Footer>
    </CpCard>
  );
}
