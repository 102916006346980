import React from "react";
import { useHasAccess } from "cp-client-auth!sofe";
import RoleAssignmentSelect from "src/form/role-assignment-select/role-assignment-select.component";
import styles from "./users-teams-section.styles.css";
import { CpIcon } from "canopy-styleguide!sofe";

export default function UsersTeamsSection({
  value,
  onChange,
  requiresAccess = true,
  hasRoles,
  showCurrentUserNotAssigned = false,
}) {
  const hasAssignAccess = useHasAccess("contacts_assign_team_members");
  const disabled = !(requiresAccess ? hasAssignAccess : true);

  return (
    <div>
      <div className={`cp-body-sm ${hasRoles ? "cp-mb-4 cp-pt-16" : "cp-wt-semibold cp-mb-16"}`}>
        General Users/Teams
      </div>
      {!hasRoles && showCurrentUserNotAssigned && (
        <div className="cp-mb-4 cp-caption">
          <CpIcon name="information-circle-open-small" />
          <i>Note: You are currently not assigned to this client and will be unable to access their client record.</i>
        </div>
      )}
      <div className={styles.container}>
        <RoleAssignmentSelect disabled={disabled} value={value?.filter((item) => !!item) || []} onChange={onChange} />
      </div>
    </div>
  );
}
