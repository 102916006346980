import React, { useMemo, useState } from "react";
import { CpSelectMultiPills } from "canopy-styleguide!sofe";
import useTeamMembersQuery from "src/client-list/use-team-members-query.hook";
import { FieldLabel } from "src/common/client-record-fields";

export default function RoleAssignmentSelect({ label, value, onChange, disabled }) {
  const [search, setSearch] = useState("");

  const { teamMembers: users, teams } = useTeamMembersQuery();

  const groupData = useMemo(() => {
    return [
      {
        id: 0,
        name: "Users",
        icon: "person",
        data: users.map((u) => ({
          ...u,
          name: u.profile_name || u.name,
          namechip: {
            name: u.name,
            imgSrc: u.profile_picture_url,
          },
        })),
      },
      {
        id: 1,
        name: "Teams",
        icon: "person-people",
        data: teams.map((t) => ({
          ...t,
          namechip: {
            team: {
              name: t.name,
              color_hex: t.color_hex,
            },
          },
        })),
      },
    ];
  }, [users, teams]);

  return (
    <div className="cp-flex-column">
      {!!label && <FieldLabel className="cp-ellipsis">{label}</FieldLabel>}
      <CpSelectMultiPills
        disabled={disabled}
        data={groupData}
        teamMember
        insertSearch
        isGroupData
        renderValue={(item) => (item.length === 1 ? item[0].name : `${item[0].name} +${value.length - 1} more`)}
        searchFilter={(item) => item.name.toLowerCase().includes(search.trim().toLowerCase())}
        searchOnChange={setSearch}
        searchValue={search}
        triggerIsBlock
        placeholder="- Select -"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
