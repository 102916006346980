import React, { useEffect, useState, useCallback, lazy, Suspense, useRef } from "react";
import { NavContent, checkPermissions } from "primary-navbar!sofe";
import { useCss } from "kremling";
import { CprLoader } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import ContactHomeCard from "./contact-home-card.component";
import NotesList from "./notes-list.component";
import ResolutionCasesList from "./resolution-cases-list.component";
import FilesList from "./files-list.component";
const TasksList = lazy(() =>
  SystemJS.import("tasks-ui!sofe")
    .then((tasks) => tasks.getTasksList())
    .then((mod) => mod)
);
const CalendarCard = lazy(() => import("calendar-ui!sofe").then((cal) => cal.getContactCalendarCard()));
import { fromEvent } from "rxjs";
import { catchAsyncStacktrace } from "auto-trace";
import { useHasAccess } from "cp-client-auth!sofe";
import { getContact } from "src/resources/contacts.resource";

export default function ContactHomePage(props) {
  const [contact, setContact] = useState();
  const [buttonClick, setButtonClick] = useState(0); // a way to connect "add a note" click with notesList
  const permissions = useRef({ transcriptsOnly: checkPermissions(props.user, "transcripts-only") });
  const resolutionCasePerm = useHasAccess(
    featureEnabled("toggle_engagements_rename") ? "tasks_resolution_cases" : "tasks_engagements"
  );
  const tasksPerm = useHasAccess("task_dashboard");
  const hasContactFilesAccess = useHasAccess("files_contact");
  const hasCommunications = useHasAccess("communications");
  const hasNotesAccess = useHasAccess("notes");
  const { history, match } = props;
  const { id: contactId } = match.params;

  const getContactCallback = useCallback(() => {
    const req$ = getContact(contactId, "users,contacts,tags,contact_for,contact_sources,integrations").subscribe(
      (contact) => {
        setButtonClick(0); // reset to 0 otherwise modal will automatically show
        setContact(contact);
      },
      catchAsyncStacktrace()
    );
    return req$;
  }, [contactId]);

  useEffect(() => {
    const req$ = fromEvent(window, "contacts-ui::contact-updated").subscribe(getContactCallback);
    return () => {
      req$.unsubscribe();
    };
  }, []); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  useEffect(() => {
    setContact();
    const req$ = getContactCallback();
    return () => req$.unsubscribe();
  }, [contactId]); // eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  function renderCards() {
    const taskListCard = (
      <div className="block-item" key="task">
        <ContactHomeCard
          title="Tasks"
          buttonText="View all tasks"
          buttonAction={() => history.push(`/tasks/clients/${contactId}`)}
        >
          <Suspense fallback={<Loader />}>
            <TasksList contactId={contactId} buttonClick={() => history.push(`/tasks/clients/${contactId}`)} />
          </Suspense>
        </ContactHomeCard>
      </div>
    );

    const filesListCard = (
      <div className="block-item" key="files">
        <ContactHomeCard
          title="Recent Files"
          buttonText="View all files"
          buttonAction={() => history.push(`/docs/clients/${contactId}/files`)}
        >
          <FilesList contactId={contactId} buttonClick={() => history.push(`/docs/clients/${contactId}/files`)} />
        </ContactHomeCard>
      </div>
    );

    const notesListCard = (
      <div className="block-item" key="notes">
        <ContactHomeCard title="Notes" buttonText="Add a note" buttonAction={() => setButtonClick((val) => val + 1)}>
          <NotesList
            contactId={contactId}
            history={history}
            buttonClick={buttonClick}
            setButtonClick={() => setButtonClick((val) => val + 1)}
          />
        </ContactHomeCard>
      </div>
    );

    const resolutionCaseListCard = (
      <div className="block-item" key="resolutionCases">
        <ContactHomeCard
          title={featureEnabled("toggle_engagements_rename") ? "Resoution Cases" : "Engagements"}
          buttonText={featureEnabled("toggle_engagements_rename") ? "Create resoution case" : "Create engagement"}
          buttonAction={() =>
            history.push(
              `/client/${contactId}/${
                featureEnabled("toggle_engagements_rename") ? "resolution-cases" : "engagements"
              }/create`
            )
          }
        >
          <ResolutionCasesList
            contactId={contactId}
            history={history}
            buttonClick={() =>
              history.push(
                `/client/${contactId}/${
                  featureEnabled("toggle_engagements_rename") ? "resolution-cases" : "engagements"
                }/create`
              )
            }
          />
        </ContactHomeCard>
      </div>
    );

    const canShowTasksList = contact.is_active && tasksPerm;
    const canShowFilesList = contact.is_active && hasContactFilesAccess;
    const canShowNotesList = hasNotesAccess;
    const canShowResolutionCaseList = resolutionCasePerm;

    let cards = [];
    if (permissions.current.transcriptsOnly) {
      cards = [canShowNotesList && notesListCard];
    } else {
      cards = [
        canShowTasksList && taskListCard,
        canShowFilesList && filesListCard,
        canShowNotesList && notesListCard,
        canShowResolutionCaseList && resolutionCaseListCard,
      ];
    }

    cards = cards.filter((card) => card);

    return (
      <>
        <div className="cps-flexible-focus flex-container">{cards}</div>
        {hasCommunications && (
          <div className="cps-card cps-flexible-focus">
            <Suspense fallback={<Loader />}>
              <CalendarCard contact={contact} />
            </Suspense>
          </div>
        )}
      </>
    );
  }

  return (
    <NavContent hasTopnavSecondary={false} contactMenuPossible={true} className={"with-sidebar"}>
      <div {...useCss(styles)}>{contact ? renderCards() : <Loader />}</div>
    </NavContent>
  );
}

const Loader = () => (
  <div className="cps-margin-top-32">
    <CprLoader dotSize={16} />
  </div>
);

const styles = `
& .flex-container {
  display: flex;
  flex-wrap: wrap;
}
& .block-item {
  width: calc(50% - 2.4rem);
  flex-grow: 1;
  margin-right: 2.4rem;
  margin-bottom: 2.4rem;
  height: 50rem;
}
& .block-item:nth-child(even),
& .block-item:last-child {
  margin-right: 0;
}
`;
