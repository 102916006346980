import React, { createContext, useState, useEffect, useMemo, useRef } from "react";
import { CpButton, CpTooltip, CpInput, CpSelectSingle, CpPagination, useDebounce } from "canopy-styleguide!sofe";
import { throttle, isEqual, isEmpty, differenceWith, toPairs } from "lodash";
import { a } from "kremling";
import { decode } from "querystring";

import styles from "./client-list.styles.css";
import AddClientButton from "./add-client-button.component";
import ClientListTable from "./table/client-list-table.component";
import ColumnCustomization from "./column-customization-modal/column-customization.component";
import KabobMenu from "./kabob-menu.component";
import FilterViewTabs from "./filter-view-tabs.component";
import BulkActionList from "./bulk-actions/bulk-action-list.component";
import SaveFilterDropdown from "./save-filter-dropdown.component";
import ExportClientListButton from "./export-client-list-button.component";
import SubViewActionButton from "./subview-action-button.component";
import { getDefaultVisibleColumns } from "./table/columns.helper";
import { track } from "src/resources/analytics.resource";
import showDedupeModal from "src/dedupe-modal/dedupe-modal.component";

import useClientsQuery from "./use-clients-query.hook";
import useFilterViewsQuery from "./use-filter-views-query.hook";
import useSelections from "./use-selections.hook";
import useCustomFieldsQuery from "./use-custom-fields-query.hook";

const pageLimitData = [
  { id: "50", name: "50" },
  { id: "100", name: "100" },
  { id: "200", name: "200" },
  { id: "all", name: "All" },
];

export const FilterContext = createContext({
  filters: () => {},
  setFilters: () => {},
  sortData: () => {},
  setSortData: () => {},
});

export default function ClientList(props) {
  const [pageLimit, setPageLimit] = useState();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sortData, setSortData] = useState([]);
  const [subView, _setSubView] = useState(null);
  const [moveToFilterView, setMoveToFilterView] = useState(null);

  // Need this to reset CpPagination until that component allows us to control current page
  const filtersString = useMemo(() => JSON.stringify(filters), [filters]);

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const debounceSearch = useDebounce((val) => setDebouncedSearch(val), 500, {}, []);
  useEffect(() => {
    const trimmedSearch = debouncedSearch.trim();
    if (trimmedSearch) {
      track("client_list.search-executed", { search: trimmedSearch });
    }
  }, [debouncedSearch]);

  const infiniteScroll = pageLimit?.id === "all";

  const filterContextValues = useMemo(
    () => ({
      getFilter: (filterField) => filters[filterField],
      setFilter: (filterField, filterData, columnDef) =>
        setFilters((prev) => {
          const newFilters = { ...prev };
          if (isEmpty(filterData)) {
            delete newFilters[filterField];
          } else {
            if (!!columnDef.customField) {
              const type = columnDef.customField.field_type;
              filterData = filterData.map((data) => ({
                ...data,
                field_type: type === "multiselect" ? "dropdown" : type,
              }));
            }
            newFilters[filterField] = filterData;
          }
          const appliedFilter = differenceWith(toPairs(newFilters), toPairs(prev), isEqual)[0];
          if (appliedFilter) {
            track("client_list.filter-applied", {
              field: appliedFilter[0],
              value: appliedFilter[1],
            });
          }
          return newFilters;
        }),
      getSort: (sortField) => sortData.find((el) => el.field === sortField) || null,
      setSort: (sortObj) =>
        setSortData((prev) => {
          const i = prev.findIndex((el) => el.field === sortObj.field);
          const newSortData = [...prev];
          if (i < 0 && !!sortObj.sort) {
            newSortData.push(sortObj);
          } else if (i >= 0 && !!sortObj.sort) {
            newSortData[i] = sortObj;
          } else if (i >= 0 && !sortObj.sort) {
            newSortData.splice(i, 1);
          }
          const appliedSort = differenceWith(newSortData, prev, isEqual)[0];
          if (appliedSort) {
            track("client_list.sort-applied", {
              field: appliedSort.field,
              sort: appliedSort.sort,
            });
          }
          return newSortData;
        }),
    }),
    [filters, sortData]
  );

  const { filterViewsQuery, filterViews } = useFilterViewsQuery();
  const visibleFilterViews = useMemo(() => filterViews.filter((fv) => !fv.hidden), [filterViews]);
  const [filterViewOverrides, setFilterViewOverrides] = useState({});

  const [activeFilterViewId, setActiveFilterViewId] = useState(null);

  const filterView = useMemo(
    () => visibleFilterViews.find((f) => f.id === activeFilterViewId),
    [visibleFilterViews, activeFilterViewId]
  );

  // Overlay the dedupe modal when import ids are passed
  useEffect(() => {
    const theQueryString = props.location?.search || "";
    const parsedQueryString = decode(theQueryString.toString().replace("?", ""));
    if (parsedQueryString?.import_id) {
      showDedupeModal({
        context: {},
        id: parsedQueryString.import_id,
        invalid_rows: parsedQueryString.invalid_rows,
      });
    }
  }, [props.location?.search]);

  // Set default filter view to the first once we've loaded the views
  useEffect(() => {
    if (!filterView && visibleFilterViews.length > 0) {
      setActiveFilterViewId(visibleFilterViews[0].id);
    }
  }, [filterView, visibleFilterViews]);

  const prevFilterViewId = useRef();
  useEffect(() => {
    if (filterView && prevFilterViewId.current !== filterView.id) {
      setFilters(filterView.filter_data?.filters || {});
      setSortData(filterView.filter_data?.sort_data || []);
      prevFilterViewId.current = filterView.id;
    }
  }, [filterView]);

  useEffect(() => {
    // When a new view is created we want to switch to it, so we need to wait until filterViews has been refetched
    if (moveToFilterView) {
      const view = visibleFilterViews.find((f) => f.id === moveToFilterView);
      if (view) {
        setActiveFilterViewId(view.id);
        setMoveToFilterView(null);
      }
    }
  }, [moveToFilterView, visibleFilterViews]);

  const newFiltersCount = useMemo(() => {
    if (!filterView) return 0;

    const appliedFilters = Object.keys(filters).filter((name) => filters[name]?.length > 0);
    const filterFieldsToCheck = new Set([
      ...appliedFilters,
      ...Object.keys(filterView.filter_data?.filters || {}),
      ...(filterView?.sort_data?.map((d) => d.field) || []),
      ...sortData.map((d) => d.field),
    ]);
    const newFilters = new Set(); // A list of filter fields that differ from the filter view

    filterFieldsToCheck.forEach((name) => {
      const filterViewFilter = filterView.filter_data?.filters?.[name] || [];
      const filter = filters[name] || [];
      if (!isEqual(filter, filterViewFilter)) {
        newFilters.add(name);
      }

      const filterViewSortData = filterView.filter_data?.sort_data?.find((d) => d.field === name && !!d.sort) || {};
      const fieldSortData = sortData.find((d) => d.field === name && !!d.sort) || {};

      if (!isEqual(fieldSortData, filterViewSortData)) {
        newFilters.add(name);
      }
    });

    const newColumnOrder = !!filterViewOverrides[filterView.id]?.column_order;
    const newVisibleColumns = !!filterViewOverrides[filterView.id]?.visible_columns;
    if (newColumnOrder) newFilters.add("column_order");
    if (newVisibleColumns) newFilters.add("visible_columns");

    return newFilters.size;
  }, [filters, sortData, filterView, filterViewOverrides]);

  const { customFields } = useCustomFieldsQuery();

  const visibleColumns = useMemo(() => {
    const cols =
      filterViewOverrides[filterView?.id]?.visible_columns ||
      filterView?.filter_data?.visible_columns ||
      getDefaultVisibleColumns(customFields?.map((f) => f.field_id));
    if (cols.some((c) => c.includes("roles_"))) {
      return [...cols, "roles"];
    } else {
      return cols;
    }
  }, [filterViewOverrides, filterView, customFields]);

  const { clients, clientsQuery, pageCount, totalClientsCount } = useClientsQuery({
    page,
    limit: pageLimit?.id,
    infiniteScroll,
    filters,
    sortData,
    search: debouncedSearch,
    visibleColumns,
    disabled: !customFields,
  });

  const { selection, selectAll, deselectAll, toggleSelection } = useSelections({ totalSize: totalClientsCount });

  // There are too many issues that arise when keeping selections across filter changes, so we need to reset them when filters change.
  // For example exporting selected clients will not work without some BE intervention because one of the selected clients may not exist in the filter view.
  useEffect(() => {
    deselectAll();
  }, [filters, deselectAll]);

  useEffect(() => {
    const limitId = localStorage.getItem("contacts-ui:client-list-page-limit");
    setPageLimit(limitId ? pageLimitData.find((p) => p.id === limitId) : pageLimitData[0]);
  }, []);

  const onPageChange = useMemo(() => throttle((page) => setPage(page + 1), 500), []);

  const inactiveShowing = useMemo(() => {
    if (!filters?.is_active) {
      return true;
    }
    return filters.is_active.some((f) => f.equal_to === false);
  }, [filters]);

  function onLimitChange(val) {
    setPageLimit(val);
    track("client_list.pagination-changed", { pageLimit: val.id });
    localStorage.setItem("contacts-ui:client-list-page-limit", val.id);
  }

  function setSubView(view) {
    _setSubView(view);
    deselectAll();
    if (view?.filter_data) {
      setFilters(view.filter_data.filters);
      setSortData(view.filter_data.sort_data);
    } else {
      setFilters(filterView.filter_data.filters);
      setSortData(filterView.filter_data.sort_data);
    }
  }

  function resetFilters() {
    deselectAll();
    setFilters(filterView.filter_data.filters);
    setSortData(filterView.filter_data.sort_data);
    if (!!filterViewOverrides[filterView.id])
      setFilterViewOverrides((prev) => {
        const updated = { ...prev };
        delete updated[filterView.id];
        return updated;
      });
  }

  function overrideFilterView(filterViewId, overrideData) {
    setFilterViewOverrides((val) => ({
      ...val,
      [filterViewId]: {
        ...(val[filterViewId] || {}),
        ...overrideData,
      },
    }));
  }

  function onColumnOrderChange(columnOrder) {
    if (!!filterView.read_only || filterView.created_by === "__DEFAULT") {
      overrideFilterView(filterView.id, {
        column_order: columnOrder,
      });
    }
  }

  function onColumnVisibilityChange(filterViewId, visibleColumns) {
    if (!!filterView.read_only || filterView.created_by === "__DEFAULT") {
      overrideFilterView(filterViewId, {
        visible_columns: visibleColumns,
      });
    }
  }

  function getAvailableBulkActions() {
    if (subView?.id === "archived") {
      return ["email", "tags"];
    } else if (subView?.id === "inactive" || inactiveShowing) {
      return ["email", "tags", "assign_contact_owner", "assign_team_members", "archive"];
    } else {
      return null;
    }
  }

  const subviewContextActions = useMemo(() => {
    if (!subView) return null;
    if (subView?.id === "archived") {
      return ["edit", "delete", "unarchive"];
    } else return ["edit"];
  }, [subView]);

  return (
    <FilterContext.Provider value={filterContextValues}>
      <div className={styles.clientListContainer}>
        <div className={styles.topBar}>
          <div className={styles.header}>
            {!!subView && (
              <CpTooltip text="Back to Client List">
                <CpButton
                  btnType="icon"
                  aria-label="Back to Client List"
                  icon="caret-large-left"
                  className="cp-mr-12"
                  onClick={() => setSubView(null)}
                />
              </CpTooltip>
            )}
            <div className={a("cp-subheader").m("cp-ml-12", !subView)}>{subView?.name || "Client List"}</div>
          </div>
          <div className="cp-flex">
            <AddClientButton />
            <KabobMenu
              currentView={subView?.name || "Active Clients"}
              showActiveClients={() => setSubView(null)}
              showInactiveClients={() => {
                setSubView({
                  id: "inactive",
                  name: "Inactive Clients",
                  clientActionName: "Activate",
                  filter_data: {
                    filters: {
                      is_active: [{ equal_to: false }],
                      is_archived: [{ equal_to: false }],
                    },
                    sort_data: [],
                  },
                });
              }}
              showArchivedClients={() => {
                setSubView({
                  id: "archived",
                  name: "Archived Clients",
                  clientActionName: "Unarchive",
                  filter_data: {
                    filters: {
                      is_archived: [{ equal_to: true }],
                    },
                    sort_data: [],
                  },
                });
              }}
              filterViews={filterViews}
              filterViewOverrides={filterViewOverrides}
              selection={selection}
              currentFilterViewId={filterView?.id}
              currentFilters={filters}
              currentSortData={sortData}
              globalSearch={search}
              totalClientsCount={totalClientsCount}
            />
          </div>
        </div>
        {!subView && (
          <FilterViewTabs
            className="cp-pr-24"
            filterViews={visibleFilterViews}
            filterView={filterView}
            onChange={(view) => setActiveFilterViewId(view.id)}
          />
        )}
        <div className={styles.filterSection}>
          <div className="cp-flex-center cp-mr-24">
            {!subView && (
              <ColumnCustomization
                filterViews={filterViews}
                filterView={filterView}
                filterViewOverrides={filterViewOverrides}
                onColumnVisibilityChange={onColumnVisibilityChange}
              />
            )}
            <ExportClientListButton
              filterView={filterView}
              filterViewOverrides={filterViewOverrides}
              filters={filters}
              sortData={sortData}
              search={search}
              selection={selection}
              subView={subView}
            />
            {totalClientsCount > 0 && (
              <div className="cp-ml-16">
                {totalClientsCount.toLocaleString()} client{totalClientsCount > 1 ? "s" : ""}
              </div>
            )}
            {subView
              ? selection.totalSelected > 0 && (
                  <SubViewActionButton
                    className="cp-ml-12"
                    subView={subView}
                    filters={filters}
                    selection={selection}
                    deselectAll={deselectAll}
                    search={search}
                  />
                )
              : newFiltersCount > 0 && (
                  <>
                    <SaveFilterDropdown
                      newFiltersCount={newFiltersCount}
                      filters={filters}
                      sortData={sortData}
                      filterView={filterView}
                      filterViewOverrides={filterViewOverrides}
                      onFilterViewCreate={(viewId) => {
                        if (filterView.created_by === "__DEFAULT" || filterView.read_only) resetFilters();
                        setMoveToFilterView(viewId);
                      }}
                    />
                    <CpButton onClick={resetFilters} className="cp-ml-8" btnType="tertiary">
                      Reset filter{newFiltersCount > 1 ? "s" : ""}
                    </CpButton>
                  </>
                )}
            {!subView && !!filterViewsQuery.data && inactiveShowing && (
              <div className={styles.limitedBulkWarning}>
                Bulk actions are limited when inactive clients are filtered
              </div>
            )}
          </div>
          {selection.totalSelected > 0 ? (
            <BulkActionList
              selection={selection}
              actions={getAvailableBulkActions()}
              filters={filters}
              search={search}
            />
          ) : (
            <div className={styles.searchInput}>
              <CpInput
                isSearch
                placeholder="Search client list"
                value={search}
                onChange={(val) => {
                  setSearch(val);
                  debounceSearch(val.trim());
                }}
              />
            </div>
          )}
        </div>
        <ClientListTable
          className={styles.clientListTable}
          filterView={filterView}
          filterViewOverrides={filterViewOverrides}
          clients={clients}
          clientsQuery={clientsQuery}
          infiniteScroll={infiniteScroll}
          selection={selection}
          selectAll={selectAll}
          deselectAll={deselectAll}
          toggleSelection={toggleSelection}
          contextMenuActions={subviewContextActions}
          isSearching={debouncedSearch.length > 0}
          onColumnOrderChange={onColumnOrderChange}
          subView={subView}
        />
        <div className={styles.bottomBar}>
          <CpPagination
            key={`${pageLimit?.id}_${filterView?.id}_${debouncedSearch}_${filtersString}`}
            lastPage={infiniteScroll ? 0 : pageCount - 1}
            onChange={onPageChange}
          />
          <CpSelectSingle
            className="cp-ml-20"
            onChange={onLimitChange}
            contentWidth="sm"
            value={pageLimit}
            data={pageLimitData}
          />
        </div>
      </div>
    </FilterContext.Provider>
  );
}
