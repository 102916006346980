import React from "react";
import styles from "./upgrade-contacts-card.styles.css";
import { constants, CpButton } from "canopy-styleguide!sofe";

export default function UpgradeContactsCard(props) {
  return (
    <div className={styles.card}>
      <img className={styles.icon} src="https://cdn.canopytax.com/static/empty-states/es_contacts.svg" />
      <div className="cps-subheader cps-wt-bold">Time to upgrade</div>
      <div className={styles.text}>{props.children}</div>
      <div>
        Call <span className="cps-wt-semibold">{`${constants.canopyPhone}`}</span> to upgrade.
      </div>
      <CpButton
        btnType="flat"
        href="#/global-settings/account-management"
        onClick={() => (window.location.hash = "#/global-settings/account-management")}
        className="cp-mt-24"
      >
        View my subscription
      </CpButton>
    </div>
  );
}
