// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-client-list-table-cells-list-cell-styles__moreButton {\n  background: none;\n  border: none;\n  padding: 0;\n  margin-left: 0.4rem;\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.src-client-list-table-cells-list-cell-styles__dropdownContent {\n  display: flex;\n  flex-direction: column;\n  padding: 0.8rem;\n  padding-left: 0.2rem;\n}\n", ""]);
// Exports
exports.locals = {
	"moreButton": "src-client-list-table-cells-list-cell-styles__moreButton",
	"dropdownContent": "src-client-list-table-cells-list-cell-styles__dropdownContent"
};
module.exports = exports;
