import { capitalize } from "lodash";
import { currency } from "canopy-styleguide!sofe";
import FilterHeader from "./headers/filter-header.component";
import ClientNameHeader from "./headers/client-name-header.component";
import TextHeader from "./headers/text-header.component";
import BoolCell from "./cells/bool-cell.component";
import DateCell from "./cells/date-cell.component";
import EmailCell from "./cells/email-cell.component";
import ListCell from "./cells/list-cell.component";
import UserListCell from "./cells/user-list-cell.component";
import TextCell from "./cells/text-cell.component";
import ClientNameCell from "./cells/client-name-cell.component";
import PhoneNumberCell from "./cells/phone-number-cell.component";
import MaskedCell from "./cells/masked-cell.component";
import CustomFieldCell from "./cells/custom-field-cell.component";
import ReferredByCell from "./cells/referred-by-cell.component";
import QboCell from "./cells/qbo-cell.component";
import BirthdayCell from "./cells/birthday-cell.component";
import RoleCell from "./cells/role-cell.component";

export const columnWidthsPx = {
  sm: 110,
  md: 200,
  lg: 300,
  clientName: 250,
};

const defaultVisibleColumns = [
  "full_name",
  "display_name",
  "first_name",
  "last_name",
  "middle_name",
  "ein",
  "ssn",
  "phone",
  "contact_owner",
  "team_members",
  "contact_person",
  "spouse_name",
  "business_type",
  "contact_type",
  "tags",
  "locality",
  "region",
  "qbo",
];
export function getDefaultVisibleColumns(customFieldIds) {
  return [...defaultVisibleColumns, ...(customFieldIds?.map((id) => "custom_field_" + id) || [])];
}

// Returns a copy of colsToSort sorted by the columnOrder
export function sortColumns(colsToSort, columnOrder) {
  return [...colsToSort].sort((a, b) => {
    let indexA = columnOrder.indexOf(a);
    let indexB = columnOrder.indexOf(b);
    // If index cannot be found then push these columns to the end of the list
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;

    return indexA - indexB;
  });
}

/*
  search+select options:
    getSearchFilterLabel: (item)
      A function that returns the label to display in the search dropdown

    getSearchFilterValue: (item)
      A function that returns the actual filter value that we send up to the BE

    findSearchFilterItem: (serverItem, filterValue)
      A function that returns true/false used to find the item from the server data based only on the filterValue (since that's all we get back from BE). By default we use getSearchFilterValue on the serverItem and compare that with filterValue. This is only needed in rare cases e.g. when filterValue is an object or when server data has a key that is different from the filterValue
*/

export const columns = {
  first_name: {
    label: "First Name",
    type: "string",
    width: "md",
    section: "client",
  },
  middle_name: {
    label: "Middle Name",
    type: "string",
    width: "md",
    section: "client",
  },
  last_name: {
    label: "Last Name",
    type: "string",
    width: "md",
    section: "client",
  },
  display_name: {
    label: "Display Name",
    type: "string",
    width: "md",
    section: "client",
  },
  email: {
    label: "Email",
    type: "string",
    width: "md",
    cell: EmailCell,
    section: "client",
  },
  birthdate: {
    label: "Birthday",
    type: "date",
    filter: "date",
    dateFormat: "MM-dd",
    width: "md",
    section: "client",
    cell: BirthdayCell,
  },
  business_type: {
    label: "Business Type",
    type: "string",
    width: "md",
    section: "business",
  },
  locality: {
    label: "City",
    type: "string",
    width: "md",
    section: "client",
  },
  client_since: {
    label: "Client Since",
    type: "date",
    width: "md",
    filter: "date",
    section: "client",
  },
  contact_owes: {
    label: "Client Owes",
    type: "number",
    width: "md",
    formatValue: (value) => {
      let num = value;
      // Getting string and number values from BE
      if (typeof value === "string") {
        num = parseFloat(value);
      }
      return !num ? null : currency(num);
    },
    section: "firm",
    header: TextHeader,
  },
  contact_owner: {
    label: "Client Owner",
    type: "string",
    width: "md",
    section: "firm",
    filterField: "_client_owner_id",
    sortable: false,
    filter: "search",
    getSearchFilterValue: (item) => item.id,
    singleSelect: true,
  },
  contact_person: {
    label: "Contact Person",
    type: "string",
    width: "md",
    section: "business",
  },
  contact_type: {
    label: "Client Type",
    type: "string",
    width: "md",
    formatValue: (value) => capitalize(value),
    getSearchFilterLabel: (item) => capitalize(item.name),
    getSearchFilterValue: (item) => item.name,
    findSearchItemFromValue: (serverItem, filterValue) => {
      return serverItem.id === filterValue;
    },
    section: "client",
    hideBlanksOption: true,
    filter: "search",
    singleSelect: true,
  },
  country: {
    label: "Country",
    type: "string",
    width: "md",
    section: "client",
    filter: "search",
    getSearchFilterValue: (item) => item.name,
    singleSelect: true,
  },
  created_at: {
    label: "Created On",
    type: "date",
    width: "md",
    filter: "date",
    section: "client",
    hideBlanksOption: true,
  },
  date_established: {
    label: "Date Established",
    type: "date",
    width: "md",
    filter: "date",
    section: "business",
  },
  ein: {
    label: "EIN",
    type: "string",
    width: "md",
    cell: MaskedCell,
    section: "business",
    header: TextHeader,
  },
  employer_name: {
    label: "Employer",
    type: "string",
    width: "md",
    section: "client",
  },
  external_id: {
    label: "External ID",
    type: "string",
    width: "md",
    section: "client",
  },
  filing_status: {
    label: "Filing Status",
    type: "string",
    width: "md",
    section: "client",
    filter: "search",
    getSearchFilterValue: (item) => item.name,
    singleSelect: true,
  },
  industry: {
    label: "Industry",
    type: "string",
    width: "md",
    section: "business",
    filter: "search",
    getSearchFilterValue: (item) => item.name,
    singleSelect: true,
  },
  occupation: {
    label: "Occupation",
    type: "string",
    width: "md",
    section: "client",
  },
  phone: {
    label: "Phone",
    type: "string",
    width: "md",
    cell: PhoneNumberCell,
    section: "client",
  },
  referred_by: {
    label: "Referred By",
    type: "string",
    width: "md",
    section: "client",
    header: TextHeader,
    cell: ReferredByCell,
  },
  contact_sources: {
    label: "Source",
    type: "string",
    width: "md",
    filter: "search",
    getSearchFilterValue: (item) => item.name,
    sortable: false,
    formatValue: (value) => {
      // Source gets returned as a single object in an array on the client, but search+select will give us a string value
      if (Array.isArray(value)) {
        return value?.[0]?.name || "";
      } else {
        return value;
      }
    },
    section: "client",
  },
  spouse_name: {
    label: "Spouse Name",
    type: "string",
    width: "md",
    section: "client",
  },
  ssn: {
    label: "SSN",
    type: "string",
    width: "md",
    cell: MaskedCell,
    section: "client",
    header: TextHeader,
  },
  region: {
    label: "State",
    type: "string",
    width: "sm",
    section: "client",
  },
  street_address: {
    label: "Street Address",
    type: "string",
    width: "md",
    section: "client",
  },
  tags: {
    label: "Tags",
    type: "list",
    width: "md",
    section: "firm",
    filter: "search",
    getSearchFilterValue: (item) => item.name,
  },
  postal_code: {
    label: "Zip",
    type: "string",
    width: "sm",
    section: "client",
  },
  team_members: {
    label: "Assigned Users",
    type: "list",
    section: "firm",
    sortable: false,
    formatValue: (value) => {
      if (typeof value === "string") {
        return value;
      } else {
        return value?.map((tm) => tm.profile_name || tm.name) || "";
      }
    },
    filter: "search",
    getSearchFilterValue: (item) => item.id,
    getSearchFilterLabel: (item) => item.profile_name || item.name,
    cell: UserListCell,
  },
  teams: {
    label: "Assigned Teams",
    type: "list",
    section: "firm",
    filter: "search",
    sortable: false,
    cell: ListCell,
    getSearchFilterLabel: (item) => item.name,
    getSearchFilterValue: (item) => item.id,
    formatValue: (value) => {
      return value?.map((team) => team.name);
    },
  },
  qbo: {
    label: "QBO",
    type: "string",
    section: "firm",
    header: TextHeader,
    cell: QboCell,
  },
  is_active: {
    label: "Active",
    type: "boolean",
    filter: "boolean",
    section: "client",
    width: "sm",
    boolFilterOpts: {
      ascSortLabel: "Inactive - Active",
      descSortLabel: "Active - Inactive",
      trueValueLabel: "Active",
      falseValueLabel: "Inactive",
    },
  },
};

function getCellByColumn(col) {
  let cell;
  switch (col.type) {
    case "boolean":
      cell = BoolCell;
      break;
    case "date":
      cell = DateCell;
      break;
    case "list":
      cell = ListCell;
      break;
    default:
      cell = TextCell;
  }
  return cell;
}

export function buildColumnDefs(customFields = null, roles = null) {
  const defs = {
    full_name: {
      id: "full_name",
      label: "Client Name",
      width: "clientName",
      header: ClientNameHeader,
      cell: ClientNameCell,
      type: "string",
      filter: "dynamic",
      filterField: "full_name",
      sortable: true,
      sortField: "name",
      hideBlanksOption: true,
    },
  };

  Object.keys(columns).forEach((id) => {
    const column = columns[id];
    defs[id] = {
      ...column,
      id,
      header: column.header || FilterHeader,
      cell: column.cell || getCellByColumn(column), // add unique cell types to the columns object above as the 'cell' property, otherwise a generic cell type is chosen in getCellByColumn
      label: column.label,
      type: column.type,
      width: column.width,
      filter: column.filter || "dynamic",
      filterField: column.filterField || id,
      sortField: column.sortField || id,
      formatValue: column.formatValue,
      section: column.section,
      sortable: column.sortable ?? true,
    };
  });

  customFields?.forEach((field) => {
    const id = "custom_field_" + field.field_id;
    const type = "custom_field_" + field.field_type.toLowerCase();
    defs[id] = {
      id,
      header: FilterHeader,
      cell: CustomFieldCell,
      label: field.field_name,
      width: "md",
      customField: field,
      type: type,
      section: "custom",
      filterField: id,
      sortField: "cf_" + field.field_name,
      sortable: true,
      singleSelect: field.field_type === "dropdown",
      // All search+select custom fields need to pass the id and not the name of the search result items
      getSearchFilterValue: (item) => item.id,
    };
  });

  roles?.forEach((role) => {
    const id = "roles_" + role.id;
    defs[id] = {
      id,
      roleId: role.id,
      header: FilterHeader,
      cell: RoleCell,
      width: "md",
      label: role.label,
      section: "roles",
      sortable: false,
      filter: "search",
      filterField: id,
      getSearchFilterValue: (item) => item,
      getSearchFilterLabel: (item) => item.name,
      findSearchItemFromValue: (serverItem, filterValue) => {
        return serverItem.id === filterValue.id;
      },
    };
  });

  return defs;
}
