import { catchError } from "auto-trace";
import { getTemplates } from "./templates.resource";
import { useQuery } from "src/react-query";

export function useTemplatesQuery({ search, type, page }) {
  const query = useQuery({
    queryKey: ["client-record-templates", search, type, page],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        getTemplates({
          search,
          exactMatch: false,
          type: type || "both",
          limit: 50,
          page: page || 1,
        }).subscribe((data) => {
          resolve(data);
        }, reject);
      });
    },
    onError: catchError(),
  });

  return {
    templatesQuery: query,
    templates: query.data?.templates,
    templatesTotalPages: query.data?.pagination?.total_pages || 1,
  };
}
